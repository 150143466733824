
/*import package*/
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const WebinarTutorCard = (props) => {

  return (
    <>
      <div className="card e-webinarcard e-webinar-tutor-card text-center">
        <div className='row'>
          <div className='col-lg-12'>
            <img src={props.data.img} alt=""/>
          </div>
          <div className='col-lg-12'>
            <h6 className='pt-3'>{props.data.name}</h6>
          </div>
          <div className='col-lg-12 text-center'>
            <div className='d-inline-flex'>
              <p className='mb-0'>No. of webinars</p>
              <p className='pl-3 mb-0'>{props.data.count}</p>
            </div>
          </div>
        </div>
      </div>
    </>



  );
}
export default WebinarTutorCard;

