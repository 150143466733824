/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import devconfig from '../../config/config.js';

class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
    }
  }

  componentDidMount() {

  }

  async uploadDocument(e) {
    var tempFileName = e.target.files[0].name;
    var fileReader = new FileReader();


    if (e.target.files != null) {
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onloadend = function (event) {
        var file = event.target.result;
        $.ajax({
          type: "POST",
          url: devconfig.admin + '/utils/upload-image',
          dataType: 'json',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Token': this.state.accessToken
          },
          data: JSON.stringify({
            'file': file,
            'fileName': tempFileName,
            "key": "card"
          })
        }).then(function (response) {
          if (response.status) {
            this.setState({ file: response.cdn_url });
            this.props.uploadSuccess(response.cdn_url);
          }
        }.bind(this))
      }.bind(this)
    }
  }


  render() {
    return (
      <>
        <input id={this.props.id} type="file" accept=".jpg,.png,.gif" className={this.props.className} onChange={this.uploadDocument.bind(this)} />
        <div className='e-error-message'>{this.props.error}</div>
      </>
    );
  }
}

export default ImageUploader;
