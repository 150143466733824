/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import WebinarTutorDetailCard from '../../components/card/webinarCreatorDetailCard.js';
import RemoveBannerConfirmation from '../../components/modals/banner/removeBanner.js';
import AddNewHostModal from '../../components/modals/webinar/addNewHost.js';
import WebinarCard from '../../components/card/webinarCard';

/*Assets*/
import Delete from '../../assets/images/cards/delete.svg';
import BackArrow from '../../assets/images/icons/back-arrow.svg';
import Edit from '../../assets/images/icons/edit.svg';

class WebinarCreatorDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    // this.creatorsListApi(this.state.pageNum, null, 1);
  }


  render() {

    return (
      <div className="e-main-content e-creators-wrap e-webinar-wrap">
        <h2>Webinar Tutor</h2>
        <div className='row'>
          <div className='col-lg-8'>
            <h5><a href="/webinar-tutors"><img src={BackArrow} alt="" className='e-back-arrow mr-3' /></a>Webinar tutor details</h5>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <span className='mr-5 mb-0 e-red' data-toggle="modal" data-target="#removeBannerModal"> <img src={Delete} alt="" className='mr-2' />Delete</span>
              <span className='mb-0 e-edit-btn' data-toggle="modal" data-target="#addNewHostModal"> <img src={Edit} alt="" className='mr-2' />Edit</span>

            </div>
          </div>
        </div>
        <div className='row pt-2'>
          <div className='col-lg-12'>
            <WebinarTutorDetailCard />
          </div>
        </div>
        <div className='row pt-4 mt-2'>
          <div className='col-lg-12'>
            <h5>Webinar’s host by Edward newgate</h5>
          </div>
        </div>
        <div className='row pt-3 mb-5'>
          <div className='col-lg-4'>
            <WebinarCard/>
          </div>
          <div className='col-lg-4'>
            <WebinarCard/>
          </div>
          <div className='col-lg-4'>
            <WebinarCard/>
          </div>
        </div>
        <RemoveBannerConfirmation content="Are you sure to delete this webinar tutor?" />
        <AddNewHostModal />
      </div >

    );
  }
}

export default WebinarCreatorDetail;
