/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class LazyLoaderGif extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <span className="e-lazy-loader"></span>
    );
  }
}

export default LazyLoaderGif;
