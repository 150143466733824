import React from 'react';

// import Service
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';

export default class refferals extends React.Component{

constructor(props){
	super(props);
	this.state={
		pageNum: 1
	}
}

componentDidMount(){
	this.getData();
}

getData(){
	this.setState({apiLoading: true});
    var url = devconfig.admin+"/users/referrals";
    var data = JSON.stringify({
      // "id" : this.state.id,
      "page_num": this.state.pageNum
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({
          apiLoading: false, 
          data: response.data
        });
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this))
}
	render(){
		return(
			<div className="e-main-content e-invites-wrap">
        <h2>Refferals</h2>
        <div className="row">
        	<div className="col-lg-12">
        		<table className="table table-stripped">
        			<thead>
        				<tr>
        					<th>User ID</th>
        					<th>User Name</th>
        					<th>Course Name</th>
        					<th>Joined Leads</th>
        					<th>Earned Commission</th>
        			
        				</tr>
        			</thead>
        			<tbody>
        				{
        					this.state.data &&
        					this.state.data.map((item, key)=>{
        						return(
        							<tr key={key}>
        								<td>{item.alice_blue_id}</td>	
        								<td>{item.name}</td>
        								<td>{item.course_name}</td>
        								<td>{item.joined_leads}</td>
        								<td>{item.earned_commission}</td>
        							</tr>
        						)
        					})
        				}
        			</tbody>
        		</table>
        	</div>
        </div>
      </div>
		);
	}
}