/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';

/*Assets*/

class EditIncentive extends Component {
  constructor(props){
    super(props);
    this.state = {
      incentive: ""
    }
  }

  componentWillMount(){
    this.setState({ incentive: this.props.data});
  }

  buttonClick(){
    var data = {
      "creator_id" : this.props.id,
      "incentive_percentage" : this.state.incentive
    }
    var url = devconfig.admin + "/creator/update-incentive"

    APIPost(url, JSON.stringify(data)).then((response)=>{
      if(response.status){
        window.location.reload();
        this.setState({ apiLoading: false});
      }else{
        this.setState({ apiLoading: false, errorMessage: response.message});
      }
    })

  }
  editCloseClick(){
    // this.props.editClose();
  }

  render() {
    return (
      <div className="modal fade" id="editIncentiveModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="editIncentiveModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Incentive</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.editCloseClick.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mt-3">
              <Input label="Incentive(%)" id="u-lang" type="text" value={this.state.incentive} valueChange={(tempVal) => this.setState({incentive: tempVal})} />
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal" onClick={this.editCloseClick.bind(this)}>Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        this.state.incentive && this.state.incentive.length ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit" >Save</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditIncentive;
