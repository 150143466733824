// /*Package importing*/
// import React, { Component } from 'react';

// /*Import custom components and assets*/
// import Select from "../../assets/images/circle-active.png";
// import Unselect from "../../assets/images/circle-inactive.png";

// class RadioGroup extends Component {
//   constructor(props) {
//     super(props);
//   }

//   _onSelectRadioItem(selected_id) {
//     this.props.onSelectItem(selected_id);
//   }

//   render() {

//     return (
//       <>
//         {
//           this.props.data.map((item, key) => {
//             return (
//               <div className='d-flex e-radio cursor-pointer' 
//               onClick={() => {
//                 this.props.onSelectItem(item.value)
//               }}>
//                 <div key={key} className=' text-start' >
//                   <img draggable='false'
//                     src={this.props.selectedItem === item.value ? Select : Unselect}
//                     alt='radio-btn' />
//                 </div>
//                 <span className='e-radio-span '>{item.label}</span>
//               </div>
//             )
//           })
//         }
//       </>
//     );
//   }
// }

// export default RadioGroup;


import React, { Component } from 'react';
import Select from "../../assets/images/circle-active.png";
import Unselect from "../../assets/images/circle-inactive.png";

class RadioGroup extends Component {
    constructor(props) {
        super(props);
    }

    _onSelectRadioItem(selected_id) {
        this.props.onSelectItem(selected_id);
    }

    render() {
        const { data, selectedItem } = this.props;
        return (
            <>
                {data.map((item, key) => (
                    <div
                        className='d-flex e-radio e-cursor-pointer'
                        onClick={() => this._onSelectRadioItem(item.value)}
                        key={key}
                    >
                        <div className='text-start'>
                            <img
                                draggable='false'
                                src={selectedItem === item.value ? Select : Unselect}
                                alt='radio-btn'
                            />
                        </div>
                        <span className='e-radio-span'>{item.label}</span>
                    </div>
                ))}
            </>
        );
    }
}

export default RadioGroup;
