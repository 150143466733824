/*Package importing*/
import React, { Component } from 'react';

/*Components*/

/*Assets*/

class RemoveCreator extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  removeClick(){
    this.props.success();
  }

  render() {
    return (
      <div className="modal fade" id="removeCreatorModal" tabIndex="-1" role="dialog" aria-labelledby="removeCreatorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Remove Creator</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to remove <b>‘{this.props.name}’</b> ?
              </p>
            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              <span className="e-p-link-txt" onClick={this.removeClick.bind(this)}>Remove</span>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default RemoveCreator;
