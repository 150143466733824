/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Topbar extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  logout() {
    localStorage.clear();
    window.location.href = '/';
  }

  render() {
    return (
      <header className="text-right">
        <span className="e-logout" onClick={this.logout.bind(this)}>Logout</span>
      </header>
    );
  }
}

export default Topbar;
