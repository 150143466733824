/*Package importing*/
import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

/*Components*/
import SummaryCard from '../../components/card/summaryCard.js';

/*Assets*/
import Users from '../../assets/images/dashboard/users.svg';
import UpcomingWebinar from '../../assets/images/dashboard/upcomingWebinar.svg';
import ongoingWebinar from '../../assets/images/dashboard/ongoingWebinar.svg';
import Webinar from '../../assets/images/dashboard/webinar.svg';
import APIGet from '../../services/apiCall/get.js';
import devconfig from '../../config/config.js';
import NothingFound from '../../components/common/nothingFound.js';
import LazyLoaderGif from '../../components/common/lazyloader.js';
import APIPost from '../../services/apiCall/post.js';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardData: {},
            data: [],
            dataLoading: true,
        }
    }

    componentDidMount() {
        this._getDashboardData();

        // console.log("this.state.data::", this.state.data)
    }


    // API - Get dashboard detail
    _getDashboardData = () => {
        var url = devconfig.admin + "/dashboard/get";
        APIGet(url).then((response) => {
            console.log("API Response:", response);
            if (response.status) {

                this.setState({
                    dashboardData: response.data[0],
                    // dataLoading: false
                }, () => {
                    this._getListDetails();
                });
            } else {
                this.setState({
                    dashboardData: {}
                });
            }
        }).catch((error) => {
            console.error("API request error:", error);
        });
    }

    // API - Get dashboard detail
    _getListDetails() {
        let formatted_date = null;
        let formatted_enddate = null;

        let started_date = this.state.startDate
        let end_date = this.state.endDate

        if (started_date, end_date) {
            const new_date = new Date(started_date);
            const new_enddate = new Date(end_date);
            formatted_date = new_date.toLocaleDateString('en-GB').split("/").join("-");
            formatted_enddate = new_enddate.toLocaleDateString('en-GB').split("/").join("-");
        }

        console.log('formatted_date', formatted_date)

        var url = devconfig.admin + "/webinar/all-webinar";
        var data = JSON.stringify({
            "sort_value": null,
            "start_date": formatted_date,
            "end_date": formatted_enddate,
            "published": true,
            "type": 3 // Upcoming webinars
        });

        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    data: response.data,
                    dataLoading: false
                });
            }
            else {
                this.setState({
                    data: [],
                    lazyLoadStatus: false,
                    dataLoading: false
                });
            }
        }.bind(this));
    }


    render() {
        return (
            <div className="e-main-content e-creators-wrap e-webinar-wrap">
                <h2>Dashboard</h2>
                <div className='row'>

                    {/* total number of customers */}
                    <div className='col-lg-3 mb-4 pb-2'>
                        <SummaryCard isGeneral={true}
                            img={Users}
                            title={'Total number of users'}
                            count={this.state.dashboardData.total_users} />
                    </div>

                    {/* number of upcoming webinars */}
                    <div className='col-lg-3 mb-4 pb-2'>
                        <SummaryCard isGeneral={false}
                            type= {3}
                            img={UpcomingWebinar}
                            title={'Upcoming webinar'}
                            count={this.state.dashboardData.upcoming_webinars} />
                    </div>

                    {/* number of ongoing webinars */}
                    <div className='col-lg-3 mb-4 pb-2'>
                        <SummaryCard isGeneral={false}
                            type= {2}
                            img={ongoingWebinar}
                            title={'Ongoing webinar'}
                            count={this.state.dashboardData.ongoing_webinars} />
                    </div>

                    {/* number of completed webinars */}
                    <div className='col-lg-3 mb-4 pb-2'>
                        <SummaryCard isGeneral={false}
                            type= {1}
                            img={Webinar}
                            title={'Completed webinar'}
                            count={this.state.dashboardData.completed_webinars} />
                    </div>
                </div>


                <div className='row'>
                    <div className='col-lg-8 mb-4'>
                        <h5>Upcoming Webinars</h5>
                    </div>
                </div>
                {
                    this.state.dataLoading == false ?
                        <>
                            <React.Fragment>
                                {
                                    this.state.data.length ?
                                        <>
                                            {
                                                this.state.dataLoading == true ?
                                                    <div className=' text-center'>
                                                        <LazyLoaderGif />
                                                    </div>
                                                :
                                                    <div >
                                                        <table className="table e-webinar-datas">
                                                            <thead>
                                                                <tr>
                                                                    <th>Webinar ID</th>
                                                                    <th>Name</th>
                                                                    <th>Date</th>
                                                                    <th>Duration</th>
                                                                    <th>Zoom ID</th>
                                                                    <th>Status</th>
                                                                    <th>Language</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {
                                                                    this.state.data.map((item, index) => (
                                                                        <tr className="e-webinar-datas e-cursor-pointer" key={index}
                                                                            onClick={() =>
                                                                                window.location.href = `/webinar-detail?id=${item.id}`}>
                                                                            <td className="e-creator-dull-txt ">{item.webinar_id}</td>
                                                                            <td className="e-creator-dull-txt">{item.name}</td>
                                                                            <td className="e-creator-dull-txt">{item.scheduled_date}</td>
                                                                            <td className="e-creator-dull-txt ">{item.duration}</td>
                                                                            <td className="e-creator-dull-txt">{item.zoom_meeting_id}</td>
                                                                            {
                                                                                item.active == true ?
                                                                                    <td className="e-creator-dull-txt">Enabled</td>
                                                                                    :
                                                                                    <td className="e-creator-dull-txt">Disabled</td>
                                                                            }
                                                                            <td className="e-creator-dull-txt">{item.language}</td>
                                                                        </tr>

                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }
                                        </>
                                        :

                                        <NothingFound data="No webinars found !" />
                                }
                            </React.Fragment>
                        </> :
                        <React.Fragment>
                            <div className='col-12 text-center'>
                                <LazyLoaderGif />
                            </div>
                        </React.Fragment>
                }


                {/* <div className='row'>
                    <div className='col-lg-4'>
                        <div className='card border-0 e-webinarcard'>
                        <div className="card-body">
                            <h6 className="card-title mb-0">New users</h6>
                            <div className='row'>
                            <div className='col-lg-4'>Name</div>
                            <div className='col-lg-4'>Date of joining</div>
                            <div className='col-lg-4'></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div> */}


            </div >

        );
    }
}

export default Dashboard;
