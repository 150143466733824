/*Package importing*/
import React, { Component } from 'react';

/*Components*/

/*Assets*/
import Delete from '../../../assets/images/delete-red.svg';

class RemoveBannerConfirmation extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  removeClick(){
    this.props.success();
  }

  render() {
    return (
      <div className="modal fade e-remove-modal" id="removeBannerModal" tabIndex="-1" role="dialog" aria-labelledby="removeBannerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content text-center p-4">
            {/* <div className="modal-header">
              <h5 className="modal-title">Remove Creator</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body ">
              <img src={Delete} alt=""/>
              <p className='pt-3 mx-3'>{this.props.content}</p>
            </div>
            <div className='d-inline-flex position-relative' style={{justifyContent: "space-between"}}>
            <span className="e-cancel-btn" data-dismiss="modal">Cancel</span>
              <span className="e-delete-btn">Delete</span>
            </div>
            {/* <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              <span className="e-p-link-txt">Remove</span>
            </div> */}
          </div>
        </div>
      </div>
      
    );
  }
}

export default RemoveBannerConfirmation;
