import React, { Component } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            focused: false,
        };
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            const myDate = moment(this.props.defaultValue, 'DD-MM-YYYY');
            if (myDate.isValid()) {
                this.setState({ date: myDate });
            }
        }
    }

    render() {
        return (
            <div>
                <SingleDatePicker
                    date={this.state.date}
                    onDateChange={(date) => {
                        if (date) {
                            this.setState({ date });
                            this.props.dateChange(date.format('DD/MM/YYYY'));
                        } else {
                            this.setState({ date: null });
                            this.props.dateChange(null);
                        }
                    }}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id="your_unique_id"
                    placeholder="Select date"
                    isOutsideRange={day => day.isBefore(moment().startOf('day'))}
                // isOutsideRange={(day) =>
                //     this.props.outsideRange &&
                //     !isInclusivelyBeforeDay(day, moment())
                // }
                />
                <p className="e-error-message pt-2 mb-0">{this.props.error}</p>
            </div>
        );
    }
}