
/*import package*/
import React, { useEffect, useState } from 'react';

import Tutor from '../../assets/images/cards/tutor.svg';
import Youtube from '../../assets/images/cards/youtube.svg';
import Facebook from '../../assets/images/cards/fb.svg';
import Twitter from '../../assets/images/cards/twitter.svg';

const WebinarCreatorDetailCard = (props) => {


  return (
    <>
      <div className="card e-webinar-detail-card e-webinar-creator-detail-card p-4">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='' style={{ display: "-webkit-inline-box" }}>
              <img src={Tutor} className="" alt="" />
              <div className=' pl-3'>
                <h4 className='pt-2'>Edward newgate</h4>
                <div className='row'>
                  <div className='col-lg-7'>
                    <div className='e-content-wrap d-inline-flex pt-1'>
                      <p className='mb-0'>No. of webinars: 4</p>
                      <p className='mb-0'>Company name: Evoque innovation</p>
                      <p className='mb-0'>Company name: Evoque innovation</p>
                    </div>
                  </div>
                </div>
                <div className='row pt-2 mt-1'>
                  <div className='col-lg-10'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A ultricies leo cras aliquam ultricies faucibus fringilla cras. Diam at eget in tristique nisi adipiscing cursus sociis malesuada. Ridiculus vel elementum pulvinar pretium sit nisl fringilla sem rhoncus. Mattis sed porta mi risus. Habitasse convallis id enim consectetur odio diam. Est, et aenean elit eget. Malesuada commodo, dignissim neque mauris imperdiet ipsum. Massa, sem at id amet netus. Odio ullamcorper porttitor sem id tellus eu.</p>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-lg-5'>
                    <div className='e-content-wrap d-inline-flex pt-1'>
                      <div><img src={Youtube} alt="" className='mr-2'/>Learner academy</div>
                      <div><img src={Facebook} alt="" className='mr-2'/>Edward newgate</div>
                      <div><img src={Twitter} alt="" className='mr-2'/>Learner academy</div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <div className='col-lg-8'>
            

          </div> */}
        </div>
      </div>
    </>



  );
}
export default WebinarCreatorDetailCard;


{/* <div className='p-4'>
              <h5>Actual Trends in Stock Market Study</h5>
              <p>Learn Technical Analysis course that not only helps you understand the profit target but also aware you of risk involved in the trade. This course teaches the secrets of successful traders, unique ideas to trade in Intraday, Swing trade, Short term delivery, Futures & Options. The course by Kredent Academy is different from regular technical analysis because our studies are based on Logic & easy mathematical formulas, and are used by many successful traders, Brokers, and Fund Managers.</p>
              <div className='row'>
                <div className='e-content-wrap d-inline-flex pl-3 pt-1'>
                <p className='mb-0'>Host by: <span className='e-blue'>John Doe</span></p>
                <p className='mb-0'>Date & time: <span className='e-blue'>12/05/22, 10:30 AM</span></p>
                <p className='mb-0'>Type: <span>Paid</span></p>
                <p className='mb-0'>Joiners: <span>100</span></p>
                <p className='mb-0'>Cost: <span>₹4,000</span></p>
                </div>
              </div>
            </div> */}

