/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';
import AddPopUp from '../../components/modals/addPopUp';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import UpdatePopUp from '../../components/modals/editHomePopUp';
import NoDataFound from '../../components/common/nothingFound';
/*Assets*/

class SendNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      message: "",
      webinarMessage:"",
      webinarTitle:"",
      webinarUrl:"",
      apiLoading: false,
      errorMessage: "",
      success: false,
      tabIndex: 2
    }
  }

  componentDidMount() {
    // this.getNotification();
  }

  getPopUp = () => {
    this.setState({ loading: true })
    var url = devconfig.admin + "/notification/get-banner";
    APIGet(url).then(function (response) {
      if (response.status) {
        this.setState({ popUpData: response.data, loading: false });
      }
      else {
        this.setState({ loading: false })
      }
    }.bind(this));
  }

  sendClick() {
    this.setState({ apiLoading: true });
    var url = devconfig.admin + "/users/send-notification";
    var data = JSON.stringify({
      "title": this.state.title,
      "message": this.state.message
    })
    APIPost(url, data).then(function (response) {
      if (response.status) {
        this.setState({
          apiLoading: false,
          success: true
        });
        window.location.reload();
      }
      else {
        this.setState({ errorMessage: response.message, apiLoading: false });
      }
    }.bind(this))
  }
  tabChange = (e) => {
    this.setState({ tabIndex: e });
    if (e == 3) {
      this.getPopUp();
    }
  }
  reloadPops = () => {
    this.setState({ toast: "Updated Successfully", toastType: "success" })
    setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    this.getPopUp();
  }

  render() {

    return (
      <div className="e-main-content e-invites-wrap">

        <div className="row">
          <div className="col-lg-12 mb-4 e-Product-tab-link-wrap">
            {/*     {
              this.state.tabIndex === 1 ?
                <span className="e-tab-item-title e-active">Notifications</span>
              :
                <span onClick={()=>{this.tabChange(1)}} className="e-tab-item-title ">Notifications</span>
            }
*/}
            {
              this.state.tabIndex === 2 ?
                <span className="e-tab-item-title e-active ">Send push notification</span>
                :
                <span onClick={() => { this.tabChange(2) }} className="e-tab-item-title">Send push notification</span>
            }
            {/* {
              this.state.tabIndex === 3 ?
               <span className="e-tab-item-title e-active ">Home screen promotion banner</span>
              :
              <span onClick={()=>{this.tabChange(3)}} className="e-tab-item-title">Home screen promotion banner</span>
            } */}
            {
              this.state.tabIndex === 4 ?
                <span className="e-tab-item-title e-active ">Webinar push notification</span>
                :
                <span onClick={() => { this.tabChange(4) }} className="e-tab-item-title">Webinar push notification</span>
            }
          </div>

        </div>
        <React.Fragment>
          {
            this.state.tabIndex == 1 ?
              <div className="row">
                {
                  this.state.data ?
                    this.state.data.map((item, index) => {
                      return (
                        <div className="col-lg-12 mt-4" key={index}>
                          <div className="card border-0 e-profile-card pt-4">
                            <div className="row">
                              <div className="col-lg-10">
                                <h6 className="mb-3">{item.product_name}: Edited by {item.vendor_name}, Edited On : {item.created}</h6>
                                <span className="font-weight-bold">Edited elements: </span>
                                {
                                  item.edited_history.map((item, index) => {
                                    return (
                                      <React.Fragment key={index}>

                                        <p className="">
                                          <b>Edited Field: </b>{item.field}<br />
                                          <b>Edited Text: </b>{item.edited_text}<br />
                                          <b>New value: </b>{item.new_value}<br />
                                          <b>Old value: </b>{item.old_value}<br />
                                        </p>
                                      </React.Fragment>
                                    )
                                  })

                                }

                              </div>
                              <div className="col-lg-2 text-right mt-3 ">
                                <button className="e-p-btn " onClick={() => { window.location.href = '/product-details?id=' + item.product_id }}>View Product</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    null
                }
              </div>
              :
              null
          }
        </React.Fragment>
        <React.Fragment>
          {
            this.state.tabIndex === 2 ?
              <div className="col-lg-6">
                <div className="e-notify-wrap e-v-input-wrap">
                  <label>Title</label>
                  <Input id="n-title" type="text" placeholder="Enter the title of the webinar" defaultValue={this.state.title} valueChange={(tempVal) => this.setState({ title: tempVal })} />
                  <label>Message</label>
                  <Textarea placeholder="Enter the objective of the webinar" id="n-mesage" defaultValue={this.state.message} valueChange={(tempVal) => this.setState({ message: tempVal })} />

                  {
                    this.state.errorMessage.length ?
                      <div className="text-danger pb-4">{this.state.errorMessage}</div>
                      :
                      null
                  }

                  {
                    this.state.success ?
                      <div className="text-success pb-4">Notification has been sent successfully</div>
                      :
                      null
                  }

                  {
                    this.state.apiLoading ?
                      <span className="e-api-loader"></span>
                      :
                      <span>
                        {
                          this.state.message.length && this.state.title.length && !this.state.apiLoading ?
                            <button type="submit" form="e-login-form" value="Submit" onClick={this.sendClick.bind(this)}>Send notification</button>
                            :
                            <button className="e-inactive" type="submit" form="e-login-form" value="Submit">Send notification</button>
                        }
                      </span>
                  }
                </div>
              </div>
              :
              null

          }
        </React.Fragment>
        <React.Fragment>
          {
            this.state.tabIndex === 3 && this.state.popUpData && this.state.popUpData.id && !this.state.loading ?
              <React.Fragment>
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <React.Fragment>
                      {
                        this.state.popUpData && this.state.popUpData.image ?
                          <span className="e-img-preview">
                            <img src={this.state.popUpData.image} />
                          </span>
                          :
                          null
                      }
                    </React.Fragment>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    {
                      this.state.popUpData.redirection_url ?
                        <Input value={this.state.popUpData.redirection_url} readOnly={true} />
                        :
                        <p className="e-nodata-input pl-2 mt-4">No url found</p>
                    }

                  </div>
                </div>
                <div className="mt-4">
                  <button className="e-p-btn" data-toggle="modal" data-target="#editPopUp">Edit promotion banner</button>
                </div>
                <UpdatePopUp data={this.state.popUpData} reload={() => { this.reloadPops() }} />
              </React.Fragment>
              :
              this.state.tabIndex === 3 && this.state.popUpData && this.state.popUpData.id == null && !this.state.loading ?
                <div className="col-lg-12 text-center e-home-pop-sec">
                  <NoDataFound data="No promotion banner found!!" />
                  <button className="e-p-btn" data-toggle="modal" data-target="#addPopUp">Add new promotion banner</button>
                </div>
                :
                null
          }
        </React.Fragment>
        <React.Fragment>
          {
            this.state.tabIndex === 4 ?
              <div className="col-lg-6">
                <div className="e-notify-wrap e-v-input-wrap">
                  <label>Title</label>
                  <Input id="n-title" type="text" placeholder="Enter the title of the webinar" value={this.state.webinarTitle} valueChange={(tempVal) => this.setState({ webinarTitle: tempVal })} />
                  <label>Message</label>
                  <Textarea placeholder="Enter the objective of the webinar" id="n-mesage" value={this.state.webinarMessage} valueChange={(tempVal) => this.setState({ webinarMessage: tempVal })} />
                  <label>Webinar link</label>
                  <Input id="n-url" type="text" placeholder="Enter the url of the webinar" value={this.state.webinarUrl} valueChange={(tempVal) => this.setState({ webinarUrl: tempVal })} />

                  {
                    this.state.errorMessage.length ?
                      <div className="text-danger pb-4">{this.state.errorMessage}</div>
                      :
                      null
                  }

                  {
                    this.state.success ?
                      <div className="text-success pb-4">Notification has been sent successfully</div>
                      :
                      null
                  }

                  
                      <span>
                        {
                          this?.state?.webinarMessage?.length> 0 && this?.state?.webinarTitle?.length>0 && this?.state?.webinarUrl?.length > 0 ?
                            <button type="submit" form="e-login-form" value="Submit" onClick={this.sendClick.bind(this)}>Send notification</button>
                            :
                            <button className="e-inactive" type="submit" form="e-login-form" value="Submit">Send notification</button>
                        }
                      </span>
                  
                </div>
              </div>
              :
              null

          }
        </React.Fragment>
        <AddPopUp reload={() => { this.reloadPops() }} />
      </div>
    );
  }
}

export default SendNotification;
