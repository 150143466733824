/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';

/*Assets*/
import profile from '../../assets/images/profile.png';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class UsersList extends Component {
  constructor(props){
    super(props);
    this.state = {
      sort: 1,
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      pageNum: 1,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      downloadLoading: false,
      dataLoading: false
    }
  }

  componentDidMount() {
    this.setState({dataLoading: true});
    this.usersListApi(1, 1);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.usersListApi(this.state.pageNum, sort.value);
  }

  usersListApi(tempPagenum, tempSort){
    var url = devconfig.admin+"/users/list";
    var data = JSON.stringify({
      "page_num" : tempPagenum,
      "sort_value" : tempSort 
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        
        {/*Find lazy load or not*/}
        if(this.state.data.length && this.state.lazyLoadStatus){
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({data: tempCardArry});
        }
        else{
          this.setState({data: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({lazyDataEnd: true, dataLoading: false, lazyLoadStatus: false});
        }
        else{
          this.setState({lazyDataEnd: false, dataLoading: false, lazyLoadStatus: false});
        }
      }
      else{
        this.setState({ data: [], dataLoading: false, lazyLoadStatus: false});
      }
    }.bind(this))
  }
  
  loadMoreClick(){
    this.usersListApi(this.state.pageNum + 1, this.state.filter, this.state.sort);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
  }

  downloadClick(){
    this.setState({ downloadLoading: true });
    var url = devconfig.admin+"/users/download";
    var data = JSON.stringify({
      "sort_value" : this.state.sort 
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ downloadLoading: false });
        window.open(response.cdn_url, '_blank'); 
      }
      else{
        this.setState({ downloadLoading: false });
      }
      
    }.bind(this))
  }

  render() {
    const sortBy = [
      { value: 1, label: 'Name' },
      { value: 2, label: 'Top Viewed' },
      { value: 3, label: 'Latest Joind ' },
      { value: 4, label: 'Recent Activity' },
    ]
    return (
      <div className="e-main-content e-creators-wrap">
        <h2>App Users</h2>
        <div className="row">
          <div className="col-lg-2">
            {
              this.state.searchEnable ?
                null
              :
                <div className="e-filter-wrap pt-2">
                  <label>Sort by:</label>
                  <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
                </div>
            }
          </div>
          <div className="col-lg-10 text-right pt-5">
            {
              this.state.downloadLoading ?
                <span className="e-download-loader">
                  <LazyLoaderGif/>
                </span>
              :
                <span className="e-download-link" onClick={this.downloadClick.bind(this)}>Download List</span>
            }
          </div>
        </div>
        {
          this.state.dataLoading ?
            <div className="row">
              <div className="col-lg-12 text-center py-3">
                <LazyLoaderGif/>
              </div>
            </div>
          :
            <div className="row mt-4">
              {
                this.state.data.length ?
                  <div className="col-lg-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Recent Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          this.state.data.map( (data, index) => {
                            return(
                              <tr key={index}>
                                <td>
                                  {
                                    data.image ?
                                      <img className="mr-3 e-creator-pic" src={data.image } alt="Profile"/>
                                    :
                                      <img className="mr-3 e-creator-pic" src={profPlaceholder} alt="Profile"/>
                                  }
                                  
                                  <span className="e-creator-name">{data.name}</span>
                                </td>
                                <td className="e-creator-dull-txt">{data.email}</td>
                                <td className="e-creator-dull-txt">{data.last_activity}</td>
                                
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                    {
                      this.state.moreOpen ?
                        <span className="e-more-overlay" onClick={this.moreClose.bind(this)}></span>
                      :
                        null
                    }

                    {
                      !this.state.lazyDataEnd ?
                        <div className="text-center py-3">
                          <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                        </div>
                      :
                        null
                    }

                    {
                      this.state.lazyLoadStatus ?
                        <div className="text-center py-3">
                          <LazyLoaderGif/>
                        </div>
                      :
                        null
                    }
                  </div>
                :
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No invites found !"/>
                  </div>
              }
            </div>
        }
        
      </div>
      
    );
  }
}

export default UsersList;
