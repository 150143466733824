/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';
import ImageUploader from '../../components/common/imageUploader';
import UploadLoader from '../../components/common/uploadLoader';
/*Assets*/

class EditHomePopUp extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
    if(this.props.data){
    this.setState({logo: this.props.data.image, url: this.props.data.redirection_url, id: this.props.data.id})
  }

  }
  updateBanner=()=>{
    this.setState({updating: true});
    var url = devconfig.admin+"/notification/update-banner";
    var data = JSON.stringify({
      "image" : this.state.logo,
      "redirection_url" : this.state.url,
    });
    APIPost(url ,data).then(function(response){
      if(response.status){
        this.setState({updating: false});
        $('#editPopUp').modal('toggle');
        this.props.reload();
      }
      else{
        this.setState({updating: false})
      }
    }.bind(this))
  }
  async uploadStatus(status){
    this.setState({uploading: status})
  }

  logoUploaded(tempData){
    // alert(tempData)
    this.setState({logo: tempData})
  }

  async logoUploadTrigger(tempType){
    await this.setState({imgLoader: tempType});
    $("#e-prod-logo-upload").click()
  }

  hideLogin=()=> {
    $('#e-log-overlay1').remove();
  this.props.modalClose();
  }
  


  render() {

    return (
      <div className="modal fade e-edit-popup" id="editPopUp" tabIndex="-1" role="dialog" aria-labelledby="addCreatorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit & update home promotion banner</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mt-3">
              <div className="row pt-3">
              <div className="col-lg-12 e-select-product">
                <label>Banner Image</label>
                <div className="row">
                <div className="col-lg-12 position-relative">
                  {
                    this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                    :
                      <React.Fragment>
                        {
                          this.state.logo ?
                            <span className="e-img-preview">
                              <img src={this.state.logo}/>
                              <span className="e-change" onClick={()=>{ this.setState({logo: null}) }}></span>
                            </span>
                          :
                            <React.Fragment>
                              <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">Add Logo</span></span>
                           </React.Fragment>
                        }
                      </React.Fragment>
                  }
                  <ImageUploader
                    accept="application/pdf, image/*"
                    className="hidden"
                    tempKey="/prod/illustration"
                    id="e-prod-logo-upload"
                    uploadStatus={this.uploadStatus.bind(this)}
                    uploadSuccess={this.logoUploaded.bind(this)}
                  />
                  </div>
                  </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <label>Redirect URL</label>
              </div>
               <div className="col-lg-12" >
                <Input placeholder="Redirect Url" value={this.state.url} valueChange={(tempVal) => this.setState({url: tempVal})} />
                    </div>


            </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      <button type="submit" form="e-login-form" value="Submit" onClick={this.updateBanner.bind(this)}>Update promotion banner</button>
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditHomePopUp;
