

import React, { Component } from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
// 0:09:49
class TimePickerComponent extends Component {
    render() {

        // const format = 'hh:mm A';
        return (
            <div>
                <TimePicker
                    value={this.props.time}
                    placeholder={this.props.placeholder}
                    // format={format}
                    showHour={this.props.showHour}
                    showMinute={this.props.showMinute}
                    showSecond={this.props.showSecond}
                    use12Hours={this.props.use12Hours}
                    // defaultValue={dayjs("07:07:0", "HH:mm:ss")}
                    onChange={(time) => this.props.onChange(time)} />
                <p className="e-error-message pt-2 mb-0">{this.props.error}</p>
            </div>
        );
    }
}
export default TimePickerComponent;
