/*Package importing*/
import React, { Component } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Import custom components and assets*/

class Datepicker extends Component {
  constructor(props){
    super(props);
    this.state = {
      initalDate: null,
    }
    this.onDatesChange = this.onDatesChange.bind(this);
  }

  componentDidMount() {
    var tempToday = moment();
    this.setState({initalDate: tempToday.format()})
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
    if(startDate && endDate){
      var tempStart = startDate.format("DD-MM-YYYY");
      var tempEnd = endDate.format("DD-MM-YYYY");

      this.props.dateChange(tempStart, tempEnd)
    }
    
    if(!startDate && !endDate){
      this.props.dateChange(null, null)
    }
  }

  render() {

    return (
      <DateRangePicker
        startDate={this.state.startDate} //this.props.startDate
        startDateId={"e-start-date"} //this.props.startDateId
        endDate={this.state.endDate} //this.props.endDate
        endDateId={"e-end-date"} //this.props.endDateId
        onDatesChange={this.onDatesChange}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        startDatePlaceholderText={"Start date"}
        endDatePlaceholderText={"End date"}
        disableScroll={false}
        hideKeyboardShortcutsPanel={true}
        regular={false}
        small
        showClearDates
        numberOfMonths={2}
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
        {...this.props}
      />
    );
  }
}

export default Datepicker;
