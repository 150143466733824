/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';
import Toast from '../../components/toast/toast';

/*Assets*/
import profile from '../../assets/images/profile.png';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class AllCourseTutors extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: 1,
      filterDefault: {value: null, label: 'All course tutors'},
      sortDefault: {value: 1, label: 'Tutor name'},
      search: "",
      data: [],
      pageNum: 1,
      searchEnable: false,
      moreOpen: 0,
      muteLoading: false,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      dataLoading: true,
      payNowItem: null
    }
  }

  componentDidMount() {
    this.creatorsListApi(this.state.pageNum, null, 1);
  }

  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.creatorsListApi(this.state.pageNum, filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.creatorsListApi(this.state.pageNum, this.state.filter, sort.value);
  }

  creatorsListApi(tempPagenum, tempFilter, tempSort){
    if(this.state.tempPagenum ==  1){
      this.setState({dataLoading: true});
    }
    
    var url = devconfig.admin + "/creator/course-creators";
    var data = JSON.stringify({
      "page_num" : tempPagenum,
      "sort_value" : tempSort,
      "filter_value" : tempFilter
    })

    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Find lazy load or not*/}
        if(this.state.data.length && this.state.lazyLoadStatus){
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({data: tempCardArry});
        }
        else{
          this.setState({data: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({lazyDataEnd: true, lazyLoadStatus: false, dataLoading: false});
        }
        else{
          this.setState({lazyDataEnd: false, lazyLoadStatus: false, dataLoading: false});
        }
      }
      else{
        this.setState({ data: [], lazyLoadStatus: false, dataLoading: false});
      }
    }.bind(this))
  }


    downloadData=()=>{
    var url = devconfig.admin + "/creator/download-course-creators";
    var data = JSON.stringify({
      "page_num" : this.state.pageNum,
      "sort_value" : this.state.sort,
      "filter_value" : this.state.filter
    })

    APIPost(url, data).then(function(response){
      if(response.status){
          if(response.file_url){
            window.open(response.file_url);
          }else{
            this.openToast();
          }
      }
    }.bind(this))
  }
  creatorsSearchApi(tempText){
    var url = devconfig.admin+"/creator/search";
    var data = JSON.stringify({
      "text" : tempText
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        this.setState({ data: response.data});

      }
      else{
        this.setState({ data: []});
      }
    }.bind(this))
  }

  searchChange(e){
    this.setState({
      search: e.target.value
    });

    if(e.target.value.length >= 3){
      this.creatorsSearchApi(e.target.value);
      this.setState({
        searchEnable: true
      });

      this.clearFilter();  
    }

    if(!e.target.value.length){
      this.creatorsListApi(1, null, 1);
      this.setState({
        searchEnable: false,
      });
    }
  }

  openToast=()=>{
     this.setState({toast: "No data found for download",  toastType:"fail"})
    setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
  }
  clearFilter(){
    this.setState({
      filterDefault: {value: null, label: 'All Creators'},
      sortDefault: {value: 1, label: 'Creator name'},
      filter: null,
      sort: 1,
    });
  }

  moreClick(tempId){
    this.setState({moreOpen: tempId});
  }

  moreClose(){
    this.setState({moreOpen: 0});
  }

  muteClick(tempId, tempStatus){
    this.setState({ muteLoading: true});

    var url = devconfig.admin+"/creator/mute-or-unmute";
    var data = JSON.stringify({
      "creator_id" : tempId,
      "mute" : tempStatus
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.clearFilter();
        this.creatorsListApi(1, null, 1);
        this.setState({moreOpen: 0, muteLoading: false});
      }
      else{
        this.setState({ errorMessage: response.message, muteLoading: false});
      }
    }.bind(this))
  }

  
  loadMoreClick(){
    this.creatorsListApi(this.state.pageNum + 1, this.state.filter, this.state.sort);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    
  }

  payNow(tempId, amount){
    this.setState({ payNowLoading: true, payNowItem: tempId});
    var url = devconfig.admin+"/creator/pay-now";
    var data = JSON.stringify({
      "creator_id" : tempId,
      "amount": amount
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.creatorsListApi(1, null, 1);
        this.setState({payNowLoading: false, payNowItem: null});
      }
      else{
        this.setState({ errorMessage: response.message, payNowLoading: false, payNowItem: null});
      }
    }.bind(this))
  }

  enableWebinars(tempId,channelLink){
    this.setState({ muteLoading: true});
    var url = devconfig.admin+"/creator/enable-webinars";
    var data = JSON.stringify({
      "creator_id" : tempId,
      "channel_link": channelLink
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.creatorsListApi(1, null, 1);
        this.setState({muteLoading: false, moreOpen: 0});
      }
      else{
        this.setState({ errorMessage: response.message, muteLoading: false, moreOpen: 0});
      }
    }.bind(this))
  }

  render() {
    const filterBy = [
      { value: null, label: 'All course tutors' },
      { value: 1, label: 'Top 10' },
      { value: 2, label: 'Muted' },
    ]
    const sortBy = [
      { value: 1, label: 'Tutors name' },
      { value: 2, label: 'Video count' },
    ]
    return (
      <div className="e-main-content e-creators-wrap">
        <h2>All Course Tutors</h2>
        <div className="row">
          <div className="col-lg-2">
            {
              this.state.searchEnable ?
                null
              :
                <div className="e-filter-wrap">
                  <label>Filter by:</label>
                  <SelectBox defaultValue={this.state.filterDefault} options={filterBy} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
            }
          </div>
          <div className="col-lg-2">
            {
              this.state.searchEnable ?
                null
              :
                <div className="e-filter-wrap">
                  <label>Sort by:</label>
                  <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
                </div>
            }
          </div>
          <div className="col-lg-2 mt-1">
          {
            this.state.searchEnable ?
            null
            :
            <div className="mt-4">
              <a className="e-download-link" onClick={this.downloadData} target="_blank">Download CSV</a>
            </div>
          }
            
          </div>
          <div className="col-lg-6">
            <div className="e-search-wrap text-right">
              <input className="e-search-input" placeholder="Search for a tutors" value={this.state.value} type="text" onChange={this.searchChange.bind(this)} required/>
            </div>
          </div>
        </div>
        {
          !this.state.dataLoading ?
            <div className="row mt-4">
              {
                this.state.data.length ?
                  <div className="col-lg-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tutor</th>
                          <th>Product Code</th>
                          <th>No. of courses</th>
                          {/*<th>Next Schedule</th>*/}
                          <th>Paid courses</th>
                          <th>Incentive percentage</th>
                          <th>Balance incentive</th>
                          <th>Pay incentive</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          this.state.data.map( (data, index) => {
                            var tempClass = "position-relative";
                            if(data.muted){
                              tempClass = tempClass + " e-muted";
                            }

                            var tempURL = "/detail?id="+data.id;
                            
                            var tempLink = "https://www.youtube.com/channel/"+data.channel_id;
                            return(
                              <tr className={tempClass} key={index}>
                                {/*<td className="pr-0">
                                  {
                                    data.image ?
                                      <img className="e-creator-pic" src={data.image} alt="Profile"/>
                                    :
                                      <img className="e-creator-pic" src={profPlaceholder} alt="Profile"/>
                                  }
                                </td>*/}
                                <td className="e-creator-dull-txt"><a className="e-detail-link" href={tempURL}><span className="e-creator-name ">{data.name}</span></a></td>
                                <td className="e-creator-dull-txt">{data.code ? data.code : "--"}</td>
                                <td className="e-creator-dull-txt">{data.no_of_courses}</td>
                                <td className="e-creator-dull-txt">{data.no_of_paid_courses}</td>
                                <td className="e-creator-dull-txt">{data.incentive_percentage}%</td>
                                <td className="e-creator-dull-txt">₹ {data.balance_incentive}</td>

                                {
                                  data.balance_incentive ?
                                    <React.Fragment>
                                      {
                                        this.state.payNowLoading && this.state.payNowItem == data.id ?
                                          <td className="e-creator-dull-txt e-paynow-txt">
                                            <LazyLoaderGif/>
                                          </td>
                                        :
                                          <td className="e-creator-dull-txt e-paynow-txt" onClick={()=>this.payNow(data.id, data.balance_incentive)}>{"Pay Now"}</td>
                                      }
                                    </React.Fragment>
                                  :
                                    <td className="e-creator-dull-txt">No pending payments</td>
                                }
                                
                                
                                <td className="position-relative">
                                  <span className="e-more-icon" onClick={this.moreClick.bind(this, data.id)}></span>
                                  {
                                    this.state.moreOpen === data.id ?
                                      <span className="e-creator-item-more" >
                                        {
                                          this.state.muteLoading ?
                                            <span className="e-api-loader"></span>
                                          :
                                            <span>
                                              {
                                                data.muted ?
                                                  <span className="e-mute-txt e-creator-item" onClick={this.muteClick.bind(this, data.id, false)}>Un-mute {data.name}</span>
                                                :
                                                  <span className="e-mute-txt e-creator-item" onClick={this.muteClick.bind(this, data.id, true)}>Mute {data.name}</span>
                                              }
                                              <span className="e-creator-item" onClick={()=>this.enableWebinars(data.id, data.channel_link)}>Enable Webinars</span>
                                            </span>
                                            
                                        }
                                      </span>
                                    :
                                      null
                                  }
                                </td> 
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                    {
                      this.state.moreOpen ?
                        <span className="e-more-overlay" onClick={this.moreClose.bind(this)}></span>
                      :
                        null
                    }

                    {
                      !this.state.lazyDataEnd && !this.state.lazyLoadStatus ?
                        <div className="text-center py-3">
                          <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                        </div>
                      :
                        null
                    }

                    {
                      this.state.lazyLoadStatus ?
                        <div className="text-center py-3">
                          <LazyLoaderGif/>
                        </div>
                      :
                        null
                    }
                  </div>
                :
                  <div className="col-lg-12 text-center">
                    <NothingFound data="No tutors found !"/>
                  </div>
              }
              
            </div>
          :
            <div className="row">
              <div className="col-lg-12 text-center pt-5">
                <LazyLoaderGif/>
              </div>
            </div>

        }
        {
            this.state.toast ?
              <Toast data={this.state.toast} type={this.state.toastType}/>
            :
            null
           }
        
      </div>
      
    );
  }
}

export default AllCourseTutors;
