/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import Input from '../../components/formComponents/input';
import Select from '../../components/common/selectBox';
import Toast from '../../components/toast/toast';

/*import services*/
import devconfig from '../../config/config'; 
import APIPost from '../../services/apiCall/post';

class UpdateInvites extends Component {
  constructor(props){
    super(props);
    this.state = {


    }
  }

  componentDidMount(){
    $('#update-invite').on('show.bs.modal',  (event)=>{
      this.setState({
        id: this.props.data.id,
        name: this.props.data.name,
        mail: this.props.data.mail,
        phone: this.props.data.phone,
        code: this.props.data.code,
        link: this.props.data.channel,
        type: this.props.data.type == 1 ? { value: 1, label: 'Webinar tutor only' } : this.props.data.type == 2 ? { value: 2, label: 'Course tutor only' } : { value: 3, label: 'Both webinar & course tutor' },
        sortDefault: this.props.data.type == 1 ? { value: 1, label: 'Webinar tutor only' } : this.props.data.type == 2 ? { value: 2, label: 'Course tutor only' } : { value: 3, label: 'Both webinar & course tutor' },
      })
    })
  }

  proceedClick(){
    let url = devconfig.admin+'/creator/update-invite';
    let data = JSON.stringify({
      "invited_id" : parseInt(this.state.id),
      "name" : this.state.name,
      "email" : this.state.mail,
      "mobile" : this.state.phone,
      "code" : this.state.code,
      "type" : this.state.type.value,
      "channel_link" : this.state.link,
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        $('#update-invite').trigger('click');
        this.props.confirmSuccess();
      }
      else{
        this.setState({ toast: true, message: response.message, type: 'fail'});
      }
    })
    setTimeout(()=>{
      this.setState({ toast: false})
    },3000)
  }

  render() {
    const tutorType = [
      { value: 3, label: 'Both webinar & course tutor' },
      { value: 1, label: 'Webinar tutor only' },
      { value: 2, label: 'Course tutor only' },
    ]
    return(
      <div className="modal fade e-modal-wrap" id="update-invite" tabIndex="-1" role="dialog" aria-labelledby="e-confirmModalLabel" aria-hidden="true">
        <div className="modal-dialog  e-modal-confirm" role="document">
          <div className="modal-content">
            <div className="text-right">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-2">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <h5 className="pb-3">Update Invitation</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Name*" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Email*" id="u-mail" type="text" value={this.state.mail} valueChange={(tempVal) => this.setState({mail: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Phone*" id="u-mail" type="text" value={this.state.phone} valueChange={(tempVal) => this.setState({phone: tempVal})} />
                </div>
                <div className="col-lg-12">
                  <Input label="Code*" id="u-mail" type="text" value={this.state.code} valueChange={(tempVal) => this.setState({code: tempVal})} />
                </div>
                <div className="e-filter-wrap mb-4 e-form-select col-lg-12">
                  <label>Tutor Type*</label>
                  <Select defaultValue={this.state.sortDefault} options={tutorType} value={this.state.type} selectchange={(temp)=> this.setState({ type: temp })} placeholder="Tutor type"/>
                </div>
                <div className="col-lg-12">
                  {
                    this.state.type && this.state.type.value != 2 ? 
                      <Input label="Youtube Channel Link" id="u-link" type="text" value={this.state.link} valueChange={(tempVal) => this.setState({link: tempVal})} />
                    :
                      null
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-right">
                  <span className="e-cancel-confirm mr-4" data-dismiss="modal">Cancel</span>
                  <button className="e-p-btn" onClick={this.proceedClick.bind(this)}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.toast ?
            <Toast data={this.state.message} type={this.state.type}/>
          :
            null
        }
      </div>
    )
  }
}

export default UpdateInvites;
