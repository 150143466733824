/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/

/*Assets*/
import logo from '../../assets/images/logo.svg';
import profile from '../../assets/images/profile-placeholder.jpg';
import $ from 'jquery';



class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
      adminData: {}
    }
  }

  componentWillMount() {
    this.findActiveItem();

    this.setState({ adminData: $.parseJSON(localStorage.getItem("u_data")) });
  }

  findActiveItem() {
    var tempUrl = [];
    tempUrl = window.location.href.split("/");
    this.setState({ activeItem: tempUrl[tempUrl.length - 1] });
  }

  navItemClick(item) {
    this.setState({ activeItem: item });
  }

  render() {
    return (
      <div className="e-sidebar">
        <div className="e-sidebar-logo px-4">
          <img draggable="false" src={logo} alt="Tradeschool logo" />
        </div>
        <div className="e-sidebar-profile-wrap p-4">
          <div className="media">
            {
              this.state.adminData.image ?
                <img src={this.state.adminData.image} alt="Profile" />
                :
                <span className="e-prf-placeholder">AB</span>
            }
            <div className="media-body ml-3">
              <h5 className="m-0">{this.state.adminData.name}</h5>
              <span className="e-email e-sidebar-email">{this.state.adminData.email}</span>
            </div>
          </div>
        </div>
        <div className="e-sidebar-list-wrap p-4">
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "dashboard" || this.state.activeItem === "" ?
              
                <Link className="e-sidebar-dashboard e-active" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
                :
                <Link className="e-sidebar-dashboard" onClick={this.navItemClick.bind(this, "dashboard")} to="/dashboard">Dashboard</Link>
            }
          </div>
          {/* <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "webinar-tutors" || this.state.activeItem === "webinar-creator-detail"?
                <Link className="e-sidebar-creators e-active" onClick={this.navItemClick.bind(this, "webinar-tutors")} to="/webinar-tutors">Webinar Tutors</Link>
                :
                <Link className="e-sidebar-creators" onClick={this.navItemClick.bind(this, "webinar-tutors")} to="/webinar-tutors">Webinar Tutors</Link>
            }
          </div> */}
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "webinar" || this.state.activeItem === "webinar-detail" ?
                <Link className="e-sidebar-webinar e-active" onClick={this.navItemClick.bind(this, "webinar")} to="/webinar">Webinar</Link>
                :
                <Link className="e-sidebar-webinar" onClick={this.navItemClick.bind(this, "webinar")} to="/webinar">Webinar</Link>
            }
          </div>
          {/* <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "course-tutors" ?
                <Link className="e-sidebar-course e-active" onClick={this.navItemClick.bind(this, "course-tutors")} to="/course-tutors">Course Tutors</Link>
                :
                <Link className="e-sidebar-course" onClick={this.navItemClick.bind(this, "course-tutors")} to="/course-tutors">Course Tutors</Link>
            }
          </div> */}
          {/* <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "courses" ?
                <Link className="e-sidebar-courses e-active" onClick={this.navItemClick.bind(this, "courses")} to="/courses">Courses</Link>
                :
                <Link className="e-sidebar-courses" onClick={this.navItemClick.bind(this, "courses")} to="/courses">Courses</Link>
            }
          </div> */}
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "manage-banners" ?
                <Link className="e-sidebar-banners e-active" onClick={this.navItemClick.bind(this, "manage-banners")} to="/manage-banners">Manage banners</Link>
                :
                <Link className="e-sidebar-banners" onClick={this.navItemClick.bind(this, "manage-banners")} to="/manage-banners">Manage banners</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "invites" ?
                <Link className="e-sidebar-invites e-active" onClick={this.navItemClick.bind(this, "invites")} to="/invites">Invites</Link>
                :
                <Link className="e-sidebar-invites" onClick={this.navItemClick.bind(this, "invites")} to="/invites">Invites</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "users" ?
                <Link className="e-sidebar-users e-active" onClick={this.navItemClick.bind(this, "users")} to="/users">App Users</Link>
                :
                <Link className="e-sidebar-users" onClick={this.navItemClick.bind(this, "users")} to="/users">App Users</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "invoices" ?
                <Link className="e-sidebar-invoices e-active" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Payment Reports</Link>
                :
                <Link className="e-sidebar-invoices" onClick={this.navItemClick.bind(this, "invoices")} to="/invoices">Payment Reports</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "settings" ?
                <Link className="e-sidebar-categories e-active" onClick={this.navItemClick.bind(this, "settings")} to="/settings">Settings</Link>
                :
                <Link className="e-sidebar-categories" onClick={this.navItemClick.bind(this, "settings")} to="/settings">Settings</Link>
            }
          </div>
          <div className="e-sidebar-item pb-4">
            {
              this.state.activeItem === "notification" ?
                <Link className="e-sidebar-notify e-active" onClick={this.navItemClick.bind(this, "notification")} to="/notification">Notification</Link>
                :
                <Link className="e-sidebar-notify" onClick={this.navItemClick.bind(this, "notification")} to="/notification">Notification</Link>
            }
          </div>
          <div className="e-sidebar-item">
            {
              this.state.activeItem === "refferals" ?
                <Link className="e-sidebar-ref e-active" onClick={this.navItemClick.bind(this, "refferals")} to="/refferals">Refferals</Link>
                :
                <Link className="e-sidebar-ref" onClick={this.navItemClick.bind(this, "refferals")} to="/refferals">Refferals</Link>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
