
/*import package*/
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import ReadMoreModal from '../modals/readmore';

const WebinarDetailCard = (props) => {

    useEffect(() => {
        console.log('props::', props.detailData.language_ids)
    }, [props.detailData])

    // if (!props.detailData) {
    //     return <p>Loading...</p>;
    // }

    return (
        <>

            <div className="card e-webinar-detail-card e-border-radius-8px">
                <div className='row'>
                    <div className='col-lg-4 '>
                        <img draggable="false"
                            src={props.detailData.thumbnail}
                            className="e-webinar-detail-img"
                            alt="thumbnail" />
                    </div>
                    <div className='col-lg-8'>
                        <div className='pt-4 pb-4 pl-0 pr-4'>
                            {props.detailData.type == 1 ?
                                <span className=" e-upcoming">Upcoming</span> :
                                props.detailData.type == 2 ?
                                    <span className="e-upcoming">Completed</span>
                                    :
                                    <span className="e-upcoming">Ongoing</span>
                            }
                            <h5 className='mt-2'>{props.detailData.name}</h5>
                            <div className='e-editor-description'>
                                {/* <p >{parse(props.detailData.description)} */}
                                {
                                    props.detailData.description && props.detailData.description.length > 400 ?
                                        (
                                            <>
                                                {parse(props.detailData.description.substring(0, 400) + "...")}
                                                <a className='e-primary'
                                                    data-toggle="modal"
                                                    data-target="#readmoreWebinarModal">
                                                    Read more
                                                </a>
                                            </>

                                        )
                                        :
                                        parse(props.detailData.description)
                                }
                                {/* </p> */}

                            </div>

                            <div>
                                <div className='e-content-wrap d-inline-flex pt-3'>
                                    {/* <p className='mb-0'>Host by: <span className='e-blue'>John Doe</span></p> */}
                                    <p className='mb-0'>Date:
                                        <span className='e-blue pl-1'>{props.detailData.scheduled_date}</span>
                                    </p>
                                    <p className='mb-0'>Duration:
                                        <span className='pl-1'>{props.detailData.duration}</span>
                                    </p>
                                    <p className='mb-0'>Languges:
                                        {props.detailData.language_ids && props.detailData.language_ids.map((language, key) => (
                                            <>
                                                <span key={key} className='pl-1'>{language.language}</span>
                                                {
                                                    key !== props.detailData.language_ids.length - 1 && (
                                                        <span className='pl-1'>•</span>
                                                    )
                                                }
                                            </>
                                        ))}
                                    </p>
                                    {/* <p className='mb-0'>Cost: <span>₹4,000</span></p> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
            <ReadMoreModal detailData={props.detailData} />
        </>

    );
}
export default WebinarDetailCard;

