/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import Datepicker from '../../components/common/datepicker';
import AddCreator from '../../components/modals/addCreator';
import NothingFound from '../../components/common/nothingFound';
import UpdateInvite from '../../components/modals/updateInvite';
import ConfirmModal from '../../components/modals/ConfirmationModal';

/*Assets*/
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';

class InvitesList extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: [],
      pageNum: 1,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      resetModal: true,
      dataLoading: false,
      statusSelected: null,
      startDate: null,
      endDate: null,
      tabIndex: 1,
      dataRequestLoading: false,
      sending: null,
      selectedRequest: {}, 
      sendId: []
    }
  }

  componentDidMount() {
    this.recentInvitesApi(this.state.statusSelected, this.state.startDate, this.state.endDate);

    this.creatorRequestApi();
  }

  creatorRequestApi(){
    this.setState({ dataRequestLoading: true});
    var url = devconfig.admin+"/creator/requests";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ dataRequests: response.data, dataRequestLoading: false});
      }
      else{
        this.setState({ dataRequests: [], dataRequestLoading: false});
      }
    }.bind(this))
  }
  recentInvitesApi(tempStatus, tempStart, tempEnd){
    this.setState({ resetModal: false, dataLoading: true});
    var url = devconfig.adminV2+"/creator/recent-invites";

    var data = JSON.stringify({
      "accepted" : tempStatus,
      "start_date" : tempStart,
      "end_date" : tempEnd 
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ data: response.data, resetModal: true, dataLoading: false});
      }
      else{
        this.setState({ data: [], resetModal: true, dataLoading: false});
      }
    }.bind(this))
  }

  dateChangeEvent(tempStart, tempEnd){
    this.recentInvitesApi(this.state.statusFilterSelected, tempStart, tempEnd)
    this.setState({ startDate: tempStart, endDate: tempEnd});
  }

  statusFilterChange(tempItem){
    this.setState({ statusFilterSelected: tempItem.value});
    this.recentInvitesApi(tempItem.value, this.state.startDate, this.state.endDate)
  }

  download(){
    this.setState({ downloadLoading: true});
    var url = devconfig.admin+"/creator/download-recent-invites";

    var data = JSON.stringify({
      "accepted" : this.state.statusSelected,
      "start_date" : this.state.startDate,
      "end_date" : this.state.endDate 
    })

    APIPost(url, data).then(function(response){

      if(response.status){
        window.open(response.cdn_url, '_blank');
        this.setState({ downloadLoading: false});
      }
      else{
        this.setState({ downloadLoading: false});
      }
    }.bind(this))
  }

  tabItemClick(tempId){
    this.setState({ tabIndex: tempId});
  }

  sendInvites(tempData){
    this.setState({ selectedRequest: tempData});
    $('#addCreatorModal').modal('show');
    
  }

  async edit(temp){
    await this.setState({update_invite: { id: temp.id, name: temp.name, mail: temp.email, phone: temp.mobile, code: temp.code, type: temp.type, channel: temp.channel_link}})
    await $('#update-invite').modal('show');
  }

  triggerToast = () =>{
    this.setState({ toast: true, message: 'Invitatoin updated', type: 'success'});
    this.recentInvitesApi();
    setTimeout(()=>{ this.setState({ toast: false })}, 4000)
  }

  resentInvite = (temp) =>{
    var tempArray = this.state.sendId;
    tempArray.push(temp);
    this.setState({ sending: true, sendId: tempArray});
    let url = devconfig.admin+'/creator/resend-invite';
    let data = JSON.stringify({
      "invited_id" : parseInt(temp)
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        this.setState({send: true, sending: false});
      }
      else{
        this.setState({ toast: true, message: response.message, type: 'fail'});
      }
    })
    setTimeout(()=>{ this.setState({ toast: false})}, 3000)
  }

  deleteInvite = () =>{
    let url = devconfig.admin+'/creator/delete-invite';
    let data = JSON.stringify({ 
      "invited_id" : parseInt(this.state.deleteId)
    })
    APIPost(url, data).then((response)=>{
      if(response.status){
        this.setState({
          toast: true,
          message: 'Invitation removed!',
          type: 'success'
        });
        this.recentInvitesApi();
      }
      else{
        this.setState({
          toast: true,
          message: response.message,
          type: 'fail',
        })
      }
    })
    setTimeout(()=>{ this.setState({ toast: false}) }, 3000)
  }
  render() {

    var tempOptions = [
      { label: "All", value: null},
      { label: "Accepted", value: true},
      { label: "Pending", value: false},
    ]
    var tempType = ["Both webinar & course tutor", "Webinar tutor only", "Course tutor only"];
    
    return (
      <div className="e-main-content e-invites-wrap">
        <h2>Recent Invites</h2>

        <div className="row mb-4">
          <div className="col-lg-10">
            {
              this.state.tabIndex == 1 ?
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 1)}>Already Invited</span>
                  <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>New Creator Request</span>
                </div>
              :
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Already Invited</span>
                  <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 2)}>New Creator Request</span>
                </div>
            }
            
          </div>
        </div>

        
        {
          this.state.tabIndex == 1 ?
            <React.Fragment>
              <div className="row">
                <div className="col-lg-3">
                  <div className="e-filter-wrap">
                    <label>Filter by date:</label>
                    <div className="e-invites-date-wrap">
                      <Datepicker dateChange={this.dateChangeEvent.bind(this)}/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-filter-wrap">
                    <label>Filter by status:</label>
                    <SelectBox defaultValue={this.state.filterTypeDefault} options={tempOptions} selectchange={this.statusFilterChange.bind(this)} placeholder="All"/>
                  </div>
                </div>
                <div className="col-lg-2">
                  {
                    this.state.data.length ?
                      <span className="e-download-link e-invites-download" onClick={this.download.bind(this)}>Download</span>
                    :
                      null
                  }
                </div>
                <div className="col-lg-5 text-right mt-4">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addCreatorModal">Add New Tutor</button>
                </div> 
              </div>
              {
                this.state.dataLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    {
                      this.state.data.length ?
                        <div className="col-lg-12">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Invited on</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                this.state.data.map( (data, index) => {
                                  return(
                                    <tr  key={index}>
                                      <td>
                                        {
                                          data.image ?
                                            <img className="mr-3 e-creator-pic" src={data.image } alt="Profile"/>
                                          :
                                            <img className="mr-3 e-creator-pic" src={profPlaceholder} alt="Profile"/>
                                        }
                                        
                                        <span className="e-creator-name">{data.name}</span>
                                      </td>
                                      <td className="e-creator-dull-txt">{data.email}</td>
                                      <td className="e-creator-dull-txt">{data.invited_on}</td>
                                      {
                                        !data.active ?
                                          <td className="e-status-invited">
                                            <span>Invited</span>
                                          </td>
                                        :
                                          <td className="e-status-accepted">
                                            <span>Accepted</span>
                                          </td>
                                      }
                                      <td>
                                        <span className="mr-4 e-action-btn" onClick={()=> this.resentInvite(data.id)}>{this.state.sending && this.state.sendId.includes(data.id) ? 'Sending...' : this.state.send && this.state.sendId.includes(data.id) ? 'Success' : 'Resent'}</span>
                                        <span className="mr-4 e-action-btn" onClick={()=> this.edit(data)}>Edit</span>
                                        <span className="e-action-btn" data-target="#e-confirmModal" data-toggle="modal" onClick={()=>{ this.setState({ deleteId: data.id})}}>Delete</span>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                          
                        </div>
                      :
                        <div className="col-lg-12 text-center">
                          <NothingFound data="No invites found !"/>
                        </div>
                    }
                  </div>
              }
              
              {
                this.state.resetModal ?
                  <AddCreator id={"addCreatorModal"} success={this.recentInvitesApi.bind(this)}/>
                :
                  null
              }
            </React.Fragment>
          :
            <React.Fragment>
              {
                this.state.dataRequestLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    {
                      this.state.dataRequests.length ?
                        <div className="col-lg-12">
                          <table className="table e-creator-requests-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                this.state.dataRequests.map( (data, index) => {
                                  return(
                                    <React.Fragment>
                                    <tr key={index}>
                                      <td>
                                        <span className="e-creator-name">{data.name}</span>
                                      </td>
                                      <td className="e-creator-dull-txt">{data.email}</td>
                                      <td className="e-creator-dull-txt">{data.mobile}</td>
                                      <td className="e-creator-dull-txt">{tempType[data.type]}</td>
                                      <td className="">
                                        <span className="e-link-text" onClick={this.sendInvites.bind(this, data)} data-toggle="modal" data-target={"#addCreatorModal"+data.id}>Invite now</span>
                                      </td>
                                    </tr>
                                    <AddCreator id={"addCreatorModal"+data.id} success={this.recentInvitesApi.bind(this)} data={data}/>
                                    </React.Fragment>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                          
                        </div>
                      :
                        <div className="col-lg-12 text-center">
                          <NothingFound data="No new requests found !"/>
                        </div>
                    }
                  </div>
              }

            </React.Fragment>
        }

        <UpdateInvite data={this.state.update_invite} confirmSuccess={()=> this.triggerToast()}/>
        <ConfirmModal modalTitle="Confirmation" modalContent="Are you sure want to remove this invitation?" confirmSuccess={()=>{this.deleteInvite() }}/>
      </div>
      
    );
  }
}

export default InvitesList;
