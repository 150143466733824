
/*import package*/
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const HeroBannerCard = (props) => {

  const [states, setStates] = useState(false);

  useEffect(() => {
    setStates(props.checked)
  }, [props.checked])

  return (
    <>
      {
        props.type === 1 ?
          <>
            <div className="card e-webinarcard e-hero-banner-card" style={{cursor: "pointer"}}>
              <img src={props.data.img} className="card-img-top" alt="" />
              <span className='e-checkbx-span' style={{ left: "8px" }}>
                <input type="checkbox" checked={states} style={{ cursor: "pointer" }} />
                <span className="checkmark"></span>
              </span>
              <div className="card-body">
                <h5 className="card-title mb-0">{props.data.name}</h5>
              </div>
            </div>
          </>
          :
          <>
            <div className="card e-webinarcard e-hero-banner-card" style={{cursor: "pointer"}}>
              <img src={props.data.img} className="card-img-top" alt="" />
              <span className='e-checkbx-span' style={{ left: "8px" }}>
                <input type="checkbox" checked={states} style={{ cursor: "pointer" }} />
                <span className="checkmark"></span>
              </span>
              <div className="card-body">
                <h5 className="card-title mb-0">{props.data.name}</h5>
              </div>
            </div>
          </>
      }
    </>



  );
}
export default HeroBannerCard;

