/*Package importing*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";
import $ from 'jquery';

/*Import custom components and assets*/

class LineChart extends Component {
  constructor(props){
    super(props);
    this.state = {
      series: [{ name: 'Total Bookings',  data: [40, 5500, 4100, 6400], type: 'area'}, { name: 'Total Bookings',  data: [22, 444,5555, 2260], type: 'area'}],
      options: {
        stroke: {curve: 'smooth'},
        legend: {fontSize: '13px', fontFamily: 'Nunito', fontWeight: "20px"},
        chart: { type: 'area', height: 730},
        plotOptions: { bar: { horizontal: false, dataLabels: {position: 'top'}} },
        dataLabels: { enabled: false, offsetX: -6, style: { fontSize: '12px', colors: ['#fff'] } },
        stroke: { show: true, width: 2, colors: ['#002A5C'] },
        markers: {
          size: 0
        },
        labels: ["Week 1","Week 2","Week 3","Week 4"],
        fill: {
          colors: ['#2a8ff7',"#68e397"],
          type:'solid',
          opacity: [0.4, 1],
        },
        tooltip: {
          shared: true,
          colors: ['#FFB606'],
        }
      },
    }
  }

  componentDidMount() {
    if(this.props.data){
      var tempVal1 = [];
      var tempVal2 = [];
      var tempXAxis = [];

      $.each(this.props.data, function( key, value ) {
        tempVal1.push(value.enroll_count);
        tempVal2.push(value.view_count);
        tempXAxis.push(key);
      });

      this.setState({ 
        series: [{ name: 'Enrolled',  data: tempVal1, type: 'area'}, { name: 'Viwers',  data: tempVal2, type: 'area'},],
        options:  {
          xaxis: { categories: tempXAxis },
        }
      });
    }
  }

  render() {
    return (
      <Chart options={this.state.options} series={this.state.series} type="bar" height="400px" width="100%"/>
    );
  }
}

export default LineChart;
