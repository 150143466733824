// const url= "http://localhost:5000/api/v1";

// dev url
// const devV1= "https://j4b1yj8zg7.execute-api.ap-south-1.amazonaws.com/dev/api/v1";
// const devV2= "https://j4b1yj8zg7.execute-api.ap-south-1.amazonaws.com/dev/api/v2";

// stagging url
 // const staggingV1= "https://8bugtbiaji.execute-api.ap-south-1.amazonaws.com/staging/api/v1";
 // const staggingV2= "https://8bugtbiaji.execute-api.ap-south-1.amazonaws.com/staging/api/v2";


// prod url
const url = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v1";
const url2 = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v2";

var devconfig = {
//   admin: devV1 + "/admin",
//   adminV2: devV2 + "/admin",
    admin: url + "/admin",
    adminV2: url2 + "/admin",
}

module.exports = devconfig;