
import React, { useState, useEffect } from 'react';


const SearchInput = (props) => {

  const [value, setValue] = useState();

  const valueChange = (e) => {
    setValue(e.target.value);
    props.valueChange(e.target.value);
  }

  const keyPress = (e) => {

    if (e.keyCode === 13 && props.onSearch) {
      props.onSearch(e.target.value);
    }
  }

  return (
    <React.Fragment>
      <div className="e-input-wrap">
        <input id={props.id}
          value={props.value}
          type={props.type}
          className={"e-search-input-box" + " " + props.className}
          onChange={(e) => { valueChange(e) }}
          placeholder={props.placeholder}
          onKeyDown={(e) => { keyPress(e) }}
          {...props} />
      </div>
    </React.Fragment>
  );
}
export default SearchInput;


