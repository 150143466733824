/*Package importing*/
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, createFromBlockArray, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
/*Components*/
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';
import LineChart from '../../components/charts/line';
import EditCourseModal from '../../components/modals/course/editCourse';
import CreateModuleModal from '../../components/modals/course/createModule';
import EditModuleModal from '../../components/modals/course/editModule';
import RemoveReview from '../../components/modals/removeReview';
import VideoPlayerModal from '../../components/modals/course/videoPlayer';
import Confirmation from '../../components/modals/ConfirmationModal';
import Toast from '../../components/toast/toast';
/*Assets*/
import profile from '../../assets/images/profile.png';
import courseCover from '../../assets/images/course-cover.jpg';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';
import Delete from '../../assets/images/delete-icon-red.svg';

class CourseDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: 1,
      filterTypeDefault: {value: null, label: 'All Type'},
      filterCategoryDefault: {value: null, label: 'All Category'},
      filterStatusDefault: {value: 1, label: 'Published'},
      sortDefault: {value: 1, label: 'Last 7 days'},
      filterRateDefault: {value: null, label: 'All reviews'},
      search: "",
      data: {},
      pageNum: 1,
      searchEnable: false,
      moreOpen: 0,
      muteLoading: false,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      dataLoading: true,
      tabItemActive: 1,
      editorState: EditorState.createEmpty(),
      reviewPageNum: 1,
      modules: [],
      reviews: [],
      wysiwygChange: false,
      descriptionUpdating: false,
      student: {total_view: 0, total_enrolled: 0},
      studentsLoading: true,
      students: [],
      studentsPagenum: 1,
      reviewsLazyDataEnd: true,
      reviewsLoading: true
    }
  }

  componentDidMount() {
    var query = this.getUrlVars();
    this.setState({ id: query.id });
    this.getData(query.id);
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.getData(this.state.pageNum, filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.getData(this.state.pageNum, this.state.filter, sort.value);
  }

  async getData(id){
    this.setState({dataLoading: true});
    var url = devconfig.admin + "/course/detail";
    var data = JSON.stringify({
      "id" : Number(id)
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({data: response.data, dataLoading: false });
      }
      else{
        this.setState({ data: {}, lazyLoadStatus: false, dataLoading: false});
      }
      this.getModules();
    }.bind(this))
  }

  getModules(){
    this.setState({modulesLoading: true});
    var url = devconfig.admin + "/course/modules";
    var data = JSON.stringify({
      "id" : Number(this.state.id)
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({modules: response.data, modulesLoading: false });
      }
      else{
        this.setState({ modules: {}, modulesLoading: false});
      }
      // this.getUsers();
    }.bind(this))
  }

  getUsers(){

  }


  getReviews(){
    var url = devconfig.admin + "/course/reviews";
    var data = JSON.stringify({
      "course_id" : parseInt(this.state.id),
      "page_num": this.state.reviewPageNum,
      "filter_type" : this.state.reviewFilter
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({reviewsCount: response.data});

        {/*Find lazy load or not*/}
        if(this.state.reviews.length && this.state.reviewsLazyLoadStatus){
          var tempCardArry = this.state.reviews.concat(response.data.reviews);
          this.setState({reviews: tempCardArry});
        }
        else{
          this.setState({reviews: response.data.reviews});
        }

        {/*Find lazy load ends*/}
        if(response.data.reviews.length <= 19){
          this.setState({reviewsLazyDataEnd: true, reviewsLazyLoadStatus: false, reviewsLoading: false});
        }
        else{
          this.setState({reviewsLazyDataEnd: false, reviewsLazyLoadStatus: false, reviewsLoading: false});
        }

      }
      else{
        this.setState({ reviews: {}, reviewsLoading: false});
      }
    }.bind(this))
  }


  moreClick(tempId){
    this.setState({moreOpen: tempId});
  }

  moreClose(){
    this.setState({moreOpen: 0});
  }

  muteClick(tempId, tempStatus){
    this.setState({ muteLoading: true});

    var url = devconfig.admin+"/creator/mute-or-unmute";
    var data = JSON.stringify({
      "creator_id" : tempId,
      "mute" : tempStatus
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.clearFilter();
        this.getData(1, null, 1);
        this.setState({moreOpen: 0, muteLoading: false});
      }
      else{
        this.setState({ errorMessage: response.message, muteLoading: false});
      }
    }.bind(this))
  }

  loadMoreClick(){
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    this.getData(this.state.pageNum + 1, this.state.filter, this.state.sort);
  }

  async tabItemClick(item){
    this.setState({ tabItemActive: item });

    if(item===1){
      this.getModules();
    }
    if(item===2){
      await this.getDecription(this.state.id);
    }
    if(item===3){
      await this.getStudents();
      await this.getGraph(1);
    }
    if(item===4){
      this.getReviews();
    }

  }

  async studentLoadMoreClick(){
    await this.setState({ studentsPagenum: this.state.studentsPagenum + 1, studentLazyLoadStatus: true });
    this.getStudents();
  }

  getStudents(){
    var url = devconfig.admin + "/course/students";
    var data = JSON.stringify({
      "course_id" : parseInt(this.state.id),
      "page_num" : this.state.studentsPagenum,
      "filter_type" : this.state.studentFilter
    })

    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Find lazy load or not*/}
        if(this.state.students.length && this.state.studentLazyLoadStatus){
          var tempCardArry = this.state.students.concat(response.data);
          this.setState({students: tempCardArry});
        }
        else{
          this.setState({students: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({studentLazyDataEnd: true, studentLazyLoadStatus: false, studentsLoading: false});
        }
        else{
          this.setState({studentLazyDataEnd: false, studentLazyLoadStatus: false, studentsLoading: false});
        }
      }
      else{
        this.setState({students: {}, studentsLoading: false});
      }
    }.bind(this))
  }

  getGraph(tempVal){
    this.setState({graphLoading: true, graphData: {}});
    var url = devconfig.admin + "/course/students-graph";
    var data = JSON.stringify({
      "course_id" : parseInt(this.state.id),
      "date_filter" : tempVal
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({graphData: response.data.graph_data, graphLoading: false, student: {total_view: response.data.view_count, total_enrolled: response.data.enroll_count }});
      }
      else{
        this.setState({graphData: {}, graphLoading: false});
      }
    }.bind(this))
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.getGraph(sort.value);
  }

  filterStudentChange = async (filter) => {
    await this.setState({studentFilter: filter.value});
    this.getStudents();
  }

  async saveCourseDescription(){
    var text = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

    this.setState({ descriptionUpdating: true });
    var url = devconfig.admin + "/course/update-description";
    var data = {
      id: parseInt(this.state.id),
      description: this.state.description
    };
    APIPost(url, JSON.stringify(data)).then((response)=>{
      this.setState({ descriptionUpdating: false, wysiwygChange: false});
    })
  }

  async getDecription(id){
    this.setState({dataDescpLoading: true});
    var url = devconfig.admin + "/course/get-description";
    var data = JSON.stringify({
      "id" : Number(id)
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        var description = response.data.description;
        if(description){
          const html = '';
          const contentBlock = htmlToDraft(description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
            const inputEditorState = EditorState.createWithContent(contentState);
            this.setState({ inputEditorState: inputEditorState, dataDescpLoading: false});
          }
        }
        else{
          this.setState({ dataDescpLoading: false});
        }
      }
      else{
        this.setState({dataDescpLoading: false});
      }
      this.getModules();
    }.bind(this))
  }

  async publishToggle(published){
    var url = devconfig.admin + "/course/publish";
    var data = {
      id: this.state.id,
      publish: !published
    };
    APIPost(url, JSON.stringify(data)).then((response)=>{
      if(response.status){
        this.getData(this.state.id)
        this.setState({ toast: true, message: response.message, type: 'success'})
      }else{
        this.setState({ toast: true, message: response.message, type: 'fail'})
      }
    })
    setTimeout(()=>{ this.setState({ toast: false}) },4000)
  }

  async featuredToggle(featured){
    var url = devconfig.admin + "/course/update-featured";
    var data = {
      id: this.state.id,
      featured: !featured
    };
    APIPost(url, JSON.stringify(data)).then((response)=>{
      if(response.status){
        this.getData(this.state.id)
      }else{

      }
    })
  }

  onEditorStateChange: Function = (inputEditorState) => {
    this.setState({
      inputEditorState,
      description: draftToHtml(convertToRaw(inputEditorState.getCurrentContent())),
      wysiwygChange: true
    });
  };

  closeModal(){
    this.getData(this.state.id);
  }

  deleteCourse=()=>{
    var url = devconfig.admin+"/course/delete";
    var data = JSON.stringify({
      "id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        window.location="/courses";
      }
      else{
        // this.setState({ errorMessage: response.message, muteLoading: false, moreOpen: 0});
      }
    }.bind(this))
  }


  render() {
    const { editorState} = this.state;
    const filterByType = [
      { value: null, label: 'All' },
      { value: 1, label: 'Enrolled' },
      { value: 2, label: 'Viewed only' },
    ]
    const filterByRate = [
      { value: null, label: 'All reviews' },
      { value: 1, label: '1 star reviews' },
      { value: 2, label: '2 star reviews' },
      { value: 3, label: '3 star reviews' },
      { value: 4, label: '4 star reviews' },
      { value: 5, label: '5 star reviews' },
    ]
    const sortBy = [
      { value: 1, label: 'Last 7 days' },
      { value: 2, label: 'Last 30 days' },
      { value: 3, label: 'Last 60 days' },
    ]
    const filterByTypeStatus = [
      { value: null, label: 'All' },
      { value: 1, label: 'Published' },
      { value: 2, label: 'Unpublished' },
    ]
    if(this.state.dataLoading){
      return(
        <div className="col-12 text-center">
          <LazyLoaderGif />
        </div>
      )
    }
    var data = this.state.data;
    return (
      <div className="e-main-content e-creators-wrap">
        <div className="e-video-detail-wrap">
          <div className="row">
            <div className="col-lg-3">
              <img className="e-video-detail-cover" src={data.image} alt="Course cover"/>
            </div>
            <div className="col-lg-9 e-video-summary">
              <button className="e-s-btn e-edit-btn" data-toggle="modal" data-target="#editCourseModal">Edit</button>
              <button className="e-delete" data-toggle="modal" data-target="#e-confirmModal"><img src={Delete} className="e-delete-img"/></button>
              <div>
                <h4>{data.name}</h4>
              </div>
              <div>
                {
                  data.categories && data.categories.length?
                    data.categories.map((item, index) => {
                      return(
                        <span className="e-category-tag">{item.category}</span>
                      )
                    }) 
                  :
                    null
                }
              </div>
              <div className="row mt-4">
                <div className="col-lg-2 e-course-figures">
                  <span className="text-dull">Total Viewers: </span>
                  <p>{data.view_count}</p>
                </div>
                <div className="col-lg-2 e-course-figures">
                  <span className="text-dull">Enrolled: </span>
                  <p>{data.enrolled_count}</p>
                </div>
                <div className="col-lg-2 e-course-figures">
                  <span className="text-dull">Inital Price: </span>
                  <p>₹{data.price}</p>
                </div>
                <div className="col-lg-2 e-course-figures">
                  <span className="text-dull">Offer Price: </span>
                  <p><b>₹{data.offer_price}</b></p>
                </div>
                <div className="col-lg-4 e-course-figures e-course-creadted-by">
                  <span className="text-dull">Created by: </span>
                  <p>{data.creator} - <b>{data.creator_code}</b></p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <button className="e-p-btn mr-4" data-toggle="modal" data-target="#addPromoModal" onClick={()=>this.publishToggle(data.published)}>
                    {data.published ? "Unpublish" : "Publish"}
                  </button>
                  <span className="hand" onClick={()=>this.featuredToggle(data.featured)}>
                    <strong>
                      {data.featured ? "Remove featured" : "Mark as featured"}
                    </strong>
                  </span>
                </div>
                <div className="col-lg-6 mt-4 pt-2 text-right">
                  <span className="text-dull mr-3">Last Published: {data.last_published} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="e-tab-head-wrap mt-5">
          {
            this.state.tabItemActive === 1 ?
              <span className="e-active" onClick={this.tabItemClick.bind(this, 1)}>Course Modules</span>
            :
              <span onClick={this.tabItemClick.bind(this, 1)}>Course Modules</span>
          }

          {
            this.state.tabItemActive === 2 ?
              <span className="e-active" onClick={this.tabItemClick.bind(this, 2)}>Course Details</span>
            :
              <span onClick={this.tabItemClick.bind(this, 2)}>Course Details</span>
          }

          {
            this.state.tabItemActive === 3 ?
              <span className="e-active" onClick={this.tabItemClick.bind(this, 3)}>Students</span>
            :
              <span onClick={this.tabItemClick.bind(this, 3)}>Students</span>
          }

          {
            this.state.tabItemActive === 4 ?
              <span className="e-active" onClick={this.tabItemClick.bind(this, 4)}>Reviews</span>
            :
              <span onClick={this.tabItemClick.bind(this, 4)}>Reviews</span>
          }
        </div>

        <div className="e-tab-content-wrap mt-3">
          {/* Course Modules */}

          {
            this.state.tabItemActive === 1 ?
              this.state.modulesLoading ?
                <div className="col-12 text-center">
                  <LazyLoaderGif />
                </div>
              :
                this.state.modules.length ?
                  <div className="e-modules-tab e-tab-content">
                    {
                      this.state.modules.map((module, index) => {
                        return(
                          <div className="e-module-item" key={"module-"+index}>
                            <div className="row mb-1">
                              <div className="col-lg-10">
                                <span className="text-dull">Module {index+1}:</span>
                                <h6>{module.name}</h6>
                              </div>
                              <div className="col-lg-2 text-right mt-3">
                                <button 
                                  className="e-s-btn e-edit-btn" 
                                  data-toggle="modal" 
                                  data-target="#editModuleModal" 
                                  onClick={()=>this.setState({moduleToEdit: module})}>
                                    Edit
                                  </button>
                              </div>
                            </div>
                            {
                              module.videos && module.videos.length?
                                module.videos.map((video, vIndex) => {
                                  return(
                                    <div className="row e-module-subject-wrap" key={"video-" + index + vIndex} data-toggle="modal" data-target={"#videoPlayerModal"+index+vIndex}>
                                      <div className="col-lg-8">
                                        <p className="e-title m-0">{vIndex+1}. {video.name}</p>
                                      </div>
                                      <div className="col-lg-2">
                                        <p className="e-time m-0">{video.duration_string}</p>
                                      </div>
                                      <div className="col-lg-1">
                                        <p className="e-type m-0">{video.free ? "Free" : "Paid"}</p>
                                      </div>
                                      <VideoPlayerModal id={"videoPlayerModal"+index+vIndex} data={video} closeModal={this.closeModal.bind(this)}/>
                                    </div>
                                  )
                                })
                              :
                                null
                            }
                          </div>
                        )
                      })
                    }
                          
                    {/*<div className="row mt-3 mb-3">
                      <div className="col-lg-12">
                        <div className="e-add-module" data-toggle="modal" data-target="#createModuleModal">+ Add New Module</div>
                      </div>
                    </div>*/}
                  </div>
                :
                  <NothingFound data={"No modules found."}/>
            :
              null
          }

          {
            this.state.tabItemActive === 2 ?
              <div className="e-modules-tab e-tab-content">
                {
                  this.state.dataDescpLoading ?
                    <div className="col-12 text-center">
                      <LazyLoaderGif />
                    </div>
                  :
                    <React.Fragment>
                      <p className="mt-3">What user will learn from course</p>
                      <Editor
                        editorState={this.state.inputEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                          inline: { inDropdown: false },
                          list: { inDropdown: true },
                        }}
                      />

                      <div className="mt-3 mb-3">
                        {
                          this.state.wysiwygChange ?
                            <React.Fragment>
                              {
                                this.state.descriptionUpdating ?
                                  <span className="e-course-descp-loader">
                                    <LazyLoaderGif/>
                                  </span>
                                :
                                  <button className="e-p-btn mr-4" onClick={()=>this.saveCourseDescription()}>Save</button>
                              }
                            </React.Fragment>
                            
                          :
                            <button className="e-p-btn mr-4 e-inactive">Save</button>
                        }
                        
                      </div>
                    </React.Fragment>
                }


              </div>
            :
              null
          }

          {
            this.state.tabItemActive === 3 ?
              <div className="e-students-tab e-tab-content">
                <div className="row mt-3">
                  <div className="col-lg-2">
                    <div className="e-filter-wrap">
                      <label>Sort by:</label>
                      <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Filter"/>
                    </div>
                  </div>
                  <div className="col-lg-2 offset-lg-6">
                    <div className="e-chart-summary-item">
                      <h5>Total Viewers: <b className="e-viewers">{this.state.student.total_view}</b></h5>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="e-chart-summary-item">
                      <h5>Total Enrolled: <b className="e-enrolled">{this.state.student.total_enrolled}</b></h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    {
                      !this.state.graphLoading ?
                        <LineChart data={this.state.graphData}/>
                      :
                        <div className="text-center my-5">
                          <LazyLoaderGif/>
                        </div>
                    }
                  </div>
                </div>


                <div className="row mb-4">
                  <div className="col-lg-2">
                    <div className="e-filter-wrap">
                      <label>Filter by type:</label>
                      <SelectBox defaultValue={this.state.filterTypeDefault} options={filterByType} selectchange={this.filterStudentChange} placeholder="Filter"/>
                    </div>
                  </div>
                </div>

                {
                  this.state.studentsLoading ?
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <div className="mt-5 text-center">
                          <LazyLoaderGif/>
                        </div>
                      </div>
                    </div>
                  :
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        {
                          this.state.students && this.state.students.length ?
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Student</th>
                                  <th>Type</th>
                                  <th>Enrolled date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.students.map((item, index) => {
                                    return(
                                      <tr className="">
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        {
                                          item.date && item.date.length ?
                                            <td>{item.date}</td>
                                          :
                                            <td>-</td>
                                        }
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          :
                            null
                        }
                        
                      </div>
                      {
                        !this.state.studentLazyDataEnd && !this.state.studentLazyLoadStatus ?
                          <div className="col-lg-12 text-center py-3">
                            <span className="e-load-more" onClick={this.studentLoadMoreClick.bind(this)}>Load More</span>
                          </div>
                        :
                          null
                      }

                      {
                        this.state.studentLazyLoadStatus ?
                          <div className="col-lg-12 text-center py-3">
                            <LazyLoaderGif/>
                          </div>
                        :
                          null
                      }
                    </div>
                }

                
              </div>
            :
              null
          }

          {
            this.state.tabItemActive === 4 ? 
              this.state.reviewsLoading ?
                <div className="col-12 text-center">
                  <LazyLoaderGif />
                </div>
              :
                this.state.reviews && this.state.reviews.length ?
                  <div className="e-modules-tab e-tab-content">
                    <div className="row mt-3">
                      <div className="col-lg-8">
                        <div className="e-review-wrap">
                          {/*<div className="row mb-4">
                            <div className="col-lg-3">
                              <div className="e-filter-wrap">
                                <label>Filter by Star Rating:</label>
                                <SelectBox defaultValue={this.state.filterRateDefault} options={filterByRate} selectchange={this.filterReviewChange} placeholder="Filter"/>
                              </div>
                            </div>
                          </div>*/}
                          {
                            this.state.reviews.map((item, index) => {
                              return(
                                <div className="media e-review-item position-relative pt-4" key={"module-"+index}>
                                  <img className="mr-3" src={item.image} alt="Generic placeholder image"/>
                                  <div className="media-body">
                                    <span className="e-remove hidden" data-toggle="modal" data-target="#removeReviewModal"></span>  
                                    <h5 className="mt-0">{item.user_name}<p className="e-rate-star">{item.rating}<b>&#8902;</b></p></h5>
                                    {
                                      item.review && item.review.length ?
                                        <p className="e-review-content">{item.review}</p>
                                      :
                                        <p className="e-review-content">--</p>
                                    }
                                    
                                  </div>
                                </div>
                              )
                            })
                          }

                          


                        </div>
                        {
                            !this.state.reviewsLazyDataEnd && !this.state.reviewsLazyLoadStatus ?
                              <div className="col-lg-12 text-center pt-4 pb-3">
                                <span className="e-load-more" onClick={this.reviewsLoadMoreClick.bind(this)}>Load More</span>
                              </div>
                            :
                              null
                          }

                          {
                            this.state.reviewsLazyLoadStatus ?
                              <div className="col-lg-12 text-center pb-3">
                                <LazyLoaderGif/>
                              </div>
                            :
                              null
                          }
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="e-rating-summary-item">
                              <h5>Overall Rating: <b className="e-rating">{this.state.reviewsCount.rating ? this.state.reviewsCount.rating : 0}</b></h5>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="e-rating-summary-item">
                              <h5>Total Reviews: <b className="e-enrolled">{this.state.reviewsCount.total_reviews ? this.state.reviewsCount.total_reviews : 0}</b></h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                :
                  <NothingFound data={"You haven't received any reviews yet."}/>
            :
              null
          }
          
        </div>

        {/* Modals */}
        {
          this.state.data ?
            <EditCourseModal data={this.state.data}/>
          : null  
        }
        {
          this.state.toast ?
            <Toast data={this.state.message} type={this.state.type}/>
          :
            null
        }

        <CreateModuleModal id={this.state.id}/>
        <EditModuleModal id={this.state.id} moduleData={this.state.moduleToEdit}/>
        <Confirmation modalTitle="Confirm Delete" modalContent="Are you sure want to delete this course" confirmSuccess={()=>{this.deleteCourse()}}/>
        <RemoveReview/>
      </div>
      
    );
  }
}

export default CourseDetail;
