import React, { Component } from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class Editor extends Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
        this.state = {
            editorLoaded: true,
            data: props.value || "",
            error: props.error || null,
        };
    }

    componentDidUpdate(prevProps) {
        const { value, error } = this.props;

        if (prevProps.value !== value) {
            this.setState({ data: value || "" });
        }

        if (prevProps.error !== error) {
            this.setState({ error });
        }
    }

    handleEditorChange = (event, editor) => {
        const tempData = editor.getData();
        this.props.editorChange(tempData);
    };

    render() {
        const { editorLoaded, data, error } = this.state;

        return (
            <div className={error ? "e-editor-error" : ""}>
                {editorLoaded && (
                    <CKEditor
                        activeClass="p10"
                        editor={ClassicEditor}
                        config={{
                            removePlugins: [
                                "EasyImage",
                                "ImageUpload",
                                "MediaEmbed",
                                "Table",
                                "BlockQuote",
                                "Indent",
                            ],
                        }}
                        onChange={this.handleEditorChange}
                        data={data}
                    />
                )}
                <p className="e-error-message pt-2 mb-0">{this.props.error}</p>
            </div>
        );
    }
}

Editor.propTypes = {
    value: PropTypes.string,
    error: PropTypes.string,
    editorChange: PropTypes.func.isRequired,
};

export default Editor;
