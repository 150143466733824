/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import RemoveBannerConfirmation from '../../components/modals/banner/removeBanner.js';
import HeroBannerCard from '../../components/card/bannerCard.js';
import Banner1 from '../../assets/images/cards/banner1.png';
import Banner2 from '../../assets/images/cards/banner2.png';
import Banner3 from '../../assets/images/cards/banner3.png';
import Banner4 from '../../assets/images/cards/popup.png';
import Delete from '../../assets/images/cards/delete.svg';


/*Assets*/

class BannerListing extends Component {
  constructor(props) {
    super(props);
    this.state = {

      tabIndex: 1
    }
  }

  componentDidMount() {
  }

  tabChange = (e) => {
    this.setState({ tabIndex: e });
  }

  render() {

    const demoArray = [
      {
        name: 'Banner 1',
        img: Banner1,
      },
      {
        name: 'Banner 2',
        img: Banner2,
      },
      {
        name: 'Banner 3',
        img: Banner3,
      },

    ]

    const popUpArray = [
      {
        name: 'Banner 1',
        img: Banner4,
      },
      {
        name: 'Banner 2',
        img: Banner4,
      },
      {
        name: 'Banner 3',
        img: Banner4,
      },

    ]

    const renderButton = ()=>{
      switch(this.state. tabIndex) {
        case 1:{
          return  <button type="submit" value="Submit" className='e-add-webinar-btn'>Add new banner</button>
        }
        case 2:{
          return  <button type="submit" value="Submit" className='e-add-webinar-btn'>Add new banner</button>
        }
      }
    }



    return (
      <div className="e-main-content e-invites-wrap">

        <div className="row">
          <div className="col-lg-9 mb-4 e-Product-tab-link-wrap">

            {
              this.state.tabIndex === 1 ?
                <span className="e-tab-item-title e-active ">Hero banners</span>
                :
                <span onClick={() => { this.tabChange(1) }} className="e-tab-item-title">Hero banners</span>
            }

            {
              this.state.tabIndex === 2 ?
                <span className="e-tab-item-title e-active ">Pop up banners</span>
                :
                <span onClick={() => { this.tabChange(2) }} className="e-tab-item-title">Pop up banners</span>
            }
          </div>
          {/* <div className='col-lg-1 text-right'>
            <p className='d-flex'> <img src={Delete} alt=""/>Delete</p>
          </div> */}
          <div className="col-lg-3">
            <div className="d-flex justify-content-end">
            <span className='mr-5 mb-0 pt-2 e-red'  data-toggle="modal" data-target="#removeBannerModal"  > <img src={Delete} alt="" className='mr-2'/>Delete</span>
              {renderButton()}
            </div>
          </div>
        </div>

        <React.Fragment>
          {
            this.state.tabIndex === 1 ?
              <div className='row mt-2'>
                {
                  demoArray.map((item, key) => {
                    return (
                      <div className='col-lg-3 mb-4 pb-2'>
                        <HeroBannerCard type={1} data={item}/>
                      </div>
                    )
                  })
                }

              </div>
              :
              null

          }
        </React.Fragment>

        <React.Fragment>
          {
            this.state.tabIndex === 2 ?
            <div className='row mt-2'>
            {
              popUpArray.map((item, key) => {
                return (
                  <div className='col-lg-3 mb-4 pb-2'>
                    <HeroBannerCard data={item}/>
                  </div>
                )
              })
            }

          </div>
              :
              null

          }
          <RemoveBannerConfirmation content="Are you sure to delete this hero  banner?"/>
        </React.Fragment>

      </div>
    );
  }
}

export default BannerListing;
