/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import SelectBox from '../../components/common/selectBox';
import Input from '../../components/formComponents/input';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';

/*Assets*/

class AddCreator extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      link: "",
      code: "",
      apiLoading: false,
      sortDefault: { value: 3, label: 'Both webinar & course tutor' },
      type: 1
    }
  }

  componentDidMount(){

    // var data = this.props.data;

    console.log("Modal data");
    console.log(this.props.data);

    // $('#'+this.props.id).on('shown.bs.modal', () => {
    //   alert("focused")
    //   var data = this.props.data;
    //   if(data){
    //     console.log("data");
    //     console.log(data)
    //     this.setState({
    //       mobile: data.mobile,
    //       name: data.name,
    //       code: data.code,
    //       id: data.id,
    //       designation: data.designation
    //     })
    //   }
    // })
    var data = this.props.data;
      if(data){
        console.log("data");
        console.log(data)
        this.setState({
          phone: data.mobile,
          name: data.name,
          email: data.email,
          code: data.code,
          id: data.id,
          designation: data.designation
        })
      }
  }
  
  async buttonClick(){
    this.setState({apiLoading: true});
    var validationSuccess = true;

    await PhoneValidation(this.state.phone).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid phone number, please check and retry.", apiLoading: false});
        validationSuccess = false;
      }
    }.bind(this))

    await EmailValidation(this.state.email).then(function(response){
      if(!response){
        this.setState({errorMessage: "Invalid email address, please check and retry.", apiLoading: false});
        validationSuccess = false;
      }
    }.bind(this))

    var tempGmailSplit = this.state.email.split("@");

    if(this.state.type !=2 && tempGmailSplit[tempGmailSplit.length-1].indexOf("gmail.com") === -1){
      this.setState({errorMessage: "Invalid Gmail Address", apiLoading: false});
      validationSuccess = false;
    }

    if(this.state.type !=2 && this.state.link.indexOf("youtube.com/channel") === -1 ){
      this.setState({errorMessage: "Invalid Youtube URL", apiLoading: false});
      validationSuccess = false;
    }

    if(validationSuccess){
      var url = devconfig.admin+"/creator/invite";
      var data = JSON.stringify({
        "name" : this.state.name,
        "email" : this.state.email,
        "channel_link" : this.state.link,
        "mobile" : this.state.phone,
        "code" : this.state.code.toUpperCase(),
        "type" : this.state.type
      })
      APIPost(url, data).then(function(response){
        if(response.status){
            this.clearState();
          $('#'+this.props.id).modal('hide');
          
          this.setState({apiLoading: false});
          this.props.success();
        }
        else{
          this.setState({errorMessage: response.message, apiLoading: false});
        }
      }.bind(this))
    }
    
  }

  typeChange = async (type) => {
    await this.setState({type: type.value});
  }

  render() {
    const tutorType = [
      { value: 3, label: 'Both webinar & course tutor' },
      { value: 1, label: 'Webinar tutor only' },
      { value: 2, label: 'Course tutor only' },
    ]
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="addCreatorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Invite New Tutor</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mt-3">
              {/*<Input label="Name" id="u-name" type="text" defaultValue={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />*/}
              <Input label="Name" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />

              {/*<Input label="Gmail Address" id="u-email" type="text" defaultValue={this.state.email} valueChange={(tempVal) => this.setState({email: tempVal})} />*/}
              <Input label="Gmail Address" id="u-email" type="text" value={this.state.email} valueChange={(tempVal) => this.setState({email: tempVal})} />
              <Input label="Phone Number" id="u-phone" type="number" value={this.state.phone} valueChange={(tempVal) => this.setState({phone: tempVal})} />
              
              <Input label="Product Code" id="u-code" type="text" value={this.state.code} valueChange={(tempVal) => this.setState({code: tempVal})} />
              <div className="e-filter-wrap mb-4 e-form-select">
                <label>Tutor type</label>
                <SelectBox defaultValue={this.state.sortDefault} options={tutorType} selectchange={this.typeChange} placeholder="Tutor type"/>
              </div>
              {
                this.state.type != 2 ? 
                  <Input label="Youtube Channel Link" id="u-link" type="text" value={this.state.link} valueChange={(tempVal) => this.setState({link: tempVal})} />
                :
                  null
              }
              
            
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        (this.state.name && this.state.name.length) && 
                        (this.state.email && this.state.email.length) && 
                        (this.state.phone && this.state.phone.length) && 
                        (this.state.code && this.state.code.length) && 
                        ((this.state.link && this.state.link.length) || this.state.type == 2 )&& !this.state.apiLoading ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Verify & Invite</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit">Verify & Invite</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default AddCreator;
