/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIGet from '../../services/apiCall/get';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';

import AddLanguage from '../../components/modals/language/addLanguage';
import EditLanguage from '../../components/modals/language/editLanguage';
import RemoveLanguage from '../../components/modals/language/removeLanguage';

import AddCategory from '../../components/modals/category/addCategory';
import EditCategory from '../../components/modals/category/editCategory';
import RemoveCategory from '../../components/modals/category/removeCategory';

import AddSegment from '../../components/modals/segment/addSegment';
import EditSegment from '../../components/modals/segment/editSegment';
import RemoveSegment from '../../components/modals/segment/removeSegment';

import AddDiscount from '../../components/modals/discount/addDiscount';
import EditDiscount from '../../components/modals/discount/editDiscount';
import RemoveDiscount from '../../components/modals/discount/removeDiscount';
/*Assets*/

class Settings extends Component {
  constructor(props){
    super(props);
    this.state = {
      languages: [],
      categories: [],
      segments: [],
      courseLanguages: [],
      courseCategories: [],
      discounts: [],
      editItem: null,
      removeItem: null,
      removeCatItem: null,
      removeSegItem: null,
      editCatItem: null,
      editSegItem: null,
      dataCatLoading: false,
      dataLangLoading: false,
      resetCat: true,
      resetLang: true,
      resetDiscount: true,
      catErrorMessage: "",
      tabActive: 1
    }
  }

  componentDidMount() {
    this.languageApi();
    this.categoryApi();
    this.segmentsApi();
    this.courseLanguageApi();
    this.courseCategoryApi();
    this.courseDiscountsApi();
  }

  languageApi(){
    this.setState({ dataLangLoading: true, resetLang: false});
    var url = devconfig.admin+"/language/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ languages: response.data, dataLangLoading: false, resetLang: true});
      }
      else{
        this.setState({ languages: [], dataLangLoading: false, resetLang: true});
      }
    }.bind(this))
  }

  categoryApi(){
    this.setState({ dataCatLoading: true, resetCat: false});
    var url = devconfig.admin+"/category/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ categories: response.data, dataCatLoading: false, resetCat: true});
      }
      else{
        this.setState({ categories: [], dataCatLoading: false, resetCat: true});
      }
    }.bind(this))
  }

  segmentsApi(){
    this.setState({ dataSegLoading: true, resetSeg: false});
    var url = devconfig.admin+"/segment/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ segments: response.data, dataSegLoading: false, resetSeg: true});
      }
      else{
        this.setState({ segments: [], dataSegLoading: false, resetSeg: true});
      }
    }.bind(this))
  }

  courseLanguageApi(){
    this.setState({ dataLangLoading: true, resetLang: false});
    var url = devconfig.admin+"/course-language/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ courseLanguages: response.data, dataLangLoading: false, resetLang: true});
      }
      else{
        this.setState({ courseLanguages: [], dataLangLoading: false, resetLang: true});
      }
    }.bind(this))
  }

  courseCategoryApi(){
    this.setState({ dataCatLoading: true, resetCat: false});
    var url = devconfig.admin+"/course-category/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ courseCategories: response.data, dataCatLoading: false, resetCat: true});
      }
      else{
        this.setState({ courseCategories: [], dataCatLoading: false, resetCat: true});
      }
    }.bind(this))
  }

  courseDiscountsApi(){
    this.setState({ dataDiscLoading: true, resetCat: false});
    var url = devconfig.admin+"/course-discount/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ discounts: response.data, dataDiscLoading: false, resetCat: true});
      }
      else{
        this.setState({ discounts: [], dataDiscLoading: false, resetCat: true});
      }
    }.bind(this))
  }
  
  addLangClick(tempLang){
    // var url = devconfig.admin+"/language/add";

    if(this.state.tabActive==1){ 
      var url = devconfig.admin+"/language/add"; 
    }else{ 
      var url = devconfig.admin+"/course-language/add"; 
    }
    var data = JSON.stringify({
      "language" : tempLang
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#addLangModal').modal('hide');
        
        if(this.state.tabActive==1){
          this.languageApi();
        }
        else{
          this.courseLanguageApi();
        }
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }


  editLangClick(tempData, label){
    this.setState({editItem: tempData, editLabel: label});
  }

  editDiscountClick(tempData, label){
    this.setState({editDiscountItem: tempData, editLabel: label});
  }

  editClose(){
    this.setState({editItem: null});
  }

  editLangSuccess(tempLang){
    // var url = devconfig.admin+"/language/edit";
    if(this.state.tabActive==1){ 
      var url = devconfig.admin+"/language/edit"; 
    }else{ 
      var url = devconfig.admin+"/course-language/edit"; 
    }
    var data = JSON.stringify({
      "language" : tempLang,
      "language_id" : this.state.editItem.id,
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#editLangModal').modal('hide');
        if(this.state.tabActive==1){ 
          this.languageApi();
        }else{ 
          this.courseLanguageApi();
        }
        this.editClose();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  removeLangClick(tempData){
    this.setState({removeItem: tempData});
  }

  removeLangApi(){
    this.languageApi();
    this.setState({removeItem: null});
  }

  removeDiscountApi(){
    this.courseDiscountsApi();
    this.setState({removeDiscounttItem: null});
  }

  addCatClick(tempCat){
    // var url = devconfig.admin+"/category/add";
    if(this.state.tabActive==1){ 
        var url = devconfig.admin+"/category/add"; 
      }else{ 
        var url = devconfig.admin+"/course-category/add"; 
      }
    var data = JSON.stringify({
      "category" : tempCat
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#addCatModal').modal('hide');
        this.categoryApi();

        if(this.state.tabActive==1){
          this.categoryApi();
        }
        else{
          this.courseCategoryApi();
        }
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  addSegClick(tempSeg){
    // var url = devconfig.admin+"/category/add";
    if(this.state.tabActive==1){ 
        var url = devconfig.admin+"/segment/add"; 
      }else{ 
        var url = devconfig.admin+"/course-category/add"; 
      }
    var data = JSON.stringify({
      "name" : tempSeg
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#addSegModal').modal('hide');
        this.segmentsApi();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  addDiscountClick(tempCat){
    var url = devconfig.admin+"/course-discount/add";
    var data = JSON.stringify({
      "discount" : tempCat
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#addDiscountModal').modal('hide');
        this.courseDiscountsApi();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  removeCatClick(tempData){
    this.setState({removeCatItem: tempData});
  }

  removeSegClick(tempData){
    this.setState({removeSegItem: tempData});
  }

  removeDiscountClick(tempData){
    this.setState({removeDiscounttItem: tempData});
  }

  removeCatApi(){
    this.categoryApi();
    this.setState({removeCatItem: null});
  }

  removeSegApi(){
    this.segmentsApi();
    this.setState({removeSegItem: null});
  }

  editCatClick(tempData){
    this.setState({editCatItem: tempData});
  }

  editSegClick(tempData){
    this.setState({editSegItem: tempData});
  }

  editCatClose(){
    this.setState({editCatItem: null});
  }

  editDiscountClose(){
    this.setState({editDiscountItem: null});
  }

  editCatSuccess(tempCat){
    if(this.state.tabActive==1){ 
      var url = devconfig.admin+"/category/edit"; 
    }else{ 
      var url = devconfig.admin+"/course-category/edit"; 
    }
    
    var data = JSON.stringify({
      "category_id" : this.state.editCatItem.id,
      "category" : tempCat
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#editCatModal').modal('hide');
        if(this.state.tabActive==1){ 
          this.categoryApi();
        }else{ 
          this.courseCategoryApi();
        }
        
        this.editCatClose();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  editSegSuccess(tempSeg){
    if(this.state.tabActive==1){ 
      var url = devconfig.admin+"/segment/edit"; 
    }else{ 
      var url = devconfig.admin+"/course-category/edit"; 
    }
    
    var data = JSON.stringify({
      "id" : this.state.editSegItem.id,
      "name" : tempSeg
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#editSegModal').modal('hide');
        if(this.state.tabActive==1){ 
          this.segmentsApi();
        }
        this.editSegClose();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  editSegClose(){
    this.setState({editSegItem: null});
  }

  editDiscountSuccess(tempDiscount){
    var url = devconfig.admin+"/course-discount/edit";
    var data = JSON.stringify({
      "discount_id" : this.state.editDiscountItem.id,
      "discount" : tempDiscount
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#editDiscountModal').modal('hide');
        this.courseDiscountsApi();
        this.editDiscountClose();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  tabItemClick(tempActive){
    this.setState({tabActive: tempActive});
  }

  render() {
    
    return (
      <div className="e-main-content e-invites-wrap">
        <h2>App Settings</h2>
        

        <div className="row mb-4">
          <div className="col-lg-12">
            {
              this.state.tabActive === 1 ?
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 1)}>Webinar settings</span>
                  {/* <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>Course settings</span> */}
                </div>
              :
                null
            }

            {
              this.state.tabActive === 2 ?
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Webinar settings</span>
                  {/* <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 2)}>Course settings</span> */}
                </div>
              :
                null
            }
          </div>
        </div>

        {
          this.state.tabActive === 1 ? 
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <h2>Language</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addLangModal">Add New Language</button>
                </div>
              </div>

              {
                this.state.dataLangLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.languages.length !== 0 ?
                        this.state.languages.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1 e-item-txt">{data.language}</span>

                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editLangClick.bind(this, data, "Language")}></span>
                                  <span className="e-s-link-txt e-remove" data-toggle="modal" data-target="#removeLangModal" onClick={this.removeLangClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }

              <div className="row mt-5">
                <div className="col-lg-6">
                  <h2>Categories</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addCatModal">Add New Category</button>
                </div>
              </div>

              {
                this.state.dataCatLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.categories.length !== 0 ?
                        this.state.categories.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1">{data.category}</span>
                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editCatClick.bind(this, data)}></span>
                                  <span className="e-s-link-txt e-remove" onClick={this.removeCatClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                <span className="mr-3 e-p-link-txt" onClick={this.editCatClick.bind(this, data)}>Edit</span>
                                <span className="e-s-link-txt" onClick={this.removeCatClick.bind(this, data)}>Remove</span>
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }




              <div className="row mt-5">
                <div className="col-lg-6">
                  <h2>Segments</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addSegModal">Add New Segment</button>
                </div>
              </div>

              {
                this.state.dataSegLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.segments.length !== 0 ?
                        this.state.segments.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1 e-item-txt">{data.name}</span>

                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editSegClick.bind(this, data, "Segment")}></span>
                                  <span className="e-s-link-txt e-remove" data-toggle="modal" data-target="#removeSegModal" onClick={this.removeSegClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }
            </div>
          :
            null
        }

        {
          this.state.tabActive === 2 ? 
            <div>
              <div className="row">
                <div className="col-lg-6">
                  <h2>Language</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addLangModal">Add New Language</button>
                </div>
              </div>

              {
                this.state.dataLangLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.courseLanguages.length !== 0 ?
                        this.state.courseLanguages.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1 e-item-txt">{data.language}</span>

                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editLangClick.bind(this, data, "Language")}></span>
                                  <span className="e-s-link-txt e-remove" data-toggle="modal" data-target="#removeLangModal" onClick={this.removeLangClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }

              <div className="row mt-5">
                <div className="col-lg-6">
                  <h2>Categories</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addCatModal">Add New Category</button>
                </div>
              </div>

              {
                this.state.dataCatLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.courseCategories.length !== 0 ?
                        this.state.courseCategories.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1">{data.category}</span>
                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editCatClick.bind(this, data)}></span>
                                  <span className="e-s-link-txt e-remove" onClick={this.removeCatClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                <span className="mr-3 e-p-link-txt" onClick={this.editCatClick.bind(this, data)}>Edit</span>
                                <span className="e-s-link-txt" onClick={this.removeCatClick.bind(this, data)}>Remove</span>
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }

              <div className="row mt-5">
                <div className="col-lg-6">
                  <h2>Discounts in %</h2>
                </div>
                <div className="col-lg-6 text-right">
                  <button type="submit" value="Submit" data-toggle="modal" data-target="#addDiscountModal">Add New Discount</button>
                </div>
              </div>

              {
                this.state.dataDiscLoading ?
                  <div className="row">
                    <div className="col-lg-12 text-center py-3">
                      <LazyLoaderGif/>
                    </div>
                  </div>
                :
                  <div className="row mt-4">
                    { 
                      this.state.discounts.length !== 0 ?
                        this.state.discounts.map( (data, index) => {
                          return(
                            <div className="col-lg-3 mb-4 e-lang-item-wrap" key={index}>
                              <div className="e-lang-item">
                                <span className="e-p-ellipsis-1 e-item-txt">{data.discount}%</span>

                                <span className="e-btn-wrap">
                                  <span className="mr-3 e-p-link-txt e-edit" onClick={this.editDiscountClick.bind(this, data, "Discount")}></span>
                                  <span className="e-s-link-txt e-remove" data-toggle="modal" data-target="#removeDiscountModal" onClick={this.removeDiscountClick.bind(this, data)}></span>
                                </span>
                              </div>
                              {/*<div className="e-lang-btn-wrap">
                                
                              </div>*/}
                            </div>
                          );
                        })
                      :
                        null
                    }
                  </div>
              }

            </div>
          :
            null
        }
        
        

        {/*Modal*/}
        
        {
          this.state.resetLang ?
            <AddLanguage addSuccess={this.addLangClick.bind(this)}/>
          :
            null
        }
        {
          this.state.editItem ?
            <EditLanguage data={this.state.editItem} label={this.state.editLabel} editSuccess={this.editLangSuccess.bind(this)} editClose={this.editClose.bind(this)}/>
          :
            null
        }
        {
          this.state.removeItem ?
            <RemoveLanguage success={this.removeLangApi.bind(this)} tabActive={this.state.tabActive} data={this.state.removeItem} close={this.removeLangApi.bind(this)}/>
          :
            null
        }
        
        {
          this.state.resetCat ?
            <AddCategory success={this.addCatClick.bind(this)}/>
          :
            null
        }
        {
          this.state.editCatItem ?
            <EditCategory data={this.state.editCatItem} editSuccess={this.editCatSuccess.bind(this)} editClose={this.editCatClose.bind(this)}/>
          :
            null
        }
        {
          this.state.removeCatItem ?
            <RemoveCategory data={this.state.removeCatItem} success={this.removeCatApi.bind(this)} tabActive={this.state.tabActive} close={this.removeCatApi.bind(this)}/>
          :
            null
        }


        {
          this.state.resetSeg ?
            <AddSegment success={this.addSegClick.bind(this)}/>
          :
            null
        }
        {
          this.state.editSegItem ?
            <EditSegment data={this.state.editSegItem} editSuccess={this.editSegSuccess.bind(this)} editClose={this.editSegClose.bind(this)}/>
          :
            null
        }
        {
          this.state.removeSegItem ?
            <RemoveSegment data={this.state.removeSegItem} success={this.removeSegApi.bind(this)} tabActive={this.state.tabActive} close={this.removeSegApi.bind(this)}/>
          :
            null
        }



        {
          this.state.resetDiscount ?
            <AddDiscount success={this.addDiscountClick.bind(this)}/>
          :
            null
        }
        {
          this.state.editDiscountItem ?
            <EditDiscount data={this.state.editDiscountItem} editSuccess={this.editDiscountSuccess.bind(this)} editClose={this.editDiscountClose.bind(this)}/>
          :
            null
        }
        {
          this.state.removeDiscounttItem ?
            <RemoveDiscount data={this.state.removeDiscounttItem} success={this.removeDiscountApi.bind(this)} close={this.removeDiscountApi.bind(this)}/>
          :
            null
        }
        
        
      </div>
    );
  }
}

export default Settings;
