/*Package importing*/
import React, { Component } from 'react';

import WebinarDetailCard from '../../components/card/webinarDetailCard';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get.js';
import devconfig from '../../config/config';
import LazyLoaderGif from '../../components/common/lazyloader';
import AddWebinar from '../../components/modals/webinar/manageWebinar';
/*Assets*/
import BackArrow from '../../assets/images/icons/back-arrow.svg';
import Edit from '../../assets/images/icons/edit.svg';

class WebinarDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            enrolledList: [],
            dataLoading: true,
            modalClass: false,
            categories: [],
            languages: [],
            segments: [],
            ZoomAccount: null,
            selectedCategory: [],
            selectedLanguage: -1,
            selectedSegment: -1,
            selectedActive: -1,
            selectedZoomAccount: -1,
            enableEdit: false
        }
    }

    componentDidMount() {
        // Call getUrlVars and handle it asynchronously
        this.getUrlVars().then((query) => {
            // Now that query is available, you can use it
            // Call your other functions with the query data
            this._getWebinarDetails(query.id);
            this._getEnrolledList(query.id);
        });
        // this._getZoomAccount();
    }

    getUrlVars() {
        return new Promise((resolve, reject) => {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            // Resolve the promise with the vars object
            resolve(vars);
        });
    }

    // API - Webinar details
    _getWebinarDetails(id) {
        var url = devconfig.admin + "/webinar/detail";
        var data = JSON.stringify({
            "webinar_id": Number(id)
        });

        // Use an arrow function to preserve the correct 'this' context
        APIPost(url, data).then(response => {
            if (response.status) {

                let category_ids = response.data.category_ids;
                let languages_ids = response.data.language_ids;
                const selected_categories = category_ids.map(({ id }) => id);
                const selected_languages = languages_ids.map(({ language_id }) => language_id);
                this.setState({
                    data: response.data,
                    selectedCategory: selected_categories,
                    selectedLanguage: selected_languages,
                    selectedSegment: response.data.segment_id,

                }, () => {
                    this._getCategory();
                });
            } else {
                // Handle the case where there's an error in the response
                this.state.data={}
            }
            this.setState({
                dataLoading: false
            });
        })
    }

    // API - Webinar details
    _getEnrolledList(id) {
        var url = devconfig.admin + "/webinar/list-enrollment";
        var data = JSON.stringify({
            "webinar_id": Number(id)
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    enrolledList: response.data,
                });


            }
            else {
                this.setState({
                    enrolledList: [],
                    lazyLoadStatus: false,
                });
            }
        }.bind(this))
    }

    openToast = () => {
        this.setState({ toast: "No data found for download", toastType: "fail" })
        setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
    }

    _downloadData = () => {
        var url = devconfig.admin + "/webinar/download-enrollments";
        var data = JSON.stringify({
            "webinar_id": this.state.data.id
        })

        APIPost(url, data).then(function (response) {
            if (response.status) {
                if (response.cdn_url) {
                    window.open(response.cdn_url);
                } else {
                    this.openToast();
                }
            }
        }.bind(this))
    }

    addWebinar = () => {
        this.setState({
            modalClass: true,
            modalClass: 'e-login-fly',
        });
    }

    addModalClose() {
        // alert('close modal')
        this.setState({
            modalClass: false
        });

        window.location.reload();
        // this.props.updateEditedData();

        // this.props.modalClose();
    }



    _processRadioGroupData(radio_data, key) {
        let precessed_data = []
        radio_data.map(function (item, index) {
            var item = { value: item.id, label: item[key] };
            precessed_data.push(item);
        });

        return precessed_data;
    }

    // API - Get all webinar categories
    _getCategory() {
        var url = devconfig.admin + "/category/get-all";
        APIGet(url).then(function (response) {
            console.log('API data :: ', response)
            if (response.status) {
                var precessed_categories = this._processRadioGroupData(response.data, 'category');
                console.log('precessed_categories :: ', precessed_categories)
                this.setState({
                    categories: precessed_categories,
                }, () => {
                    this._getLanguage();
                });
            }
        }.bind(this))
    }


    // API - Get all languages 
    _getLanguage() {
        var url = devconfig.admin + "/language/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {
                {/*Type Filter option*/ }
                var processed_languages = this._processRadioGroupData(response.data, 'language');
                this.setState({
                    languages: processed_languages,
                }, () => {
                    this._getSegment()
                });
            }
        }.bind(this))
    }

    // API - Get all segments 
    _getSegment() {
        var url = devconfig.admin + "/segment/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {

                {/*Type Filter option*/ }
                var processed_segments = this._processRadioGroupData(response.data, 'name');
                this.setState({
                    segments: processed_segments,
                   
                },()=> {
                    this._getZoomAccount()
                });
            }
        }.bind(this))
    }


     //get zoom account
     _getZoomAccount() {
        var url = devconfig.admin + "/webinar/get-zoom-accounts";
        APIGet(url).then(function (response) {
            if (response.status) {
                var processed_accounts = [];
                response.data.map(function (item, index) {
                    var item = {
                        value: item.id,
                        label: item.name
                    };
                    processed_accounts.push(item);
                });
                //selectedZoomAccount
           
                let selected_account = {}
                let zoom_account_index = processed_accounts.findIndex( (item) => item.value == this.state.data.account);
                console.log("zoom_account_index", zoom_account_index)

                if (zoom_account_index != -1) {
                    selected_account = processed_accounts[zoom_account_index];
                } else {
                    selected_account = processed_accounts[0];
                }         
                console.log("processed_accounts", processed_accounts);
                this.setState({
                    selectedZoomAccount: selected_account,
                    ZoomAccount: processed_accounts,
                    enableEdit: true
                });
            }
        }.bind(this));
    }


    render() {


        return (
            <div className="e-main-content e-creators-wrap e-webinar-wrap">
                <h2>Webinar</h2>

                <div className='row'>
                    <div className='col-lg-8'>
                        <h5>
                            <a href="./webinar">
                                <img src={BackArrow}
                                    alt="" className='e-back-arrow mr-3' />
                            </a>Webinar details
                        </h5>
                    </div>

                    {
                        this.state.enableEdit == true &&
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-end">
                                <img src={Edit} alt="edit" className='mr-2' />
                                <span className='mb-0 e-edit-btn'
                                    onClick={this.addWebinar.bind(this)}>
                                    Edit
                                </span>
                            </div>
                        </div>
                    }
                </div>

                {
                    this.state.dataLoading == true ?
                        <div className='col-12 text-center web-detailpage-loader'>
                            <LazyLoaderGif />
                        </div>
                        :
                        <>
                            <div className='row pt-2'>
                                <div className='col-lg-12'>
                                    <WebinarDetailCard detailData={this.state.data} />
                                </div>
                            </div>
                            {
                                this.state.enrolledList && this.state.enrolledList.length > 0 ?
                                    <div className='row pt-4 mt-2 mb-5'>
                                        <div className='col-lg-12'>
                                            <div className='row'>
                                                <div className='col-lg-9'>
                                                    <h5 className='mb-3'>Registered user list</h5>
                                                </div>
                                                <div className='col-lg-3 text-right'>
                                                    <div className="">
                                                        <a className="e-download-link" onClick={this._downloadData} target="_blank">Download CSV</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <table className="table e-registered-user-list-wrap">
                                                <thead className=''>
                                                    <tr>
                                                        <th>Name of the user</th>
                                                        <th>Date of registration</th>
                                                        <th>Email ID</th>
                                                        <th>Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.enrolledList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="e-creator-dull-txt">{item.name}</td>
                                                                <td className="e-creator-dull-txt">{item.created}</td>
                                                                <td className="e-creator-dull-txt">{item.email}</td>
                                                                <td className="e-creator-dull-txt">{item.mobile}</td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {/* edit webinar modal */}
                            {
                                this.state.enableEdit == true &&
                                <AddWebinar modalClass={this.state.modalClass}
                                    editData={this.state.data}
                                    segments={this.state.segments}
                                    languages={this.state.languages}
                                    categories={this.state.categories}
                                    selectedCat={this.state.selectedCategory}
                                    selectedLang={this.state.selectedLanguage}
                                    selectedSeg={this.state.selectedSegment}
                                    selectedActive={this.state.selectedActive}
                                    selectedZoomAccount= {this.state.selectedZoomAccount}
                                    zoomAccountCategory={this.state.ZoomAccount}
                                    // updateEditedData={this.updateEditedData}
                                    modalClose={this.addModalClose.bind(this)}
                                    success={(e) => { this.triggerToast(e) }} />
                            }
                        </>
                }
            </div >

        );
    }
}

export default WebinarDetail;
