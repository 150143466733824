/*Package importing*/
import React, { Component } from 'react';

/*Components*/

/*Assets*/

class UnMuteModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  unmuteClick(){
    this.props.success();
  }

  render() {
    return (
      <div className="modal fade" id="unmuteModal" tabIndex="-1" role="dialog" aria-labelledby="unmuteModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Un-Mute Creator</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to Un-Mute <b>‘{this.props.name}’</b> ?
              </p>
            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
              <span className="e-p-link-txt" onClick={this.unmuteClick.bind(this)}>Un-Mute Now</span>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default UnMuteModal;
