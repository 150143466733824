/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../../components/formComponents/input';
import APIPost from '../../../services/apiCall/post';
import devconfig from '../../../config/config.js';

/*Assets*/

class EditSegment extends Component {
  constructor(props){
    super(props);
    this.state = {
      segment: ""
    }
  }

  componentWillMount(){
    this.setState({ segment: this.props.data.name});

    setTimeout(function(){
      $('#editSegModal').modal('show');
    }.bind(this),100);
  }

  buttonClick(){
    this.props.editSuccess(this.state.segment);
  }
  editCloseClick(){
    this.props.editClose();
  }

  render() {
    return (
      <div className="modal fade" id="editSegModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="editSegModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Segment</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.editCloseClick.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="e-modal-s-txt">Rename the Segment.</div>
            <div className="modal-body mt-3">
              <Input label={this.props.label} id="u-lang" type="text" value={this.state.segment} valueChange={(tempVal) => this.setState({segment: tempVal})} />
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal" onClick={this.editCloseClick.bind(this)}>Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        this.state.segment.length ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit" >Save</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditSegment;
