/*Package importing*/
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';

/*Components*/
import SearchInput from '../../components/common/search.js';
import SelectBox from '../../components/common/selectBox';
import WebinarCard from '../../components/card/webinarCard';
import AddWebinar from '../../components/modals/webinar/manageWebinar.js';
import APIGet from '../../services/apiCall/get.js';
import devconfig from '../../config/config.js';

/*Assets*/
import ListView from '../../assets/images/list.svg';
import ColumnView from '../../assets/images/column.svg';
import ListActive from '../../assets/images/list-active.svg';
import ColumnActive from '../../assets/images/column-active.svg';
import APIPost from '../../services/apiCall/post.js';
import LazyLoaderGif from '../../components/common/lazyloader.js';
import NothingFound from '../../components/common/nothingFound.js';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const FILTERBY = [
    { value: 1, label: 'Created' },
    { value: 2, label: 'Name' },
    { value: 3, label: 'Duration' },
];

const WEBINAR_FILTER_TYPE = [
    { value: 3, label: 'Upcoming' },
    { value: 2, label: 'Ongoing' },
    { value: 1, label: 'Completed' },
];

const ACTIVE = [
    { value: 1, label: 'Enable' },
    { value: 2, label: 'Disable' },
]

class AllWebinars extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialApiStatus: true,
            filter: FILTERBY[0],
            sort: 1,
            filterDefault: FILTERBY[0],
            sortDefault: { value: 1, label: 'Tutors name' },
            typeFilterDefault: WEBINAR_FILTER_TYPE[0],
            search: "",
            data: [],
            ZoomAccount: null,
            pageNum: 1,
            searchEnable: false,
            moreOpen: 0,
            muteLoading: false,
            lazyDataEnd: true,
            lazyLoadStatus: false,
            dataLoading: true,
            list: true,
            modalClass: false,
            categories: [],
            languages: [],
            segments: [],
            listDetails: [],
            searchKeyword: "",
            startDate: null,
            endDate: null,
            active: []
        }
    }

    componentDidMount() {
        this._getCategory(); // initial data API chain starts here.
        this._getZoomAccount();

        const selected_webinar_type = this.props.location?.state?.type;
        if (selected_webinar_type != undefined) {
            let selected_type = WEBINAR_FILTER_TYPE[0];
            let selected_type_index = WEBINAR_FILTER_TYPE.findIndex((item) => item.value == selected_webinar_type);
            if (selected_type_index != -1) {
                selected_type = WEBINAR_FILTER_TYPE[selected_type_index];
            }

            this.setState({
                typeFilterDefault: selected_type
            }, () => {
                this._getListDetails();

            });
        } else {
            this._getListDetails();
        }

    }

    filterChange = async (filter) => {
        await this.setState({
            filter: filter,
            dataLoading: true
        }, () => {
            this._getListDetails();
        });

    }

    _onUpdateWebinarType = (filter) => {
        this.setState({
            typeFilterDefault: filter,
            dataLoading: true
        }, () => {
            this._getListDetails();
        });
    }

    sortChange = async (sort) => {
        await this.setState({ sort: sort.value });
    }

    searchChange(e) {
        this.setState({
            search: e.target.value
        });

        if (e.target.value.length >= 3) {
            this.creatorsSearchApi(e.target.value);
            this.setState({
                searchEnable: true
            });

            this.clearFilter();
        }

        if (!e.target.value.length) {
            this.creatorsListApi(1, null, 1);
            this.setState({
                searchEnable: false,
            });
        }

    }

    clearFilter() {
        this.setState({
            sortDefault: { value: 1, label: 'Creator name' },
            filter: null,
            sort: 1,
        });
    }

    moreClick(tempId) {
        this.setState({ moreOpen: tempId });
    }

    moreClose() {
        this.setState({ moreOpen: 0 });
    }

    loadMoreClick() {
        this.creatorsListApi(this.state.pageNum + 1, this.state.filter, this.state.sort);
        this.setState({
            pageNum: this.state.pageNum + 1,
            lazyLoadStatus: true
        });
    }

    triggerToast = (tempMsg) => {
        if (tempMsg == "Success") {
            this.setState({
                toast: tempMsg,
                toastType: "success"
            });
        } else {
            this.setState({
                toast: tempMsg,
                toastType: "fail"
            });
        }

        setTimeout(function () {
            this.setState({ toast: null });
        }.bind(this), 4000);
    }

    addModalClose() {
        this.setState({
            modalClass: false
        });
        window.location.reload();
    }

    addWebinar = () => {
        this.setState({
            modalClass: 'e-login-fly'
        });
    }

    // Date range picker call back
    onDatesChange = async ({ startDate, endDate }) => {
        await this.setState({
            startDate,
            endDate,

        }, () => {
            // call API only if start date and end date is there in the picker 
            // or there are no data in start date and end date field
            if ((startDate != null && endDate != null) || (startDate == null && endDate == null)) {
                this.setState({
                    dataLoading: true,
                });
                this._getListDetails();
            }
        });
    }


    // API - Get all webinar categories
    _getCategory() {
        var url = devconfig.admin + "/category/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {

                {/*Type Filter option*/ }
                var precessed_categories = [];
                response.data.map(function (item, index) {
                    var item = { value: item.id, label: item.category };
                    precessed_categories.push(item);
                });
                console.log('Selected category :: ', precessed_categories[0])
                this.setState({
                    categories: precessed_categories
                }, () => {
                    this._getLanguage();
                });
            }
        }.bind(this))
    }


    // API - Get all languages 
    _getLanguage() {
        var url = devconfig.admin + "/language/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {

                {/*Type Filter option*/ }
                var processed_languages = [];
                response.data.map(function (item, index) {
                    var item = { value: item.id, label: item.language };
                    processed_languages.push(item);
                });

                this.setState({
                    languages: processed_languages
                }, () => {
                    this._getSegment()
                });
            }
        }.bind(this))
    }

    // API - Get all segments 
    _getSegment() {
        var url = devconfig.admin + "/segment/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {
                {/*Type Filter option*/ }
                var processed_segments = [];
                response.data.map(function (item, index) {
                    var item = { value: item.id, label: item.name };
                    processed_segments.push(item);
                });

                this.setState({
                    segments: processed_segments,
                    initialApiStatus: false
                });
            }
        }.bind(this))
    }

    // API - Get the Webinar list
    _getListDetails() {
        let formatted_date = null;
        let formatted_enddate = null;

        let started_date = this.state.startDate
        let end_date = this.state.endDate

        if (started_date, end_date) {
            const new_date = new Date(started_date);
            const new_enddate = new Date(end_date);
            formatted_date = new_date.toLocaleDateString('en-GB').split("/").join("-");
            formatted_enddate = new_enddate.toLocaleDateString('en-GB').split("/").join("-");
        }

        console.log('formatted_date', formatted_date)

        var url = devconfig.admin + "/webinar/all-webinar";
        var data = JSON.stringify({
            "sort_value": this.state.filter.value,
            "start_date": formatted_date,
            "end_date": formatted_enddate,
            "published": true,
            "type": this.state.typeFilterDefault.value
        });

        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    data: response.data,
                    dataLoading: false
                });
            }
            else {
                this.setState({
                    data: [],
                    lazyLoadStatus: false,
                    dataLoading: false
                });
            }
        }.bind(this));
    }

    //API - Webinar searching
    _postSearchData() {
        this.setState({
            dataLoading: true
        });

        var url = devconfig.admin + "/webinar/search";
        var data = JSON.stringify({
            "query": this.state.searchKeyword
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    data: response.data,
                    dataLoading: false
                });
            }
            else {
                this.setState({
                    data: [],
                    dataLoading: false
                });
            }
        }.bind(this));
    }

    _searchHandleClick = () => {
        this.setState({
            startDate: null,
            endDate: null
        }, () => {
            this._postSearchData();
        })
    }

    _searchClear = () => {

        this.setState({
            searchKeyword: "",
            dataLoading: true
        }, () => {
            this._getListDetails();// create a function and call default listing API with default data from here
        })

    }

    // calender close event handler 
    _onCloseHandler = (selected_dates) => {
        if (selected_dates.startDate == null || selected_dates.endDate == null) {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    }

    //get zoom account
    // _getZoomAccount = () => {
    //     var url = devconfig.admin + "/webinar/get-zoom-accounts";
    //     APIGet(url).then(function (response) {
    //         if (response.status) {
    //             this.setState({
    //                 ZoomAccount: response.data,
    //             });
    //         } else {
    //             this.setState({
    //                 ZoomAccount: null,
    //             });
    //         }
    //     })
    // }

    //get zoom account
    _getZoomAccount() {
        var url = devconfig.admin + "/webinar/get-zoom-accounts";
        APIGet(url).then(function (response) {
            if (response.status) {
                var processed_accounts = [];
                response.data.map(function (item, index) {
                    var item = {
                        value: item.id,
                        label: item.name
                    };
                    processed_accounts.push(item);
                });
                console.log("processed_accounts", processed_accounts)
                this.setState({
                    ZoomAccount: processed_accounts
                });
            }
        }.bind(this));
    }



    render() {
        // console.log("zoom account", this.state.ZoomAccount)
        return (
            <div className="e-main-content e-creators-wrap e-webinar-wrap">
                <h2>Webinars</h2>
                <div className='row'>
                    <div className='col-lg-6'>
                        <h5>All Webinars</h5>
                    </div>

                    {/* Title and search webinar section */}
                    <div className="col-lg-4 position-relative text-right e-search-wraper">
                        <SearchInput
                            placeholder="Search webinars"
                            className="e-dash-search e-dash-search-bar"
                            value={this.state.searchKeyword}
                            valueChange={(value) => {
                                this.setState({
                                    searchKeyword: value
                                })
                            }} />

                        {
                            this.state.searchKeyword.length != 0 ?
                                <>
                                    <span className="e-search-submit"
                                        onClick={this._searchHandleClick} ></span>
                                    <button className="e-search-btn-close"
                                        onClick={this._searchClear} />
                                </>
                                :
                                null
                        }
                    </div>

                    {/* Add webinars */}
                    {
                        this.state.initialApiStatus == false &&
                        <div className="col-lg-2 text-right">
                            <button type="submit"
                                value="Submit"
                                className='e-add-webinar-btn'
                                onClick={this.addWebinar.bind(this)}>Add Webinar</button>
                        </div>
                    }
                </div>

                <div className="row">

                    {/* filter by */}
                    <div className="col-lg-2">
                        <div className="e-filter-wrap ">
                            <label>Sort By:</label>
                            <SelectBox className="e-filter"
                                defaultValue={this.state.filter}
                                options={FILTERBY}
                                placeholder="Created"
                                value={this.state.filter}
                                selectchange={this.filterChange} />
                        </div>
                    </div>

                    {/* calender filter */}
                    <div className="col-lg-3">
                        <div className="e-filter-wrap">
                            <label>Filter by date:</label>

                            <div className="e-calendar-wrap">
                                <DateRangePicker displayFormat={() => "DD/MM/YYYY"}
                                    startDate={this.state.startDate}
                                    endDateId="e-endDate"
                                    startDateId="e-startDate"
                                    endDate={this.state.endDate}
                                    focusedInput={this.state.focusedInput}
                                    startDatePlaceholderText={"Start date"}
                                    endDatePlaceholderText={"End date"}
                                    disableScroll={false}
                                    hideKeyboardShortcutsPanel={true}
                                    regular={false}
                                    small
                                    numberOfMonths={1}
                                    showClearDates={true}
                                    minimumNights={0}
                                    onClose={this._onCloseHandler}
                                    onDatesChange={this.onDatesChange}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} />
                            </div>
                        </div>
                    </div>

                    {console.log('this.state.typeFilterDefault :: ', this.state.typeFilterDefault)}
                    {/* filter by */}
                    <div className="col-lg-2">
                        <div className="e-filter-wrap ">
                            <label>Type:</label>
                            <SelectBox className="e-filter"
                                defaultValue={this.state.typeFilterDefault}
                                options={WEBINAR_FILTER_TYPE}
                                value={this.state.typeFilterDefault}
                                selectchange={this._onUpdateWebinarType} />
                        </div>
                    </div>

                    {/* table and card view for the webinars */}
                    <div className='col-lg-5 mt-2 text-right pt-4'>
                        <img src={this.state.list ? ColumnView : ColumnActive}
                            draggable="false"
                            alt="column"
                            className='mr-5'
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setState({ list: false })} />
                        <img src={this.state.list ? ListActive : ListView}
                            draggable="false"
                            alt="list"
                            onClick={() => this.setState({ list: true })}
                            style={{ cursor: "pointer" }} />
                    </div>
                </div>

                <div className="row mt-4">
                    {
                        this.state.list ?
                            <>
                                {
                                    this.state.dataLoading == false ?
                                        <>


                                            <React.Fragment>
                                                {
                                                    this.state.data.length ?
                                                        <>
                                                            {this.state.dataLoading == true ?
                                                                <div className='col-12 text-center'>
                                                                    <LazyLoaderGif />
                                                                </div>
                                                                :
                                                                <div className="col-lg-12">
                                                                    <table className="table e-webinar-datas">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Webinar ID</th>
                                                                                <th>Name</th>
                                                                                <th>Date</th>
                                                                                <th>Duration</th>
                                                                                <th>Zoom ID</th>
                                                                                <th>Status</th>
                                                                                <th>Language</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody >
                                                                            {
                                                                                this.state.data.map((item, index) => (
                                                                                    <tr className="e-webinar-datas e-cursor-pointer" key={index}
                                                                                        onClick={() =>
                                                                                            window.location.href = `/webinar-detail?id=${item.id}`}>
                                                                                        <td className="e-creator-dull-txt ">{item.webinar_id}</td>
                                                                                        <td className="e-creator-dull-txt">{item.name}</td>
                                                                                        <td className="e-creator-dull-txt">{item.scheduled_date}</td>
                                                                                        <td className="e-creator-dull-txt ">{item.duration}</td>
                                                                                        <td className="e-creator-dull-txt">{item.zoom_meeting_id}</td>
                                                                                        {
                                                                                            item.active == true ?
                                                                                                <td className="e-creator-dull-txt">Enabled</td>
                                                                                                :
                                                                                                <td className="e-creator-dull-txt">Disabled</td>
                                                                                        }
                                                                                        <td className="e-creator-dull-txt">{item.language}</td>
                                                                                    </tr>

                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>


                                                            }
                                                        </>
                                                        :

                                                        <NothingFound data="No webinars found !" />
                                                }
                                            </React.Fragment>

                                        </>
                                        :
                                        <React.Fragment>
                                            <div className='col-12 text-center'>
                                                <LazyLoaderGif />
                                            </div>
                                        </React.Fragment>
                                }
                            </>

                            :
                            <React.Fragment>
                                {
                                    this.state.data.length ?
                                        <WebinarCard data={this.state.data} />
                                        :
                                        <NothingFound data="No webinars found !" />
                                }

                            </React.Fragment>
                    }


                </div>

                {/* create a state var for show/hide the add-webinar modal */}
                {
                    this.state.initialApiStatus == false &&
                    <AddWebinar modalClass={this.state.modalClass}
                        segments={this.state.segments}
                        languages={this.state.languages}
                        categories={this.state.categories}
                        active={ACTIVE}
                        selectedActive={ACTIVE[0].value}
                        selectedCat={[this.state.categories[0].value]}
                        selectedLang={[this.state.languages[0].value]}
                        selectedSeg={this.state.segments[0].value}
                        selectedZoomAccount= {this.state.ZoomAccount[0]}
                        zoomAccountCategory={this.state.ZoomAccount}
                        modalClose={this.addModalClose.bind(this)}
                        success={(e) => { this.triggerToast(e) }} />
                }
            </div >
        );
    }
}

export default AllWebinars;
