/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../../components/formComponents/input';
import APIPost from '../../../services/apiCall/post';
import devconfig from '../../../config/config.js';

/*Assets*/

class AddDiscount extends Component {
  constructor(props){
    super(props);
    this.state = {
      discount: ""
    }
  }


  buttonClick(){
    this.props.success(this.state.discount);
  }

  render() {
    return (
      <div className="modal fade" id="addDiscountModal" tabIndex="-1" role="dialog" aria-labelledby="addDiscountModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Discount</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mt-3">
              <Input label="Discount" id="u-lang" type="text" value={this.state.discount} valueChange={(tempVal) => this.setState({discount: tempVal})} />
              
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        this.state.discount.length ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Save</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default AddDiscount;
