/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Select from 'react-select';

/*Components*/
import Input from '../../../components/formComponents/input';
import APIPost from '../../../services/apiCall/post';
import APIGet from '../../../services/apiCall/get';
import devconfig from '../../../config/config.js';
import ImageUploader from '../../../components/common/imageUploader';
import UploadLoader from '../../../components/common/uploadLoader';
import Video from '../../../components/common/video';
import LazyLoaderGif from '../../../components/common/lazyloader';

/*Assets*/

class EditModuleModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      link: "",
      apiLoading: false,
      scheduleTime: "",
      catFilterOption: [],
      langFilterOption: [],
      showUploadLoader: false,
      logoURL: "",
      value: null,
      duration: null,
      moduleData: {'videos': []},
      selectedVideoValue: {value: 1, label: 'Paid'}
    }
  }

  componentDidMount() {

    $('#editModuleModal').on('shown.bs.modal',  () => {
        var props = this.props;
        var id = props.id;
        var moduleData = props.moduleData;
        this.setState({
          id: props.courseId,
          name: moduleData.name,
          moduleData: moduleData,
          moduleId: moduleData.id
        })
    })
  }

  handleTimeChange = value => {
    if(value){
      this.setState({ value, scheduleTime: value.format('HH:mm')});
    }
  };

  handleDurationChange = duration => {
    if(duration){
      this.setState({ duration,videoDuration: duration.format('HH:mm:ss')});
    }
  };

  catSelectChange = selectedValue => {
    this.setState({ selectedValue });
  };

  langSelectChange = selectedLangValue => {
    this.setState({ selectedLangValue });
  };
  

  logoUploaded(tempURL){
    this.setState({logoURL: tempURL, showUploadLoader: false});
  }

  triggerClick(tempItem, tempLoader){
    $(tempItem).trigger('click');
    this.setState({showUploadLoader: true});
  }

  updateModule(){
    this.setState({reviewsLoading: true});
    var url = devconfig.admin + "/course/update-module";
    var data = JSON.stringify({
      course_id : parseInt(this.state.id),
      id : parseInt(this.state.moduleId),
      videos: this.state.moduleData.videos,
      module_name: this.state.name
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        window.location.reload();
      }
      else{
        this.setState({ reviews: {}, reviewsLoading: false});
      }
    }.bind(this)) 
  }

  deleteModule(){
    var url = devconfig.admin + "/course/delete-module";
    var data = JSON.stringify({
      "id" : parseInt(this.state.moduleData.id)
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        window.location.reload();
      }
      else{
        this.setState({ reviews: {}, reviewsLoading: false});
      }
    }.bind(this)) 
  }

  refresh(){

  }


  async addVideo(video){
    await this.setState({videosLoading: true});
    var moduleData =  this.state.moduleData;
    var videos = moduleData.videos;
    if(video.id){

      var tempIndex = videos.findIndex(function(item) {
        return item.id == video.id
      });

      if(tempIndex == -1){
        videos.push(video);
      }
      else{
        videos[tempIndex] = video;
      }
      moduleData['videos'] = videos


      // var allVideos = []
      // var matched = false;
      // for (var i = videos.length - 1; i >= 0; i--) {
      //   var tmpVideo = videos[i]
      //   if(video.id===tmpVideo.id){
      //     console.log('match')
      //     matched = true;
      //     tmpVideo = video;
      //   }
      //   allVideos.push(tmpVideo);
      // }
      // if(!matched){
      //   allVideos.push(video);
      // }
      // moduleData['videos'] = allVideos


    }else{
      // new video, just add
      videos.push(video)
      moduleData['videos'] = videos;
    }
    this.setState({moduleData: moduleData, videosLoading: false})
  }
  
  disableBtn(){
    this.setState({buttonActive: false})
  }

  enableBtn(){
    this.setState({buttonActive: true})
  }

  async deleteVideo(id){
    this.deleteVideoApi(id);
    await this.setState({videosLoading: true});
    console.log("id to delete -->", id)
    var moduleData =  this.state.moduleData;
    var videos = moduleData.videos;
    if(id){
      var newVideoArray = []
      for (var i = videos.length - 1; i >= 0; i--) {
        var video = videos[i];
        if(video.id==id){
          console.log("video id is same")
        }else{
          newVideoArray.push(video);
        }
      }
      moduleData['videos'] = newVideoArray;
      console.log("moduleData after deletion -->", moduleData)
      await this.setState({moduleData: moduleData, videosLoading: false})
    }
  }

  

  deleteVideoApi(tempId){
    var url = devconfig.admin + "/course/delete-video";
    var data = JSON.stringify({
      "id" : parseInt(tempId)
    })

    APIPost(url, data).then(function(response){
      
    }.bind(this)) 
  }

  render() {
    const {selectedValue, selectedLangValue, duration, value, selectedPriceDiscountValue} = this.state;

    return (
      <div className="modal fade e-modal e-module-video-wrap" id="editModuleModal" tabIndex="-1" role="dialog" aria-labelledby="editModuleModalLabel" aria-hidden="true">
        <div className="modal-dialog e-add-module-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Module</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <Input label="Module heading" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
                </div>
                {
                  this.state.videosLoading ? <LazyLoaderGif /> :
                  Object.keys(this.state.moduleData).length && this.state.moduleData.videos.length?
                    this.state.moduleData.videos.map((video, vIndex)=>{
                      if(!video.id){
                        return null
                      }
                      return(
                        <Video 
                          data={video} 
                          key={vIndex} 
                          index={vIndex} 
                          buttonDisable={this.disableBtn.bind(this)}
                          buttonEnable={this.enableBtn.bind(this)}
                          moduleId={this.state.moduleData.id} 
                          courseId={this.state.id} 
                          deleteVideo={this.deleteVideo.bind(this)}
                          addVideo={this.addVideo.bind(this)}
                          refresh={this.refresh} />
                      )
                    })
                  :
                    null
                }

                {
                  !this.state.moduleData.videos.length || (this.state.moduleData.videos[this.state.moduleData.videos.length - 1].video_url.length && this.state.moduleData.videos[this.state.moduleData.videos.length - 1].name.length) ?
                    <div className="col-lg-6">
                      <div className="e-add-module" onClick={()=>this.addVideo({
                          id: new Date().getTime(), 
                          video_url: "", 
                          duration_sec: "", 
                          duration_string: "", 
                          free: "",
                          active: "",
                          name: ""
                        })}>
                        + Add New Video
                      </div>
                    </div>
                  :
                    null
                }

                <div className="col-lg-12 mt-5">
                  <div className="row">
                    <div className="col-lg-6">
                      <span className="e-s-link-txt e-delete-link mr-4" data-dismiss="modal" onClick={()=>this.deleteModule()}>Delete this module</span>
                    </div>
                    <div className="col-lg-6">
                      <div className="e-addPromo-btn-wrap text-right">
                        <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                        {
                          (this.state.moduleData.videos.length && this.state.moduleData.videos[0].video_url.length) && this.state.name && !this.state.apiLoading ?
                            <button type="submit" form="e-login-form" onClick={this.updateModule.bind(this)}>Update Module</button>
                          :
                            <button className="e-inactive" form="e-login-form" value="Submit">Update Module</button>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditModuleModal;
