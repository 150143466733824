/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../../components/formComponents/input';
import Textarea from '../../../components/formComponents/textarea';
import ImageUploader from '../../common/imageUploader';

/*Assets*/

class AddNewHostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cname: "",
      code: "",
      description:"",
      youtubeUrl: "",
      fbUrl: "",
      twitterUrl: "",
    }
  }

  logoUploaded(tempData) {
    // alert(tempData)
    this.setState({ logo: tempData })
  }

  async logoUploadTrigger(tempType) {
    await this.setState({ imgLoader: tempType });
    $("#e-prod-logo-upload").click()
  }

  async uploadStatus(status) {
    this.setState({ uploading: status })
  }

  logoUploaded(tempData) {
    // alert(tempData)
    this.setState({ logo: tempData })
  }

  render() {
    return (
      <div className="modal fade e-add-host-modal" id="addNewHostModal" tabIndex="-1" role="dialog" aria-labelledby="addNewHostModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add new host</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className='row'>
                <div className='col-lg-4'>
                  <div className="e-v-input-wrap">
                    <label>Host profile pic*</label>

                    {
                      this.state.imgLoader == 1 && this.state.uploading ?
                        <span className="e-img-upload-loader"></span>
                        :
                        <React.Fragment>
                          {
                            this.state.logo ?
                              <span className="e-img-preview">
                                <img src={this.state.logo} />
                                <span className="e-change" onClick={() => { this.setState({ logo: null }) }}></span>
                              </span>
                              :
                              <React.Fragment>
                                <span className="e-upload-btn e-store text-center mb-3" onClick={this.logoUploadTrigger.bind(this, 1)}><span className="mt-2">+ Add an image</span></span>
                              </React.Fragment>
                          }
                        </React.Fragment>
                    }
                    <ImageUploader
                      accept="application/pdf, image/*"
                      className="hidden"
                      tempKey="/prod/illustration"
                      id="e-prod-logo-upload"
                      uploadStatus={this.uploadStatus.bind(this)}
                      uploadSucess={this.logoUploaded.bind(this)}
                    />
                  </div>
                </div>
              </div>
              <div className="e-v-input-wrap">
                <label>Host name</label>
                <Input type="text" placeholder="Enter the host name" value={this.state.name} valueChange={(tempVal) => this.setState({ name: tempVal })} />
                <label>Company name</label>
                <Input type="text" placeholder="Enter the company name" value={this.state.cname} valueChange={(tempVal) => this.setState({ cname: tempVal })} />
                <label>Partner code</label>
                <Input type="text" placeholder="Enter the partner code" value={this.state.code} valueChange={(tempVal) => this.setState({ code: tempVal })} />
                <label>Short description</label>
                <Textarea placeholder="Enter the description" value={this.state.description} valueChange={(tempVal) => this.setState({ description: tempVal })} />
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="e-v-input-wrap">
                    <label>Social media links</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className="e-v-input-wrap e-social-link">
                    <Input type="text" value={this.state.youtubeUrl} valueChange={(tempVal) => this.setState({ youtubeUrl: tempVal })} className="e-utube-icon" />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className="e-v-input-wrap e-social-link">
                    <Input type="text" value={this.state.fbUrl} valueChange={(tempVal) => this.setState({ fbUrl: tempVal })} className="e-fb-icon" />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className="e-v-input-wrap e-social-link">
                    <Input type="text" value={this.state.twitterUrl} valueChange={(tempVal) => this.setState({ twitterUrl: tempVal })} className="e-twitter-icon" />
                  </div>
                </div>
              </div>
            </div>
           <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>

              <span>
                {
                  this.state.name.length && this.state.cname.length && this.state.code && this.state.description?
                    <button type="submit" form="e-login-form" value="Submit" className='e-add-btn'>Add new host</button>
                    :
                    <button type="submit" form="e-login-form" value="Submit" className='e-inactive e-add-btn' >Add new host</button>
                }
              </span>

            </div> 
        </div>
      </div>
      </div>

    );
  }
}

export default AddNewHostModal;
