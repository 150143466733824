/*Import Packages*/
import React, { Component } from 'react';
import {BrowserRouter, Route, Redirect} from 'react-router-dom';
 
/*Import pages*/ 
import Login from './container/auth/login.js';
// import AllCreators from './container/creators/allCreators.js';
import AllWebinarCreators from './container/creators/allWebinarCreators.js';
import InvitesList from './container/invites/invitesList.js';
import UsersList from './container/users/usersList.js';
import Settings from './container/settings/main.js';
import CreatorDetail from './container/creators/detail.js';
import SendNotification from './container/notification/sendNotify.js';
import BannerListing from './container/banners/bannerListing.js';

/*Version - 2*/
import AllCourses from './container/courses/courseList.js';
import AllCourseTutors from './container/courses/tutors.js';
import CourseDetail from './container/courses/courseDetail.js';
import InvoiceListing from './container/invoices/invoiceListing.js';
import Refferals from './container/refferals/refferals';
import AllWebinars from './container/webinar/allWebinar.js';
import WebinarDetail from './container/webinar/webinar-detail.js';
import WebinarCreatorDetail from './container/creators/webinarCreatorDetail.js';
import Dashboard from './container/dashboard/index.js';
 
/*Import global components like header, footers*/
import Sidebar from './components/navigation/sidebar.js';
import Topbar from './components/navigation/topbar.js';
 
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
  
    }
  }
 
  render() {
    if(localStorage.getItem("u_token")){
      return(
        <BrowserRouter>
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 e-sidebar-wrap p-0">
                  <Sidebar/>
                </div>
                <div className="col-lg-10 p-0">
                  <Topbar/>
                  <Route exact path="/" render={() => (
                    <Redirect to="/dashboard"/>
                  )}/>
                  {/* <Route path="/webinar-tutors" component={AllCreators}/> */}
                  <Route path="/webinar-tutors" component={AllWebinarCreators}/>
                  <Route path="/invites" component={InvitesList}/>
                  <Route path="/users" component={UsersList}/>
                  <Route path="/settings" component={Settings}/>
                  <Route path="/detail" component={CreatorDetail}/>
                  <Route path="/notification" component={SendNotification}/>
                  <Route path="/course-tutors" component={AllCourseTutors}/>
                  <Route path="/courses" component={AllCourses}/>
                  <Route path="/course-detail" component={CourseDetail}/>
                  <Route path="/invoices" component={InvoiceListing}/>
                  <Route path="/refferals" component={Refferals}/>
                  <Route path="/webinar" component={AllWebinars}/>
                  <Route path="/webinar-detail" component={WebinarDetail}/>
                  <Route path="/manage-banners" component={BannerListing}/>
                  <Route path="/webinar-creator-detail" component={WebinarCreatorDetail}/>
                  <Route path="/dashboard" component={Dashboard}/>
                </div>
              </div>
            </div>
          </section>
        </BrowserRouter>
      )
    }
    else{
      return(
        <BrowserRouter>
          <div>
            <Route path="/" exact component={Login}/>

          </div>
        </BrowserRouter>
      )
    }
  }
}
 
export default App;