
/*import package*/
import React, { useEffect, useState } from 'react';

/*import assets */
import Arrow from "../../assets/images/icons/back-arrow.svg"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SummaryCard = (props) => {

    const history = useHistory();

    const _handleClick = () => {
        if (props.isGeneral == false) {
            history.push({
                pathname: "/webinar",
                state: { type: props.type }
              });
        } else {
            return;
        }
        window.location.reload();
    }
    return (
        <>
            <div className="card e-webinarcard e-summary-card border-0" style={{ cursor: props.isGeneral == false && "pointer" }}
                onClick={_handleClick}>
                <div className="card-body p-4 m-1">
                    <div className='row'>
                        <div className='col-9'>
                            <h6 className="card-title mb-0">{props.title}</h6>
                        </div>
                        {props.isGeneral == true ? null :
                            <div className='col-3 text-right'>
                                <img draggable="false" src={Arrow} width={22} height={22} className='e-right-arrow' />
                            </div>}
                    </div>
                    <div className='row pt-4 mt-2'>

                        <div className='col-lg-9'>
                            <span>{props.count}</span>
                        </div>

                        <div className='col-lg-3 mt-2 text-right'>
                            <span className='e-icon-wrap'>
                                <img draggable="false" src={props.img} alt="icon" />
                            </span>
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
}
export default SummaryCard;

