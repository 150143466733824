/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';
import SelectBox from '../../components/common/selectBox';
import LazyLoaderGif from '../../components/common/lazyloader';

import NothingFound from '../../components/common/nothingFound';

/*Assets*/
import profile from '../../assets/images/profile.png';

class AllCourses extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: null,
      filterDefault: {value: null, label: 'All Videos'},
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      categoryFilter: [],
      dataLoading: false,
      pageNum: 1,
      lazyLoadStatus: false,
      lazyDataEnd: true
    }
  }

  componentDidMount() {
    this.getData(null, 1, this.state.pageNum);
    this.getCategory();
  }

  getCategory(){
    var url = devconfig.admin+"/course-category/get-all";
    APIGet(url).then(function(response){
      if(response.status){
        var tempArry = [];
        tempArry.push({ value: null, label: 'All Videos' });
        response.data.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });
        this.setState({ categoryFilter: tempArry});
      }
    }.bind(this))
  }

  getData(tempFilter, tempSort, tempPagenum){
    if(tempPagenum == 1){
      this.setState({dataLoading: true});
    }
    var url = devconfig.admin + "/creator/courses";
    var data = JSON.stringify({
      "creator_id" : parseInt(this.props.creator_id),
      "category_filter" : this.state.filter,
      "sort_value" : this.state.sort,
      "page_num": tempPagenum
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        tempArry.push({ value: null, label: 'All Videos' });



        {/*Find lazy load or not*/}
        if(this.state.data.length && this.state.lazyLoadStatus){
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({data: tempCardArry});
        }
        else{
          this.setState({data: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({lazyDataEnd: true, lazyLoadStatus: false});
        }
        else{
          this.setState({lazyDataEnd: false, lazyLoadStatus: false});
        }




        this.setState({ dataLoading: false});
      }
      else{
        this.setState({ data: [], dataLoading: false, lazyDataEnd: true, lazyLoadStatus: false});
      }
    }.bind(this))
  }
  

  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.getData(filter.value, this.state.sort, this.state.pageNum);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.getData(this.state.filter, sort.value, this.state.pageNum);
  }

  loadMoreClick(){
    this.getData(this.state.filter, this.state.sort, this.state.pageNum + 1);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
  }

  render() {
    const filterBy = [
      { value: null, label: 'All Creators' },
      { value: 1, label: 'Top 10' },
      { value: 2, label: 'Muted' },
    ] 
    const sortBy = [
      { value: 1, label: 'Name' },
      { value: 2, label: 'Latest scheduled' },
      { value: 3, label: 'Order of created' },
    ]

    return (
      <div className="">
        {/*
          !this.state.dataLoading && this.state.data.length ?
            <div className="row">
              <div className="col-lg-2">
                <div className="e-filter-wrap pt-2">
                  <label>Category:</label>
                  <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
              </div>
              <div className="col-lg-2 p-0">
                <div className="e-filter-wrap pt-2">
                  <label>Sort by:</label>
                  <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
                </div>
              </div>
            </div>
          :
            null
        */}

        <div className="row">
          <div className="col-lg-2">
            <div className="e-filter-wrap pt-2">
              <label>Category:</label>
              <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
            </div>
          </div>
          <div className="col-lg-2 p-0">
            <div className="e-filter-wrap pt-2">
              <label>Sort by:</label>
              <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
            </div>
          </div>
        </div>
        
        {
          this.state.dataLoading ?
            <div className="row mt-5">
              <div className="col-lg-12 text-center">
                <LazyLoaderGif/>
              </div>
            </div>
          :
            <div className="row mt-3">
            {
              this.state.data.length ?
                this.state.data.map( (data, index) => {
                  return(
                    <div className="col-lg-3 e-video-card-wrap mb-4 hand" onClick={()=>window.location = "course-detail?id=" + data.id} key={index}>
                      <div className="card">
                        <div className="position-relative">
                          <img className="card-img-top" src={data.image} alt="Card thumbnail"/>
                          <span className="e-video-duration">{data.duration ? data.duration : "--"}</span>
                          {
                            data.live ?
                              <span className="e-video-live">Live</span>
                            :
                              null
                          }
                        </div>
                        <div className="card-body">
                          <h5 className="card-title e-p-ellipsis-2">{data.name}</h5>
                          <p className="e-card-time">{data.scheduled_time}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              :
                <NothingFound data={"No upcoming videos found!"}/>
            }
          </div>
        }

        {
          !this.state.lazyDataEnd && !this.state.lazyLoadStatus ?
            <div className="col-lg-12 text-center py-3">
              <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
            </div>
          :
            null
        }

        {
          this.state.lazyLoadStatus ?
            <div className="col-lg-12 text-center py-3">
              <LazyLoaderGif/>
            </div>
          :
            null
        }
        
      </div>
      
    );
  }
}

export default AllCourses;