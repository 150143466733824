/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import SelectBox from '../../components/common/selectBox';
import LazyLoaderGif from '../../components/common/lazyloader';
import RemoveVideo from '../../components/modals/removeVideo';
import NothingFound from '../../components/common/nothingFound';

/*Assets*/
import profile from '../../assets/images/profile.png';

class AllVideos extends Component {
  constructor(props){
    super(props);
    this.state = {
      filter: null,
      sort: null,
      filterDefault: {value: null, label: 'All Videos'},
      sortDefault: {value: 1, label: 'Name'},
      data: [],
      categoryFilter: [],
      dataLoading: false,
      pageNum: 1,
      lazyDataEnd: true,
      lazyLoadStatus: false,
      deleteItem: {}
    }
  }

  componentDidMount() {
    this.allVideosApi(this.state.pageNum, null, 1);
  }

  allVideosApi(tempPageNum, tempFilter, tempSort){
    if(this.state.tempPageNum == 1){
      this.setState({ dataLoading: true});
    }
    
    var url = devconfig.admin+"/creator/all-webinars";
    var data = JSON.stringify({
      "creator_id" : parseInt(this.props.creator_id),
      "category_filter" : this.state.filter,
      "sort_value" : this.state.sort,
      "page_num" : tempPageNum
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        {/*Type Filter option*/}
        var tempArry = [];
        tempArry.push({ value: null, label: 'All Videos' });
        response.category_filter.map(function(item, index){
          var item = {value: item.id, label:item.category};
          tempArry.push(item);
        });

        {/*Find lazy load or not*/}
        if(this.state.data.length && this.state.lazyLoadStatus){
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({data: tempCardArry});
        }
        else{
          this.setState({data: response.data});
        }

        {/*Find lazy load ends*/}
        if(response.data.length <= 19){
          this.setState({lazyDataEnd: true});
        }
        else{
          this.setState({lazyDataEnd: false});
        }

        this.setState({ categoryFilter: tempArry, dataLoading: false, lazyLoadStatus: false});
      }
      else{
        this.setState({ data: [], dataLoading: false, lazyDataEnd: true, lazyLoadStatus: false});
      }
    }.bind(this))
  }
  

  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.allVideosApi(this.state.pageNum, filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.allVideosApi(this.state.pageNum, this.state.filter, sort.value);
  }

  loadMoreClick(){
    this.allVideosApi(this.state.pageNum + 1, this.state.filter, this.state.sort);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
    
  }

  deleteClick(tempData){
    this.setState({ deleteItem: tempData });
  }

  deleteSuccess(){
    var url = devconfig.admin+"/creator/remove-webinar";
    var data = JSON.stringify({
      "webinar_id" : this.state.deleteItem.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#removeVideoModal').modal('hide');
        this.allVideosApi(1, null, 1);
        this.clearFilter();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  clearFilter(){
    this.setState({
      lazyDataEnd: true,
      lazyLoadStatus: false,
      deleteItem: {},
      pageNum: 1,
      filterDefault: {value: null, label: 'All Videos'},
      sortDefault: {value: 1, label: 'Name'},
      filter: null,
      sort: null,
    });
  }

  render() {
    const filterBy = [
      { value: null, label: 'All Creators' },
      { value: 1, label: 'Top 10' },
      { value: 2, label: 'Muted' },
    ]
    const sortBy = [
      { value: 1, label: 'Name' },
      { value: 2, label: 'Latest scheduled' },
      { value: 3, label: 'Order of created' },
    ]

    return (
      <div className="">
        {/*
          !this.state.dataLoading && this.state.data.length ?
            <div className="row">
              <div className="col-lg-2">
                <div className="e-filter-wrap pt-2">
                  <label>Category:</label>
                  <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
                </div>
              </div>
              <div className="col-lg-2 p-0">
                <div className="e-filter-wrap pt-2">
                  <label>Sort by:</label>
                  <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
                </div>
              </div>
            </div>
          :
            null
        */}

        <div className="row">
          <div className="col-lg-2">
            <div className="e-filter-wrap pt-2">
              <label>Category:</label>
              <SelectBox defaultValue={this.state.filterDefault} options={this.state.categoryFilter} selectchange={this.filterChange} placeholder="Filter"/>
            </div>
          </div>
          <div className="col-lg-2 p-0">
            <div className="e-filter-wrap pt-2">
              <label>Sort by:</label>
              <SelectBox defaultValue={this.state.sortDefault} options={sortBy} selectchange={this.sortChange} placeholder="Sort"/>
            </div>
          </div>
        </div>
        
        {
          this.state.dataLoading ?
            <div className="row mt-5">
              <div className="col-lg-12 text-center">
                <LazyLoaderGif/>
              </div>
            </div>
          :
            <div className="row mt-3">
            {
              this.state.data.length ?
                this.state.data.map( (data, index) => {
                  return(
                    <div className="col-lg-3 e-video-card-wrap mb-4 hand"  key={index}>
                      <div className="card e-card">
                        <div className="position-relative">
                          <img className="card-img-top" src={data.thumbnail} alt="Card thumbnail" onClick={()=>window.open(data.link)}/>
                          <span className="e-video-duration">{data.duration}</span>
                          {
                            data.live ?
                              <span className="e-video-live">Live</span>
                            :
                              null
                          }
                        </div>
                        <div className="card-body">
                          <h5 className="card-title e-p-ellipsis-2">{data.name}</h5>
                          <p className="e-card-time">{data.scheduled_time}</p>
                          <span className="e-card-delete" data-toggle="modal" data-target="#removeVideoModal" onClick={this.deleteClick.bind(this, data)}></span>
                        </div>
                      </div>
                    </div>
                  );
                })
              :
                <NothingFound data="No videos found!"/>
            }
            {
              !this.state.lazyDataEnd && !this.state.lazyLoadStatus ?
                <div className="col-lg-12 text-center py-3">
                  <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                </div>
              :
                null
            }

            {
              this.state.lazyLoadStatus ?
                <div className="col-lg-12 text-center py-3">
                  <LazyLoaderGif/>
                </div>
              :
                null
            }
          </div>
        }

        {/*Modals*/}
        <RemoveVideo success={this.deleteSuccess.bind(this)}/>
      </div>
      
    );
  }
}

export default AllVideos;
