/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import APIPost from '../../../services/apiCall/post';
import devconfig from '../../../config/config.js';

/*Assets*/

class RemoveLanguage extends Component {
  constructor(props){
    super(props);
    this.state = {
      errorMessage: ""
    }
  }

  componentWillMount(){
    setTimeout(function(){
      $('#removeLangModal').modal('show');
    }.bind(this),100);
  }

  removeClick(){
    // var url = devconfig.admin+"/language/delete";
    if(this.props.tabActive==1){ 
      var url = devconfig.admin+"/language/delete"; 
    }else{ 
      var url = devconfig.admin+"/course-language/delete"; 
    }
    var data = JSON.stringify({
      "id" : this.props.data.id,
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        $('#removeLangModal').modal('hide');
        this.props.success();
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  closeClick(){
    this.props.close();
  }

  render() {
    return (
      <div className="modal fade" id="removeLangModal" tabIndex="-1" role="dialog" aria-labelledby="removeLangModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Remove Language</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeClick.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to remove this language ?
              </p>
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal" onClick={this.closeClick.bind(this)}>Cancel</span>
              <span className="e-p-link-txt" onClick={this.removeClick.bind(this)}>Remove</span>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default RemoveLanguage;
