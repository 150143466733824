/*Package importing*/
import React, { Component } from 'react';
import Select from 'react-select';

class SelectBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.setState({ selectedValue: this.props.value });
    }

    selectChange = selectedValue => {
        this.setState({ selectedValue });
        this.props.selectchange(selectedValue)
    };

    render() {
        return (
            <Select isSearchable={false}
                className={this.props.className}
                options={this.props.options}
                value={this.props.value}
                isMulti={this.props.selectType}
                placeholder={this.props.placeholder}
                onChange={this.selectChange} />
        );
    }
}

export default SelectBox;
