import React from 'react';
import $ from 'jquery';

import Input from '../../../components/formComponents/input';

export default class paymentModal extends React.Component{
  constructor(props){
    super(props);
    this.state={
      amount: null,
    }
  }

  click=()=>{
  this.props.save(this.state.amount);
  $('#payModal').modal('toggle');
  }
  render(){
    return(

    <div id="payModal" class="modal fade e-payModal text-left" role="dialog">
  <div class="modal-dialog">

    
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Pay Now</h4>
      </div>
      <div class="modal-body">
        <Input placeholder="Enter the amount" value={this.state.amount} valueChange={(tempVal)=> this.setState({amount: tempVal})} />
      </div>
      <div class="modal-footer">
        <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
        <button type="submit" form="e-login-form" onClick={this.click}>Save</button>
                          
      </div>
    </div>

  </div>
</div>

    )
  }
}