/*Package importing*/
import React, { Component } from 'react';

/*Components*/
import WebinarTutorCard from '../../components/card/webinarTutorCard.js';
import AddNewHostModal from '../../components/modals/webinar/addNewHost.js';

/*Assets*/
import Tutor1 from '../../assets/images/cards/tutor1.png';
import Tutor2 from '../../assets/images/cards/tutor2.png';
import Tutor3 from '../../assets/images/cards/tutor3.png';
import Tutor4 from '../../assets/images/cards/tutor4.png';
import Tutor5 from '../../assets/images/cards/tutor5.png';
import Tutor6 from '../../assets/images/cards/tutor6.png';
import Tutor7 from '../../assets/images/cards/tutor7.png';
import Tutor8 from '../../assets/images/cards/tutor8.png';
import { Link } from 'react-router-dom';

class AllWebinarCreators extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {

    const demoArray = [
      {
        name: 'Edward newgate',
        img: Tutor1,
        count: 4
      },
      {
        name: 'Devon Miles',
        img: Tutor2,
        count: 4
      },
      {
        name: 'B.A. Baracus',
        img: Tutor3,
        count: 4
      },
      {
        name: 'Theodore T.C. Calvin',
        img: Tutor4,
        count: 4
      },
      {
        name: 'Michael Knight',
        img: Tutor5,
        count: 4
      },
      {
        name: 'Willie Tanner',
        img: Tutor6,
        count: 4
      },
      {
        name: 'Capt. Trunk',
        img: Tutor7,
        count: 4
      },
      {
        name: 'Mike Torello',
        img: Tutor8,
        count: 4
      },

    ]

    return (
      <div className="e-main-content e-creators-wrap e-webinar-wrap">
        <h2>All Webinar Tutors</h2>
        <div className='row'>
          <div className='col-lg-10'>
            <h5>Webinar tutor list</h5>
          </div>
          <div className=" col-lg-2 text-right">
            <button type="submit" value="Submit" className='e-add-webinar-btn' data-toggle="modal" data-target="#addNewHostModal" >Create new host</button>
          </div>
        </div>
        <div className='row mt-4 pt-2'>
          {
            demoArray.map((item, key) => {
              return (
                <div className='col-lg-3 mb-4 pb-2'>
                  <Link to='/webinar-creator-detail'>
                    <WebinarTutorCard data={item} />
                  </Link>

                </div>
              )
            })
          }
        </div>
        <AddNewHostModal />
      </div>

    );
  }
}

export default AllWebinarCreators;
