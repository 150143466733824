/*Package importing*/
import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import $ from 'jquery';
import moment from 'moment';

/*Components*/
import Input from '../../formComponents/input';
import ImageUploader from '../../common/imageUploader';
import DatePicker from '../../common/singleDate';
import TimePickerComponent from '../../common/timePicker';
import Editor from '../../formComponents/wisyvig';
import Toast from '../../toast/toast';

/*Assets*/
import Close from '../../../assets/images/close-btn.svg';
import LazyLoaderGif from '../../common/lazyloader';
import devconfig from '../../../config/config';

import RadioGroup from '../../formComponents/radio-group';
import APIPost from '../../../services/apiCall/post';
import SelectBox from '../../common/selectBox';

// WEBINAR_ACTIVE_STATUS
const WEBINAR_STATUS = [
    { 'value': 1, 'label': 'Enabled' },
    { 'value': 2, 'label': 'Disabled' }
]


class AddWebinar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduledTime: null,
            catFilterOption: this.props.categories,
            langFilterOption: this.props.languages,
            segFilterOption: this.props.segments,
            selectedSegment: this.props.selectedSeg,
            selectedLanguage: this.props.selectedLang,
            selectedZooomAccount: this.props.selectedZoomAccount,
            // selectedLanguage: [],
            zoomAccount: this.props.zoomAccountCategory,
            checkedID: this.props.selectedCat,
            showUploadLoader: false,
            imageURL: "",
            editorError: null,
            webinarTime: "",
            duration: "",
            release_date: "",
            agendas: [''],
            error: '',
            agendaError: [],
            selectedActive: WEBINAR_STATUS[0].value, // default selection,
            webinarType: 0
        }
    }

    componentDidMount() {
        console.log('this.props.selectedLang', this.state.selectedLanguage);
        if (this.props.editData && Object.keys(this.props.editData).length !== 0) {
            console.log('this.props.editData', this.props.editData);
            const { editData } = this.props;
            console.log('language--', editData.language_ids);
            this.setState({
                name: editData.name,
                link: editData.intro_video,
                enroll: editData.enrolled_count,
                imageURL: editData.thumbnail,
                meeting: editData.zoom_meeting_id,
                editorValue: editData.description,
                duration: moment(editData.duration_value, 'HH:mm:ss'),
                release_date: editData.scheduled_date,
                webinarTime: moment(editData.scheduled_time, 'HH:mm:ss'),
                agendas: this.props.editData.agenda,
                selectedActive: this.props.editData.active == true ? 1 : 2, // edited data
                passcode: editData.passcode,
                webinarUrl: editData.recorded_video_url,
                webinarType: editData.type,
                // selectedZooomAccount:editData.value
            });
        }
    }

    hideLogin = () => {
        this.props.modalClose();
        this.setState({
            name: "",
            imageURL: "",
            link: "",
            meeting: "",
            enroll: "",
            agendas: [''],
            checkedID: "",
            selectedSegment: this.props.selectedSeg,
            selectedLanguage: this.props.selectedLang,
            selectedActive: this.props.selectedActive,
            editorValue: "",
            release_date: " ",
            webinarTime: "",
            duration: "",
            nameError: "",
            imageError: "",
            zoomError: "",
            enrollError: "",
            linkError: "",
            descriptionError: "",
            agendaError: [],
            dateError: "",
            webinarTimeError: "",
            durationError: "",
            errorMessage: "",
            passcode: "",
            webinarUrl: "",
            webinarUrlError: "",
            passcodeError: "",

        });
    }

    logoUploaded(tempURL) {
        console.log(tempURL)
        this.setState({
            imageURL: tempURL,
            showUploadLoader: false
        });
    }

    triggerClick(tempItem, tempLoader) {
        $(tempItem).trigger('click');
    }

    imageSelected() {
        this.setState({ showUploadLoader: true });
    }

    sortChange = async (sort) => {
        await this.setState({ sort: sort.value });
    }

    //To add new webinar
    _validateSubmit = () => {
        let validationSuccess = true;
        if (this.state.name == null) {
            this.setState({
                nameError: "Title is required",
            });
        }
        // if (this.state.link == null) {
        //     this.setState({
        //         linkError: "Link is required",
        //     });
        // }
        if (this.state.uploadSuccess == null && this.state.imageURL.length == 0) {
            this.setState({
                imageError: "Thumbnail is required",
            });
        }
        if (this.state.meeting == null) {
            this.setState({
                zoomError: "Zoom meeting ID is required",
            });
        }
        if (this.state.editorValue == null) {
            this.setState({
                descriptionError: "Description is required",
            });
        }

        if (this.state.agendas.length != 0) {
            let agendas = this.state.agendas;
            let error = [];
            for (let index = 0; index < agendas.length; index++) {
                if (agendas[index].length === 0) {
                    error.push(index);
                }

            }
            this.setState({
                agendaError: error,
            });
        }

        if (this.state.enroll == null) {
            this.setState({
                enrollError: "Enrolled is required",
            });
        }

        if (this.state.release_date == '') {
            console.log("Date is not selected. Setting dateError.");
            this.setState({
                dateError: "Date is required",
            });
            validationSuccess = false; // Set validationSuccess to false to prevent form submission
        } else {
            console.log("Date is selected. Clearing dateError.");
            this.setState({
                dateError: "", // Clear the error message when the date is selected
            });
        }

        if (this.state.webinarTime == '') {
            this.setState({
                webinarTimeError: "Time is required",
            });
        }
        if (this.state.duration == '') {
            this.setState({
                durationError: "Duration is required",
            });
        }
        // Commenting validation as per client requirement
        // if (this.state.webinarUrl == null && this.state.webinarType == 2) { // validate only for completed webinars
        //     validationSuccess = false;
        //     this.setState({
        //         webinarUrlError: "Webinar url is required",
        //     });
        // }
        // if (this.state.passcode == null && this.state.webinarType == 2) { // validate only for completed webinars
        //     validationSuccess = false;
        //     this.setState({
        //         passcodeError: "Passcode is required",
        //     });
        // }

        // console.log('validationSuccess :: ', validationSuccess)
        // var validationSuccess = true;
        if (validationSuccess) {
            this.setState({
                errorMessage: "",
                dateError: "",
                descriptionError: '',
                nameError: "",
                // linkError: "",
                enrollError: "",
                imageError: "",
                zoomError: "",
                webinarTimeError: "",
                durationError: null,
                apiLoading: true,
                webinarUrlError: "",
                passcodeError: ""
            });

            if (this.props.editData) {
                var scheduleTime = this.state.webinarTime.length != 0 && this.state.webinarTime.format("HH:mm");
                var webinarDuration = this.state.duration.length != 0 && this.state.duration.format("HH:mm:ss");
                var url = devconfig.admin + "/webinar/update-webinar";
                var data = JSON.stringify({
                    "video_id": this.props.editData.id,
                    "name": this.state.name,
                    "intro_video": this.state.link,
                    "enrolled_count": this.state.enroll,
                    "category_id": this.state.checkedID,
                    "language_ids": this.state.selectedLanguage,
                    "segment_id": this.state.selectedSegment,
                    "scheduled_time": this.state.release_date + ' ' + scheduleTime,
                    "thumbnail": this.state.imageURL,
                    "duration": webinarDuration,
                    "account": this.state.selectedZooomAccount.value,
                    "zoom_meeting_id": this.state.meeting,
                    "description": this.state.editorValue,
                    "agenda_list": this.state.agendas,
                    "active": this.state.selectedActive == 1 ? true : false,
                    "recorded_video_url": this.state.webinarUrl,
                    "passcode": this.state.passcode,
                });
                console.log('Edit flow Zoom account info :: ',this.state.selectedZooomAccount.value)
            }  else {
                var scheduleTime = this.state.webinarTime.length != 0 && this.state.webinarTime.format("HH:mm");
                var webinarDuration = this.state.duration.length != 0 && this.state.duration.format("HH:mm");
                var url = devconfig.admin + "/webinar/new-webinar";
                var data = JSON.stringify({
                    "name": this.state.name,
                    "intro_video": this.state.link,
                    "enrolled_count": this.state.enroll,
                    "category_id": this.state.checkedID,
                    "language_ids": this.state.selectedLanguage,
                    "segment_id": this.state.selectedSegment,
                    "scheduled_time": this.state.release_date + ' ' + scheduleTime,
                    "thumbnail": this.state.imageURL,
                    "duration": webinarDuration,
                    "account": this.state.selectedZooomAccount.value,
                    "zoom_meeting_id": this.state.meeting,
                    "description": this.state.editorValue,
                    "agenda_list": this.state.agendas,
                    "active": true
                })
            }
            console.log('Params :: ',data);
            APIPost(url, data).then(function (response) {
                if (response.status) {
                    this.setState({
                        toast: true,
                        message: response.message,
                        type: 'success'
                    });
                    setTimeout(() => {
                        $('#addWebinar').modal('hide')
                    }, 8000);
                    this.hideLogin();
                    window.location.reload();
                    this.setState({
                        apiLoading: false
                    });
                    this.props.success();
                }
                else {
                    this.setState({
                        apiLoading: false,
                        errorMessage: response.message
                    });
                }
            }.bind(this))
            setTimeout(() => {
                this.setState({
                    toast: false
                })
            }, 4000)
        } else {
            validationSuccess = false;
        }
        return null;
    }

    //radio handle change for languages
    // _handleSelectLanguage = (selected_id) => {
    //     console.log("id", selected_id)
    //     this.setState({
    //         selectedLanguage: selected_id
    //     });
    // };




    //radio handle change for segments
    _handleSelectSegment = (selected_id) => {
        console.log("id", selected_id)
        this.setState({
            selectedSegment: selected_id
        });
    };

    //radio handle change for active
    _handleSelectActive = (selected_id) => {
        this.setState({
            selectedActive: selected_id
        });
    };

    //value change for wisyvig editor
    handleEditorChange = (newData) => {
        // Update the state with the new data from the Editor component
        this.setState({
            editorValue: newData
        });

    };


    //checkbox handleclick
    _handleCheck = (option) => {
        console.log('this.state.checkedID', this.state.checkedID);// Create a new array to avoid direct state modification
        let checkItem = [...this.state.checkedID];
        if (checkItem.includes(option.value)) {
            // If the ID is already in the array, remove it
            checkItem = checkItem.filter(id => id !== option.value);
        } else {
            // If the ID is not in the array, add it
            checkItem.push(option.value);
        }
        this.setState({ checkedID: checkItem });
    }

    // handle language select checkbox 
    _handleSelectLanguage = (selected_id) => {
        let check_language = [...this.state.selectedLanguage];
        if (check_language.includes(selected_id.value)) {
            check_language = check_language.filter(language_id => language_id !== selected_id.value);
        } else {
            check_language.push(selected_id.value);
        }
        this.setState({
            selectedLanguage: check_language
        });
    };

    //add multiple agendas
    _handleAgendaChange = (index, value) => {
        const updatedAgendas = [...this.state.agendas];
        updatedAgendas[index] = value;
        this.setState({ agendas: updatedAgendas });
        console.log('Agendas after change:', updatedAgendas);
    };

    _addMoreAgenda = () => {
        this.setState((prevState) => ({
            agendas: [...prevState.agendas, ''], // Add a new empty agenda
        }));
        console.log('Agendas after adding:', this.state.agendas);
    };

    _removeAgenda = (index) => {
        const updatedAgendas = [...this.state.agendas];
        updatedAgendas.splice(index, 1);
        this.setState({ agendas: updatedAgendas });
    };

    _handleAccountChange = (value) => {
        this.setState({
            selectedZooomAccount: value,
        });
    }
    render() {
        console.log("this.state.checkedID", this.state.checkedID);
        console.log("this.state.selectedLanguage", this.state.selectedLanguage);

        console.log("zoomAccountCategory", this.props.zoomAccountCategory)
        // console.log("zoomAccount", this.state.zoomAccount.value)

        return (

            <>
                <div className={"container text-left e-login e-webinar-product-modal " + this.props.modalClass} id="#addWebinar">
                    <section className="pl-3">
                        <div className="row mb-3 e-modal-border ">
                            <div className="col-lg-10 pt-5">
                                <h5 className="">
                                    {this.props.editData && Object.keys(this.props.editData).length !== 0 ?
                                        "Edit webinar" : "Add new webinar"
                                    }
                                </h5>
                            </div>
                            <div className='col-lg-2 text-right pt-4 mt-3'>
                                <button className="e-login-closebtn">
                                    <img src={Close} alt="close button" className="e-toggle" onClick={this.hideLogin.bind(this)} />
                                </button>
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className='container'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Webinar title*</label>
                                    <Input required={true}
                                        uppercase={false}
                                        placeholder="Enter the title of the webinar"
                                        id="e-wbinar-name"
                                        type="text"
                                        className='mb-2'
                                        error={this.state.nameError}
                                        value={this.state.name}
                                        valueChange={(tempVal) => this.setState({ name: tempVal })
                                        }

                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Thumbnail (1366x768)*</label>

                                    {
                                        this.state.imgLoader == 1 && this.state.uploading ?
                                            <span className="e-img-upload-loader"></span>
                                            :
                                            <React.Fragment>

                                                {
                                                    this.state.showUploadLoader ?
                                                        <div className="e-upload-btn text-center"
                                                            onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                                                            <LazyLoaderGif />
                                                        </div>
                                                        :
                                                        this.state.imageURL && this.state.imageURL.length ?
                                                            <div className="e-upload-btn e-image-preview-wrap text-center" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                                                                <img src={this.state.imageURL} />
                                                            </div>
                                                            :
                                                            <div className="e-upload-btn text-center e-upload-main-btn e-course-edit-upload" onClick={this.triggerClick.bind(this, "#e-logo-upload", 1)}>
                                                                <span>+ Add a banner image</span>
                                                            </div>

                                                }
                                                <ImageUploader id="e-logo-upload"
                                                    error={this.state.imageError}
                                                    uploadSuccess={this.logoUploaded.bind(this)}
                                                    imageSelected={this.imageSelected.bind(this)} />
                                            </React.Fragment>
                                    }

                                </div>

                                <div className="col-lg-12 mt-2">
                                    <label>Webinar preview</label>
                                    <Input
                                        required={true}
                                        uppercase={false}
                                        placeholder="YouTube video URL"
                                        id="e-wbinar-name"
                                        type="text"
                                        className='mb-2'
                                        // error={this.state.linkError}
                                        value={this.state.link}
                                        valueChange={(tempVal) => this.setState({ link: tempVal })
                                        }
                                    />
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-lg-12 e-addwebinar-dropdown e-checkbox-wrapper'>
                                    <label>Category*</label>
                                    <div>
                                        {
                                            this.props.categories.map((option, key) => (
                                                <label key={key}
                                                    className="e-checkbox e-cursor-pointer">
                                                    <input type="checkbox"
                                                        className=""
                                                        checked={this.state.checkedID.includes(option.value)}
                                                        // checked={this.state.checkedID === option.value}
                                                        // onChange={(e) => this.setState({ checkedID: option.value })} 
                                                        onChange={(e) => this._handleCheck(option)}
                                                    />
                                                    <span key={option.value} className="checkmark e-webinar-checkmark">{option.label}</span>
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-lg-12'>
                                    <label className='mb-2 '>Language*</label>
                                    <div>
                                        {
                                            this.props.languages.map((option, key) => (
                                                <label key={key}
                                                    className="e-checkbox e-cursor-pointer">
                                                    <input type="checkbox"
                                                        className=""
                                                        checked={this.state.selectedLanguage.includes(option.value)}
                                                        onChange={() => this._handleSelectLanguage(option)}
                                                    />
                                                    <span key={option.value} className="checkmark e-webinar-checkmark">{option.label}</span>
                                                </label>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-lg-12'>
                                    <label className='mb-2 mt-3'>Segments*</label>
                                    <div className='d-flex'>

                                        <RadioGroup data={this.props.segments}
                                            selectedItem={this.state.selectedSegment} // already selected item /* handler function*/
                                            onSelectItem={this._handleSelectSegment} />
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className="e-filter-wrap col-lg-12">
                                    <label>Zoom account*</label>
                                    <SelectBox className="e-filter"
                                        defaultValue={this.state.selectedZooomAccount}
                                        options={this.props.zoomAccountCategory}
                                        value={this.state.selectedZooomAccount}
                                        selectchange={this._handleAccountChange}
                                    />
                                </div>
                            </div>

                            {
                                this.props.editData && Object.keys(this.props.editData).length !== 0 ?
                                    <div className='row mt-2'>
                                        <div className='col-lg-12'>
                                            <label className='mb-2 mt-3'>Active*</label>
                                            <div className='d-flex'>
                                                <RadioGroup data={WEBINAR_STATUS}
                                                    selectedItem={this.state.selectedActive}
                                                    onSelectItem={this._handleSelectActive} />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {this.props.editData && Object.keys(this.props.editData).length !== 0 ?
                                <div>
                                    {this.props.editData && this.props.editData.type == 2 ?
                                        <div>
                                            <div className='row mt-3 e-disable'>
                                                <div className='col-lg-12'>
                                                    <label>Zoom Meeting Id*</label>
                                                    <Input placeholder="Enter Zoom meeting Id"
                                                        className='mb-2'
                                                        error={this.state.zoomError}
                                                        value={this.state.meeting}
                                                        valueChange={(tempVal) => this.setState({ meeting: tempVal })} />
                                                </div>
                                            </div>

                                            <div className='row mt-2 e-disable'>
                                                <div className='col-lg-12'>
                                                    <label>Agenda*</label>
                                                    {this.state.agendas && this.state.agendas.map((agenda, index) => (
                                                        <div key={index} className="mb-2 position-relative">
                                                            <Input
                                                                id={`agenda-${index}`}
                                                                required={true}
                                                                uppercase={false}
                                                                placeholder="Agenda"
                                                                type="text"
                                                                className="mb-2"
                                                                error={this.state.agendaError.length > 0 && this.state.agendaError.includes(index) ? 'Agenda is required' : null}
                                                                value={agenda}
                                                                valueChange={(tempVal) => this._handleAgendaChange(index, tempVal)}
                                                            />
                                                            {index !== 0 && ( // Conditionally render delete icon for non-default inputs
                                                                <span className="e-delete-agenda-icon">
                                                                    <img className=""
                                                                        src={require("../../../assets/images/delete-icon-red.svg")}
                                                                        width={14}
                                                                        height={14}
                                                                        alt=""
                                                                        onClick={() => this._removeAgenda(index)}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                    ))}

                                                    {/* Display an input box only if no agendas are present */}
                                                    {this.state.agendas.length === 0 && (
                                                        <div className="mb-2">
                                                            <Input
                                                                id={`agenda-0`}
                                                                required={true}
                                                                uppercase={false}
                                                                placeholder="Agenda"
                                                                type="text"
                                                                className="mb-2 mr-2"
                                                                error={this.state.agendaError.length > 0 && this.state.agendaError.includes(0) ? 'Agenda is missing' : null}
                                                                value={this.state.agendas[0]}
                                                                valueChange={(tempVal) => this._handleAgendaChange(0, tempVal)}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="row pt-2 e-disable">
                                                <div className="col-lg-12 text-right">
                                                    <button onClick={this._addMoreAgenda}>+ Add</button>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div>
                                            <div className='row mt-3'>
                                                <div className='col-lg-12'>
                                                    <label>Zoom Meeting Id*</label>
                                                    <Input placeholder="Enter Zoom meeting Id"
                                                        className='mb-2'
                                                        error={this.state.zoomError}
                                                        value={this.state.meeting}
                                                        valueChange={(tempVal) => this.setState({ meeting: tempVal })} />
                                                </div>
                                            </div>


                                            <div className='row mt-2'>
                                                <div className='col-lg-12'>
                                                    <label>Agenda*</label>
                                                    {this.state.agendas && this.state.agendas.map((agenda, index) => (
                                                        <div key={index} className="mb-2 position-relative">
                                                            <Input
                                                                id={`agenda-${index}`}
                                                                required={true}
                                                                uppercase={false}
                                                                placeholder="Agenda"
                                                                type="text"
                                                                className="mb-2"
                                                                error={this.state.agendaError.length > 0 && this.state.agendaError.includes(index) ? 'Agenda is required' : null}
                                                                value={agenda}
                                                                valueChange={(tempVal) => this._handleAgendaChange(index, tempVal)}
                                                            />
                                                            {index !== 0 && ( // Conditionally render delete icon for non-default inputs
                                                                <span className="e-delete-agenda-icon">
                                                                    <img className=""
                                                                        src={require("../../../assets/images/delete-icon-red.svg")}
                                                                        width={14}
                                                                        height={14}
                                                                        alt=""
                                                                        onClick={() => this._removeAgenda(index)}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                    ))}

                                                    {/* Display an input box only if no agendas are present */}
                                                    {this.state.agendas.length === 0 && (
                                                        <div className="mb-2">
                                                            <Input
                                                                id={`agenda-0`}
                                                                required={true}
                                                                uppercase={false}
                                                                placeholder="Agenda"
                                                                type="text"
                                                                className="mb-2 mr-2"
                                                                error={this.state.agendaError.length > 0 && this.state.agendaError.includes(0) ? 'Agenda is missing' : null}
                                                                value={this.state.agendas[0]}
                                                                valueChange={(tempVal) => this._handleAgendaChange(0, tempVal)}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="row pt-2">
                                                <div className="col-lg-12 text-right">
                                                    <button onClick={this._addMoreAgenda}>+ Add</button>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div> :
                                <div>
                                    <div className='row mt-3'>
                                        <div className='col-lg-12'>
                                            <label>Zoom Meeting Id*</label>
                                            <Input placeholder="Enter Zoom meeting Id"
                                                className='mb-2'
                                                error={this.state.zoomError}
                                                value={this.state.meeting}
                                                valueChange={(tempVal) => this.setState({ meeting: tempVal })} />
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-lg-12'>
                                            <label>Agenda*</label>
                                            {this.state.agendas && this.state.agendas.map((agenda, index) => (
                                                <div key={index} className="mb-2 position-relative">
                                                    <Input
                                                        id={`agenda-${index}`}
                                                        required={true}
                                                        uppercase={false}
                                                        placeholder="Agenda"
                                                        type="text"
                                                        className="mb-2"
                                                        error={this.state.agendaError.length > 0 && this.state.agendaError.includes(index) ? 'Agenda is required' : null}
                                                        value={agenda}
                                                        valueChange={(tempVal) => this._handleAgendaChange(index, tempVal)}
                                                    />
                                                    {index !== 0 && ( // Conditionally render delete icon for non-default inputs
                                                        <span className="e-delete-agenda-icon">
                                                            <img className=""
                                                                src={require("../../../assets/images/delete-icon-red.svg")}
                                                                width={14}
                                                                height={14}
                                                                alt=""
                                                                onClick={() => this._removeAgenda(index)}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            ))}

                                            {/* Display an input box only if no agendas are present */}
                                            {this.state.agendas.length === 0 && (
                                                <div className="mb-2">
                                                    <Input
                                                        id={`agenda-0`}
                                                        required={true}
                                                        uppercase={false}
                                                        placeholder="Agenda"
                                                        type="text"
                                                        className="mb-2 mr-2"
                                                        error={this.state.agendaError.length > 0 && this.state.agendaError.includes(0) ? 'Agenda is missing' : null}
                                                        value={this.state.agendas[0]}
                                                        valueChange={(tempVal) => this._handleAgendaChange(0, tempVal)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="row pt-2">
                                        <div className="col-lg-12 text-right">
                                            <button onClick={this._addMoreAgenda}>+ Add</button>
                                        </div>
                                    </div>

                                </div>
                            }

                            <div className='row mt-2 e-wisywig-editor'>
                                <div className='col-lg-12'>
                                    <label>Description*</label>
                                    <Editor
                                        value={this.state.editorValue}
                                        error={this.state.descriptionError}
                                        editorChange={this.handleEditorChange} />

                                </div>
                            </div>

                            <div className="mt-2">
                                <label>Enrolled*</label>
                                <Input
                                    required={true}
                                    uppercase={false}
                                    placeholder="Enrolled users"
                                    id="e-wbinar-name"
                                    type="number"
                                    className='mb-2'
                                    error={this.state.enrollError}
                                    value={this.state.enroll}
                                    valueChange={(tempVal) => this.setState({ enroll: tempVal })
                                    }
                                />
                            </div>
                            {this.props.editData && Object.keys(this.props.editData).length !== 0 ?
                                <div>
                                    {this.props.editData && this.props.editData.type == 2 ?
                                        <>
                                            <div className='row mt-2 gap-12px '>
                                                <div className="col-lg-2 e-release-date e-full-width e-disable">
                                                    <label>Date*</label>
                                                    {/* <DatePicker value={this.state.release_date}
                                                    error={this.state.dateError}
                                                    // dateChange={(e) => this.setState({ release_date: e })} 
                                                    dateChange={(e) => {
                                                        console.log("New Date Selected:", e);
                                                        this.setState({ release_date: e });
                                                    }}

                                                /> */}
                                                    <DatePicker value={this.state.release_date}
                                                        defaultValue={this.props.editData ? this.props.editData.scheduled_date : null}
                                                        error={this.state.dateError}
                                                        dateChange={(e) => this.setState({ release_date: e })} />

                                                </div>

                                                <div className="col-lg-2 pb-3 e-release-time-offer e-disable">
                                                    <label>Time*</label>
                                                    <div className='e-webinar-time-picker'>

                                                        <TimePickerComponent
                                                            placeholder="Time"
                                                            showSecond={true}
                                                            showHour={true}
                                                            showMinute={true}
                                                            use12Hours={true}
                                                            time={this.state.webinarTime}
                                                            error={this.state.webinarTimeError}
                                                            onChange={(e) => { this.setState({ webinarTime: e }); }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='pb-3 e-disable'>
                                                <label>Duration*</label>
                                                <TimePickerComponent
                                                    placeholder="Duration"
                                                    showSecond={true}
                                                    showHour={true}
                                                    showMinute={true}
                                                    use12Hours={false}
                                                    time={this.state.duration}
                                                    // time={'0:09:49'}
                                                    error={this.state.durationError}
                                                    onChange={(newTime) => {
                                                        this.setState({ duration: newTime, error: "" });
                                                        console.log("Selected Duration:", newTime);
                                                    }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='row mt-2 gap-12px'>
                                                <div className="col-lg-2 e-release-date e-full-width">
                                                    <label>Date*</label>
                                                    {/* <DatePicker value={this.state.release_date}
                                                    error={this.state.dateError}
                                                    // dateChange={(e) => this.setState({ release_date: e })} 
                                                    dateChange={(e) => {
                                                        console.log("New Date Selected:", e);
                                                        this.setState({ release_date: e });
                                                    }}

                                                /> */}
                                                    <DatePicker value={this.state.release_date}
                                                        defaultValue={this.props.editData ? this.props.editData.scheduled_date : null}
                                                        error={this.state.dateError}
                                                        dateChange={(e) => this.setState({ release_date: e })} />

                                                </div>

                                                <div className="col-lg-2 pb-3 e-release-time-offer">
                                                    <label>Time*</label>
                                                    <div className='e-webinar-time-picker'>

                                                        <TimePickerComponent
                                                            placeholder="Time"
                                                            showSecond={true}
                                                            showHour={true}
                                                            showMinute={true}
                                                            use12Hours={true}
                                                            time={this.state.webinarTime}
                                                            error={this.state.webinarTimeError}
                                                            onChange={(e) => { this.setState({ webinarTime: e }); }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='pb-3'>
                                                <label>Duration*</label>
                                                <TimePickerComponent
                                                    placeholder="Duration"
                                                    showSecond={true}
                                                    showHour={true}
                                                    showMinute={true}
                                                    use12Hours={false}
                                                    time={this.state.duration}
                                                    // time={'0:09:49'}
                                                    error={this.state.durationError}
                                                    onChange={(newTime) => {
                                                        this.setState({ duration: newTime, error: "" });
                                                        console.log("Selected Duration:", newTime);
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                <>

                                    <div className='row mt-2 gap-12px '>
                                        <div className="col-lg-2 e-release-date e-full-width">
                                            <label>Date*</label>
                                            {/* <DatePicker value={this.state.release_date}
                                            error={this.state.dateError}
                                            // dateChange={(e) => this.setState({ release_date: e })} 
                                            dateChange={(e) => {
                                                console.log("New Date Selected:", e);
                                                this.setState({ release_date: e });
                                            }}

                                        /> */}
                                            <DatePicker value={this.state.release_date}
                                                defaultValue={this.props.editData ? this.props.editData.scheduled_date : null}
                                                error={this.state.dateError}
                                                dateChange={(e) => this.setState({ release_date: e })} />

                                        </div>

                                        <div className="col-lg-2 pb-3 e-release-time-offer">
                                            <label>Time*</label>
                                            <div className='e-webinar-time-picker'>

                                                <TimePickerComponent
                                                    placeholder="Time"
                                                    showSecond={true}
                                                    showHour={true}
                                                    showMinute={true}
                                                    use12Hours={true}
                                                    time={this.state.webinarTime}
                                                    error={this.state.webinarTimeError}
                                                    onChange={(e) => { this.setState({ webinarTime: e }); }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className='pb-3'>
                                        <label>Duration*</label>
                                        <TimePickerComponent
                                            placeholder="Duration"
                                            showSecond={true}
                                            showHour={true}
                                            showMinute={true}
                                            use12Hours={false}
                                            time={this.state.duration}
                                            // time={'0:09:49'}
                                            error={this.state.durationError}
                                            onChange={(newTime) => {
                                                this.setState({ duration: newTime, error: "" });
                                                console.log("Selected Duration:", newTime);
                                            }}
                                        />
                                    </div>
                                </>
                            }

                            {this.props.editData && this.props.editData.type == 2 ?
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label>Webinar URL</label>
                                        <Input
                                            required={false}
                                            uppercase={false}
                                            placeholder="Enter the Webinar URL"
                                            id="e-wbinar-name"
                                            type="text"
                                            className='mb-2'
                                            error={this.state.webinarUrlError}
                                            value={this.state.webinarUrl}
                                            valueChange={(tempVal) => this.setState({ webinarUrl: tempVal })
                                            }

                                        />
                                    </div>

                                    <div className="col-lg-12">
                                        <label>Webinar Passcode</label>
                                        <Input
                                            required={false}
                                            uppercase={false}
                                            placeholder="Enter the passcode"
                                            id="e-wbinar-name"
                                            type="text"
                                            className='mb-2'
                                            error={this.state.passcodeError}
                                            value={this.state.passcode}
                                            valueChange={(tempVal) => this.setState({ passcode: tempVal })
                                            }

                                        />
                                    </div>
                                </div> : null
                            }

                            <div className='row my-5'>
                                <div className='col-lg-6'>
                                    <span className="pt-2 text-danger">{this.state.errorMessage}</span>
                                </div>
                                <div className='col-lg-6 text-right'>
                                    <span className="e-s-link-txt mr-4" onClick={this.hideLogin.bind(this)}>Cancel</span>
                                    {/* <button
                                        type="submit"
                                        form="e-login-form"
                                        value="Submit"
                                        className='e-add-btn'
                                        onClick={this._validateSubmit}>
                                        Add webinar
                                    </button> */}

                                    {this.props.editData && Object.keys(this.props.editData).length !== 0 ?
                                        <button
                                            type="submit"
                                            form="e-login-form"
                                            value="Submit"
                                            className='e-add-btn'
                                            onClick={this._validateSubmit}>
                                            Save Webinar
                                        </button> :
                                        <button
                                            type="submit"
                                            form="e-login-form"
                                            value="Submit"
                                            className='e-add-btn'
                                            onClick={this._validateSubmit}>
                                            Create Webinar
                                        </button>
                                    }

                                </div>
                            </div>
                        </div>

                    </section >

                </div >
                {
                    this.state.toast ?
                        <Toast data={this.state.message} type={this.state.type} />
                        :
                        null
                }
                {
                    this.props.modalClass.length ?
                        <div className="e-login-overlay" id="e-log-overlay" onClick={this.hideLogin.bind(this)} ></div>
                        :
                        null
                }
            </>

        );
    }
}


export default AddWebinar;
