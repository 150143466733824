/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import Pdf from "react-to-pdf";

/*Components*/

/*Assets*/
import logo from '../../../assets/images/logo.svg';

const ref = React.createRef();

class DetailInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
      
  }

  render() {
    var data = this.props.data;
    return (
      <div className="modal fade e-detail-invoice-wrap" id="invoiceDetailModal" tabIndex="-1" role="dialog" aria-labelledby="invoiceDetailModalLabel" aria-hidden="true">
        <div className="modal-dialog"  role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pb-5" ref={ref}>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <img className="e-invoice-logo" src={logo} alt="truekhoj logo"/>
                </div>
                <div className="col-lg-4 offset-lg-2">
                  <h5 className="mb-3">Receipt</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h6 className="mb-2">Alice Blue Pvt Ltd</h6>
                  <p className="mb-1 ">ALICEBLUE COMMODITIES PVT LTD, 1865, 2nd floor, subam clinic building, Trichy Rd, Ramanathapuram, Coimbatore, Tamil Nadu 641045</p>
                  <p>GST: HGFHH454475</p>
                </div>
                <div className="col-lg-4 offset-lg-2">
                  <p className="mb-0"><b>Invoice no:</b> {data.invoice_no}</p>
                  <p><b>Invoice date:</b> {data.purchased_on}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <h4>Billing to: <b>{data.user_name}</b></h4>
                </div>
              </div>

              <div className="row mt-5 pb-2 e-price-table-header">
                <div className="col-lg-6">
                  <h4 className="m-0">Course</h4>
                </div>
                <div className="col-lg-2">
                  <h4 className="m-0">Quantity</h4>
                </div>
                <div className="col-lg-2">
                  <h4 className="m-0">Price</h4>
                </div>
                <div className="col-lg-2">
                  <h4 className="m-0">Amount</h4>
                </div>
              </div>

              <div className="row mt-4 e-price-table-item">
                <div className="col-lg-6">
                  <p>{data.course_name}</p>
                </div>
                <div className="col-lg-2">
                  <p>1</p>
                </div>
                <div className="col-lg-2">
                  <p>{data.offer_price}</p>
                </div>
                <div className="col-lg-2">
                  <p>{data.offer_price}</p>
                </div>
              </div>

              <div className="row">
                <div className="offset-lg-8 col-lg-4 mt-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <h6>Subtotal:</h6>
                    </div>
                    <div className="col-lg-6">
                      <p>{data.offer_price}</p>
                    </div>
                  </div>
                  {
                    data.igst ? null 
                    :
                      <div className="row">
                        <div className="col-lg-6">
                          <h6>SGST:</h6>
                        </div>
                        <div className="col-lg-6">
                          <p>{data.sgst}</p>
                        </div>
                      </div>

                  }

                  {
                    data.igst ? null 
                    :
                      <div className="row">
                        <div className="col-lg-6">
                          <h6>CGST:</h6>
                        </div>
                        <div className="col-lg-6">
                          <p>{data.cgst}</p>
                        </div>
                      </div>

                  }

                  {
                    data.igst ?
                      <div className="row">
                        <div className="col-lg-6">
                          <h6>IGST:</h6>
                        </div>
                        <div className="col-lg-6">
                          <p>{data.igst}</p>
                        </div>
                      </div>
                    : null   
                  }
                      
                  
                  <div className="row e-price-table-total pt-3 pb-5">
                    <div className="col-lg-6">
                      <h6>Total</h6>
                    </div>
                    <div className="col-lg-6">
                      <p>{data.total}</p>
                    </div>
                  </div>
                  <span className="e-price-table-fully-paid">Fully Paid</span>
                </div>  
                
                  
              </div>

            </div>
            <div className="modal-footer">
            <Pdf targetRef={ref} filename="Invoice.pdf">
              {({ toPdf }) => <button className="e-s-btn" onClick={toPdf}>Download as pdf</button>}
            </Pdf>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailInvoice;
