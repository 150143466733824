
/*import package*/
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Webinar from '../../assets/images/cards/webinar.png'

const WebinarCard = (props) => {

    const [states, setStates] = useState(false);

    useEffect(() => {
        console.log('props::', props.data)
    }, [props.checked])

    return (
        <>
            {props.data && props.data.map((item, key) => (
                <div className='col-lg-4 mb-3'>
                    <Link to={`/webinar-detail?id=${item.id}`}>

                        <div className="card e-webinarcard">
                            <img src={item.thumbnail} className="card-img-top " alt="thumbnail" />
                            {/* <span className='e-checkbx-span'>
                    <input type="checkbox" checked={states} style={{ cursor: "pointer" }} />
                    <span className="checkmark"></span>
                    </span> */}

                            <div className="card-body">
                                <h5 className="card-title e-ellipse">{item.name}</h5>
                                <p className="card-text">
                                    {item.category_ids&&item.category_ids.map((category, index) => (
                                        <>
                                            <span key={index}>
                                                {category.category}
                                            </span>
                                            <span className='e-category-separator px-1'>.</span>
                                        </>
                                    ))}
                                </p>
                                <p className="card-text">Zoom ID:
                                    <span className='pl-1'>{item.zoom_meeting_id}</span>
                                </p>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <h6>Language:</h6>
                                        <h4>{item.language}</h4>
                                    </div>
                                    <div className='col-lg-4'>
                                        <h6>Duration:</h6>
                                        <h4 >{item.duration}</h4>
                                    </div>
                                    <div className='col-lg-4'>
                                        <h6>Scheduled:</h6>
                                        <h4>{item.scheduled_date}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link >
                </div>
            ))}
        </>



    );
}
export default WebinarCard;

