/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

/*Components*/
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import Upcoming from './upcoming';
import AllVideos from './allVideos';
import AllCourses from './allCourses';
import MuteModal from '../../components/modals/mute';
import UnMuteModal from '../../components/modals/unmute';
import RemoveCreator from '../../components/modals/removeCreator';
import EditIncentive from '../../components/modals/editIncentive';
import NothingFound from '../../components/common/nothingFound';
import EditCreator from '../../components/modals/editCreator';
import SelectBox from '../../components/common/selectBox';
import Toast from '../../components/toast/toast';
import PayModal from '../../components/modals/course/paymentModal';
/*Assets*/
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';
import courseCover from '../../assets/images/course-cover.jpg';
import profile from '../../assets/images/profile.png';

class CreatorDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: null,
      data: null,
      upcomingTab: true,
      dataLoading: false,
      notFound: false,
      tabActive: 1,
      moreActive: false,
      filterTypeDefault: {value: null, label: 'All Type'},
      filterStatusDefault: {value: 1, label: 'Published'},
      payNowItem: null
    }
  }

  componentDidMount() {
    var query = this.getUrlVars();
    this.setState({ id: query.id });
    this.profileApi(query.id);
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  profileApi(tempId){
    this.setState({ dataLoading: true});
    var url = devconfig.admin+"/creator/profile";
    var data = JSON.stringify({
      "creator_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ data: response.data, dataLoading: false});
      }
      else{
        this.setState({ data: null, dataLoading: false, notFound: true});
      }
    }.bind(this))
  }
  
  filterChange = async (filter) => {
    await this.setState({filter: filter.value});
    this.creatorsListApi(this.state.pageNum, filter.value, this.state.sort);
  }

  sortChange = async (sort) => {
    await this.setState({sort: sort.value});
    this.creatorsListApi(this.state.pageNum, this.state.filter, sort.value);
  }

  abbreviateNumber(num){
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
  }

  tabClick(tempStatus){
    this.setState({upcomingTab: tempStatus});
  }

  muteClick(tempStatus){
    var url = devconfig.admin+"/creator/mute-or-unmute";
    var data = JSON.stringify({
      "creator_id" : this.state.id,
      "mute" : tempStatus
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.profileApi(this.state.id);
        $('#muteModal').modal('hide');
        $('#unmuteModal').modal('hide');


      }
    }.bind(this))
  }

  removeCreatorClick(){
    var url = devconfig.admin+"/creator/remove-creator";
    var data = JSON.stringify({
      "creator_id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        window.location.href = '/';
      }
    }.bind(this))
  }

  tabItemClick(tempActive){
    this.setState({tabActive: tempActive});
  }

  async moreClick(){
    await this.setState({moreActive: !this.state.moreActive});
  }

  payNow(temp){
    this.setState({ payNowLoading: true, payNowItem: this.state.id});
    var url = devconfig.admin+"/creator/pay-now";
    var data = JSON.stringify({
      "creator_id" : this.state.id,
      "amount": temp
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.creatorsListApi(1, null, 1);
        this.setState({payNowLoading: false, payNowItem: null});
        this.setState({toast: response.message,  toastType:"success"})
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);
      }
      else{
        this.setState({toast: response.message,  toastType:"fail"})
        setTimeout( function() { this.setState({ toast: null}); }.bind(this), 4000);

        this.setState({ errorMessage: response.message, payNowLoading: false, payNowItem: null});
      }
    }.bind(this))
  }

  profileEdit(){
    this.profileApi.bind(this.state.id)
    this.setState({moreActive: !this.state.moreActive});
  }

  render() {
    if(this.state.data){
      var tempLink = "https://www.youtube.com/channel/"+this.state.data.channel_id;
    }

    const filterByType = [
      { value: null, label: 'All type' },
      { value: 1, label: 'Free' },
      { value: 2, label: 'Paid' },
    ]
    const filterByTutor = [
      { value: null, label: 'All tutors' },
      { value: 1, label: 'Basil PV' },
      { value: 2, label: 'Richard Sebastian' },
      { value: 3, label: 'Nikola Tesla' },
    ]
    const filterByTypeStatus = [
      { value: null, label: 'All status' },
      { value: 1, label: 'Published' },
      { value: 2, label: 'Unpublished' },
    ]
    
    if(this.state.notFound){
      return(
        <div className="e-main-content">
          <div className="row">
            <div className="col-lg-12 text-center">
              <NothingFound data="No data found !"/>
            </div>
          </div>
        </div>
      )
    }
    else{
      return (
        <div className="e-main-content e-invites-wrap">
          <h2 className="d-inline-block">Tutor Detail</h2>
          {/*<div className="row">
            <div className="col-lg-4">
              <a className="inline-block" href="/creators">
                <span className="e-back-btn">Back to Creators</span>
              </a>
            </div>
          </div>*/}

          <div className="row mb-3">
            <div className="col-lg-10">
              {
                this.state.tabActive === 1 ?
                  <div className="e-tab-title-wrap">
                    <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 1)}>Tutor Info</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>Upcoming Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 3)}>Completed Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 4)}>All Courses</span>
                  </div>
                :
                  null
              }

              {
                this.state.tabActive === 2 ?
                  <div className="e-tab-title-wrap">
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Tutor Info</span>
                    <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 2)}>Upcoming Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 3)}>Completed Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 4)}>All Courses</span>
                  </div>
                :
                  null
              }

              {
                this.state.tabActive === 3 ?
                  <div className="e-tab-title-wrap">
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Tutor Info</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>Upcoming Webinars</span>
                    <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 3)}>Completed Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 4)}>All Courses</span>
                  </div>
                :
                  null
              }

              {
                this.state.tabActive === 4 ?
                  <div className="e-tab-title-wrap">
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Tutor Info</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>Upcoming Webinars</span>
                    <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 3)}>Completed Webinars</span>
                    <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 4)}>All Courses</span>
                  </div>
                :
                  null
              }
            </div>
            <div className="col-lg-2 text-right">
              <a className="inline-block" onClick={()=>window.history.back()}>
                <span className="e-back-btn">Back to Creators</span>
              </a>
            </div>
          </div>
          {
            this.state.tabActive === 1 ?
              <div>
                {
                  !this.state.dataLoading ?
                    <div className="row">
                      <div className="col-lg-12 mt-4">
                        {
                          this.state.data ?
                            <div className="e-tutor-tab-content">
                              <div className="row">
                                <div className="col-lg-1">
                                  {
                                    this.state.data.image ?
                                      <img className="e-profile" src={this.state.data.image} alt="Profile"/>
                                    :
                                      <img className="e-profile" src={profPlaceholder} alt="Profile"/>
                                  }
                                </div>
                                <div className="col-lg-11">
                                  <span className="e-more-icon" onClick={this.moreClick.bind(this)}></span>

                                  {
                                    this.state.moreActive ?
                                      <span className="e-tutor-more">
                                        <div className="e-tutor-more-item" data-toggle="modal" data-target="#editCreatorModal">Edit details</div>
                                        {
                                          this.state.data.muted ?
                                            <div className="e-tutor-more-item" data-toggle="modal" data-target="#unmuteModal">Un-mute </div>
                                          :
                                            <div className="e-tutor-more-item" data-toggle="modal" data-target="#muteModal">Mute tutor</div>
                                        }
                                        <div className="e-tutor-more-item" data-toggle="modal" data-target="#removeCreatorModal">Remove tutor</div>
                                        <div className="e-tutor-more-item mb-0" data-toggle="modal" data-target="#editIncentiveModal">Edit incentive</div>
                                      </span>
                                    :
                                      null
                                  }

                                  <div className="row">
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Full name</span>
                                      <p>{this.state.data.name}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Product code</span>
                                      <p>{this.state.data.code}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Mobile number</span>
                                      <p>{this.state.data.mobile ? this.state.data.mobile : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Email ID</span>
                                      <p>{this.state.data.email}</p>
                                    </div>
                                    
                                    <div className="col-lg-8">
                                      <span className="e-dull-text">Brief about him</span>
                                      <p>{this.state.data.description}</p>
                                    </div>
                                    <div className="col-lg-4">
                                      <span className="e-dull-text">Designation</span>
                                      <p>{this.state.data.designation}</p>
                                    </div>
                                  </div>
                                  <div className="row e-bank-details">
                                    <div className="col-lg-12">
                                      <hr/>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Benefeciary name</span>
                                      <p>{this.state.data.beneficiary_name ? this.state.data.beneficiary_name : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Bank name</span>
                                      <p>{this.state.data.bank ? this.state.data.bank : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Branch name</span>
                                      <p>{this.state.data.branch ? this.state.data.branch : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Account number</span>
                                      <p>{this.state.data.account_no ? this.state.data.account_no : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">IFSC code</span>
                                      <p>{this.state.data.ifsc_code ? this.state.data.ifsc_code : "--"}</p>
                                    </div>
                                  </div>

                                  <div className="row e-bank-details">
                                    <div className="col-lg-12">
                                      <hr/>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Total webinars</span>
                                      {
                                        this.state.data.video_count ?
                                          <p>{this.state.data.video_count}</p>
                                        :
                                          <p>--</p>
                                      }
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Webinar views</span>
                                      
                                      {
                                        this.state.data.views ?
                                          <p>{this.abbreviateNumber(this.state.data.views)}</p>
                                        :
                                          <p>--</p>
                                      }
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Webinar last activity</span>
                                      <p>{this.state.data.last_activity}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Action</span>
                                      {
                                        this.state.data.channel_id ?
                                          <p className="e-channel-link"><a href={tempLink} target="_blank">View Channel</a></p>
                                        :
                                          <p>--</p>
                                      }
                                      
                                    </div>
                                  </div>

                                  <div className="row e-course-details">
                                    <div className="col-lg-12">
                                      <hr/>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Total courses</span>
                                      <p>{this.state.data.total_courses ? this.state.data.total_courses : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Total entrolements</span>
                                      <p>{this.state.data.total_enrollments ? this.state.data.total_enrollments : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Incentive percentage</span>
                                      <p>{this.state.data.incentive_percentage}%</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Total paid incentive</span>
                                      <p>{this.state.data.total_paid_incentive ? "₹ "+this.state.data.total_paid_incentive : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Pending incentive</span>
                                      <p>{this.state.data.pending_incentive ? "₹ "+this.state.data.pending_incentive : "--"}</p>
                                    </div>
                                    <div className="col-lg-3">
                                      <span className="e-dull-text">Action</span>
                                      {
                                        this.state.data.pending_incentive ?
                                          <React.Fragment>
                                            {
                                              this.state.payNowLoading  ?
                                                <p className="e-creator-dull-txt e-paynow-txt">
                                                  <LazyLoaderGif/>
                                                </p>
                                              :
                                                <p className="e-channel-link e-paynow-txt" data-toggle="modal" data-target="#payModal">Pay now</p>
                                            }
                                          </React.Fragment>

                                        :
                                          <td className="e-creator-dull-txt">No pending payments</td>
                                      }
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          :
                            null
                        }
                        
                      </div>
                    </div>
                  :
                    <div className="row">
                      <div className="col-lg-12 mt-4">
                        <div className="e-detail-profile-wrap e-profile-placeholder text-center pt-5">
                           <LazyLoaderGif/>
                        </div>
                      </div>
                    </div>
                }
              </div>
            :
              null
          }
          {
            this.state.tabActive === 2 ?
              <div className="row">
                <div className="col-lg-12">
                  <Upcoming creator_id={this.state.id}/>
                </div>
              </div>
            :
              null
          }

          {
            this.state.tabActive === 3 ?
              <div className="row">
                <div className="col-lg-12">
                  <AllVideos creator_id={this.state.id}/>
                </div>
              </div>
            :
              null
          }

          {
            this.state.tabActive === 4 ?
              <div className="row">
                <div className="col-lg-12">
                  <AllCourses creator_id={this.state.id}/>
                </div>
              </div>
            :
              null
          }

          
          {/* Modal */}

          {
            this.state.data ?
              <div>
                <MuteModal name={this.state.data.name} success={this.muteClick.bind(this, true)}/>
                <UnMuteModal name={this.state.data.name} success={this.muteClick.bind(this, false)}/>
                <RemoveCreator name={this.state.data.name} success={this.removeCreatorClick.bind(this)}/>
                <EditCreator success={this.profileEdit.bind(this)} data={this.state.data}/> 
                <EditIncentive data={this.state.data.incentive_percentage} id={this.state.data.id}/>
              </div>
            :
              null
          }

          {/* Overlay */}

          {
            this.state.moreActive ?
              <span className="e-more-overlay" onClick={this.moreClick.bind(this)}></span>
            :
              null
          }
          {
            this.state.toast ?
              <Toast data={this.state.toast} type={this.state.toastType}/>
            :
            null
           }

           <PayModal save={(temp)=>{this.payNow(temp)}}/>
        </div>
      );
    }
  }
}

export default CreatorDetail;
