/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import Textarea from '../../components/formComponents/textarea';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';

/*Assets*/

class EditCreator extends Component {
  constructor(props){
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      link: "",
      code: "",
      apiLoading: false,
      designation: "",
      description: ""
    }
  }

  async buttonClick(){
    this.setState({apiLoading: true});
    var validationSuccess = true;

    // await EmailValidation(this.state.email).then(function(response){
    //   if(!response){
    //     this.setState({errorMessage: "Invalid email address, please check and retry.", apiLoading: false});
    //     validationSuccess = false;
    //   }
    // }.bind(this))

    // var tempGmailSplit = this.state.email.split("@");

    // if(tempGmailSplit[tempGmailSplit.length-1].indexOf("gmail.com") === -1){
    //   this.setState({errorMessage: "Invalid Gmail Address", apiLoading: false});
    //   validationSuccess = false;
    // }

    // if(this.state.link.indexOf("youtube.com/channel") === -1 ){
    //   this.setState({errorMessage: "Invalid Youtube URL", apiLoading: false});
    //   validationSuccess = false;
    // }

    if(validationSuccess){
      var url = devconfig.admin + "/creator/edit-profile";
      var data = JSON.stringify({
        "name" : this.state.name,
        "code" : this.state.code.toUpperCase(),
        "creator_id": this.state.id,
        "designation": this.state.designation,
        "description": this.state.description
      })
      APIPost(url, data).then(function(response){
        if(response.status){
          $('#editCreatorModal').modal('hide');
          
          this.setState({apiLoading: false});
          this.props.success();
        }
        else{
          this.setState({errorMessage: response.message, apiLoading: false});
        }
      }.bind(this))
    } 
  }

  componentDidMount(){

    $('#editCreatorModal').on('shown.bs.modal', () => {
      // alert("focused")
      var data = this.props.data;
      if(data){
        this.setState({
          mobile: data.mobile,
          name: data.name,
          code: data.code,
          id: data.id,
          designation: data.designation,
          description: data.description
        })
      }
    })
  }

  render() {
    return (
      <div className="modal fade" id="editCreatorModal" tabIndex="-1" role="dialog" aria-labelledby="editCreatorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Creator</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="e-modal-s-txt">Fill out the details of the creator.</div>
            <div className="modal-body mt-3">
              <Input label="Name" id="u-name" type="text" value={this.state.name} valueChange={(tempVal) => this.setState({name: tempVal})} />
              <Input label="Product Code" id="u-code" type="text" value={this.state.code} valueChange={(tempVal) => this.setState({code: tempVal})} />
              <Input label="Designation" id="u-designation" type="text" value={this.state.designation} valueChange={(tempVal) => this.setState({designation: tempVal})} />
              <Textarea label="Brief about you" id="u-brief" type="text" value={this.state.description} valueChange={(tempVal) => this.setState({description: tempVal})} />
              <div>
                <span className="pt-2 text-danger">{this.state.errorMessage}</span>
              </div>
            </div>
           
            <div className="modal-footer">
              <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                {
                  this.state.apiLoading ?
                    <span className="e-api-loader"></span>
                  :
                    <span>
                      {
                        (this.state.name && this.state.name.length) && (this.state.code && this.state.code.length) && (this.state.designation && this.state.designation.length) && this.state.description && !this.state.apiLoading ?
                          <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Verify & Update</button>
                        :
                          <button className="e-inactive" type="submit" form="e-login-form" value="Submit">Verify & Update</button>
                      }
                    </span>
                }     
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default EditCreator;
