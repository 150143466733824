import React, { Component } from 'react';
import parse from 'html-react-parser';

class  ReadMoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }


    render() {
        
        return (
            <div className="modal fade" id="readmoreWebinarModal" tabIndex="-1" role="dialog" aria-labelledby="readmoreModalModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.detailData.name}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body e-editor-description">
                          {parse(this.props.detailData.description)}
                        </div>
                        <div className="modal-footer">
                            <span className="e-s-link-txt mr-4" data-dismiss="modal">Cancel</span>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
export default ReadMoreModal;