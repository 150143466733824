import S3FileUpload from 'react-s3';
import { uploadFile } from 'react-s3';

export default async function UploadImageFileAWS(fileName, path, file, fileType) {
    console.log(fileName, path, file, fileType)
    // https://aliceblue-tradeschool.s3.ap-south-1.amazonaws.com/2.png
    const config = {
        bucketName: 'aliceblue-tradeschool-us-east',
        dirName: path,
        region: 'us-east-1',
        accessKeyId: 'AKIAJCOMS6ZFEV3PVT4Q',
        secretAccessKey: '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',
        s3Url: 'https://aliceblue-tradeschool.s3.amazonaws.com',
    };

    return uploadFile(file, config).then(
        function(response) {
            console.log(response)
            return response.location;
        }.bind(this)
    ).catch(err => console.error(err))
}
