/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../../components/formComponents/input';
import devconfig from '../../config/config.js';


/*Assets*/
import logo from '../../assets/images/logo.svg';
import loginHero from '../../assets/images/login-hero.svg';


class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      apiLoading: false
    }
  }

  buttonClick(e){
    e.preventDefault();
    this.setState({apiLoading: true});

    var tempEncodeData = this.state.username.concat(":").concat(this.state.password);
    var encodedData = btoa(tempEncodeData);
    $.ajax({
      type: "GET",
      url: devconfig.admin + '/auth/login',
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Basic " + encodedData,
      }
    }).then(function (response) {
      if(response.status){
        localStorage.setItem("u_data", JSON.stringify(response.data));
        localStorage.setItem("u_token", response.access_token);
        window.location.href = '/';
      }
      else{
        this.setState({errorMessage: response.message, apiLoading: false});
      }
    }.bind(this)).fail((error)=>{
      this.setState({errorMessage: "Network error", apiLoading: false});
    })
  }

  render() {
    return (
      <section className="e-login-section">
        <div className="container-fluid">
          <div className="row e-login-logo-wrap">
            <div className="col-lg-12">
              <img src={logo} alt="AliceBlue Tradeschool logo"/>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 pt-5">
            <div className="offset-lg-2 col-lg-2  e-login-img">
              <img src={loginHero} alt="Login hero"/>
            </div>
            <div className="col-lg-6 e-login-wrap">
              <h2>Login to continue</h2>

              <form id="e-login-form">
                <div className="pt-4">
                  <Input label="Username" id="u-name" type="email" defaultValue={this.state.username} valueChange={(tempVal) => this.setState({username: tempVal})} />
                  <Input label="Password" id="u-name" type="password" defaultValue={this.state.password} valueChange={(tempVal) => this.setState({password: tempVal})} />
                </div>
                <div className="d-flex justify-content-between">
                  <span className="pt-2 text-danger">{this.state.errorMessage}</span>
                  {
                    this.state.username.length && this.state.password && !this.state.apiLoading ?
                      <button type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Login</button>
                    :
                      <button className="e-inactive" type="submit" form="e-login-form" value="Submit" onClick={this.buttonClick.bind(this)}>Login</button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

export default Login;
