/*Package importing*/
import React, { Component } from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

/*Components*/
import SearchInput from '../../components/common/search.js';
import SelectBox from '../../components/common/selectBox';
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import LazyLoaderGif from '../../components/common/lazyloader';
import NothingFound from '../../components/common/nothingFound';
import Datepicker from '../../components/common/datepicker';
import DetailInvoice from '../../components/modals/invoice/invoiceDetail';

/*Assets*/
import profile from '../../assets/images/profile.png';
import profPlaceholder from '../../assets/images/profile-placeholder.jpg';



class InvoiceListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      sort: 1,
      filterDefault: { value: null, label: 'All course tutors' },
      creatorFilter: { value: null, label: 'Tutor name' },
      stateDataFilter: { value: null, label: 'State' },
      search: "",
      data: [],
      sortBy: [],
      courseData: [],
      pageNum: 1,
      pageNum2: 1,
      searchEnable: false,
      moreOpen: 0,
      muteLoading: false,

      lazyDataEnd: true,
      lazyDataEnd2: true,
      lazyLoadStatus: false,
      lazyLoadStatus2: false,
      dataLoading: false,
      dataLoading2: false,
      tabActive: 1,
      invoiceData: {}
    }
  }

  componentDidMount() {
    this.getData(1, null, null, {});
    this.downloadPaymnetGiven(this.state.creatorFilter.value, this.state.filter);

  }

  filterChange = async (filter) => {
    await this.setState({ filter: filter.value });
    this.getData(this.state.pageNum, filter.value, this.state.sort);
    this.downloadPaymnetGiven(this.state.creatorFilter.value, filter.value);
  }

  sortChange = async (creatorData) => {
    await this.setState({ creatorFilter: creatorData });
    this.getData(this.state.pageNum, this.state.filter, creatorData.value);
    this.downloadPaymnetGiven(creatorData.value, this.state.filter);
  }

  sortChange2 = async (creatorData) => {

    await this.setState({ creatorFilter: creatorData });
    this.getOtherData(this.state.pageNum2, this.state.stateDataFilter.value, creatorData.value, { startDate: this.state.startDate, endDate: this.state.endDate });
    this.downloadPaymnetReceived(this.state.stateDataFilter.value, creatorData.value, { startDate: this.state.startDate, endDate: this.state.endDate });
  }

  stateChange = async (stateDataFilter) => {
    await this.setState({ stateDataFilter: stateDataFilter });
    this.getOtherData(this.state.pageNum2, stateDataFilter.value, this.state.creatorFilter.value, { startDate: this.state.startDate, endDate: this.state.endDate });
    this.downloadPaymnetReceived(stateDataFilter.value, this.state.creatorFilter.value, { startDate: this.state.startDate, endDate: this.state.endDate });
  }

  getData(tempPagenum, tempFilter, creatorId) {
    if (tempPagenum == 1) {
      this.setState({ dataLoading: true });
    }


    var url = devconfig.admin + "/course/payment-given";
    if (tempFilter) {
      var data = JSON.stringify({
        "page_num": tempPagenum,
        "start_date": tempFilter && tempFilter.startDate ? tempFilter.startDate.format("DD-MM-YYYY") : null,
        "end_date": tempFilter && tempFilter.endDate ? tempFilter.endDate.format("DD-MM-YYYY") : null,
        "creator_id": creatorId
      })
    } else {
      var data = JSON.stringify({
        "page_num": tempPagenum,
        "creator_id": creatorId
      })
    }

    APIPost(url, data).then(function (response) {
      if (response.status) {
        var creatorData = [];
        var given = [];
        for (var i = response.creator_data.length - 1; i >= 0; i--) {
          var item = response.creator_data[i];
          creatorData.push({ label: item.name, value: item.id })
        }

        {/*Find lazy load or not*/ }
        if (this.state.data.length && this.state.lazyLoadStatus) {
          var tempCardArry = this.state.data.concat(response.data);
          this.setState({ data: tempCardArry, sortBy: creatorData });
        }
        else {
          this.setState({ data: response.data, sortBy: creatorData });
          this.state.data.map((item, key) => {
            return (
              given.push({ Student: item.user_name, CourseName: item.course_name, Tutor: item.creator, EnrolmentDate: item.purchased_on, State: item.state, EnrolmentAmount: item.offer_price })

            );
          })
          this.setState({ excelGiven: given });
        }

        {/*Find lazy load ends*/ }
        if (response.data.length <= 19) {
          this.setState({ lazyDataEnd: true, lazyLoadStatus: false, dataLoading: false });
        }
        else {
          this.setState({ lazyDataEnd: false, lazyLoadStatus: false, dataLoading: false });
        }
      }
      else {
        this.setState({ data: [], lazyLoadStatus: false, dataLoading: false });
      }
    }.bind(this))
  }

  getOtherData(tempPagenum, stateId, creatorId, tempFilter = {}) {
    if (tempPagenum == 1) {
      this.setState({ dataLoading2: true });
    }

    var url = devconfig.admin + "/course/payment-received";

    if (Object.keys(tempFilter).length) {
      var data = JSON.stringify({
        "page_num": tempPagenum,
        "state_id": stateId,
        "creator_id": creatorId,
        "start_date": tempFilter && tempFilter.startDate ? tempFilter.startDate.format("DD-MM-YYYY") : null,
        "end_date": tempFilter && tempFilter.endDate ? tempFilter.endDate.format("DD-MM-YYYY") : null
      });
    } else {
      var data = JSON.stringify({
        "page_num": tempPagenum,
        "state_id": stateId,
        "creator_id": creatorId
      });
    }

    APIPost(url, data).then(function (response) {
      if (response.status) {
        var creatorData = [];
        for (var i = response.creator_data.length - 1; i >= 0; i--) {
          var item = response.creator_data[i];
          creatorData.push({ label: item.name, value: item.id })
        }

        // response.data.state_data
        var stateData = [];
        var tem = [];
        for (var i = response.state_data.length - 1; i >= 0; i--) {
          var item = response.state_data[i];
          stateData.push({ label: item.name, value: item.id })
        }

        {/*Find lazy load or not*/ }
        // if(this.state.courseData.length && this.state.lazyLoadStatus2){
        //   var tempCardArry = this.state.courseData.concat(response.data.course_data);
        // }

        {/*Find lazy load or not*/ }
        if (this.state.courseData.length && this.state.lazyLoadStatus2) {
          var tempCardArry = this.state.courseData.concat(response.data.course_data);
          this.setState({ courseData: tempCardArry, sortBy: creatorData });
        }
        else {
          this.setState({ courseData: response.data.course_data, sortBy: creatorData });

        }
        this.state.courseData.map((item, key) => {
          return (
            tem.push({ Student: item.user_name, CourseName: item.course_name, Tutor: item.creator, EnrolmentDate: item.purchased_on, State: item.state, EnrolmentAmount: item.offer_price })

          );
        })
        this.setState({ excelReceived: tem });

        {/*Find lazy load ends*/ }
        if (response.data.course_data.length <= 19) {
          this.setState({ lazyDataEnd2: true, lazyLoadStatus2: false, dataLoading2: false });
        }
        else {
          this.setState({ lazyDataEnd2: false, lazyLoadStatus2: false, dataLoading2: false });
        }


        this.setState({
          sortBy: creatorData,
          stateData: stateData,
          total_amount: response.data.total_amount,
          total_cgst: response.data.total_cgst,
          total_igst: response.data.total_igst,
          total_sgst: response.data.total_sgst,
          dataLoading2: false
        });
      }
      else {
        this.setState({ data: [], lazyLoadStatus: false, dataLoading: false });
      }
    }.bind(this))
  }


  downloadPaymnetReceived(stateId, creatorId, tempFilter = {}) {


    var url = devconfig.admin + "/course/download-payment-received";

    if (Object.keys(tempFilter).length) {
      var data = JSON.stringify({
        "state_id": stateId,
        "creator_id": creatorId,
        "start_date": tempFilter && tempFilter.startDate ? tempFilter.startDate.format("DD-MM-YYYY") : null,
        "end_date": tempFilter && tempFilter.endDate ? tempFilter.endDate.format("DD-MM-YYYY") : null
      });
    } else {
      var data = JSON.stringify({
        "state_id": stateId,
        "creator_id": creatorId
      });
    }

    APIPost(url, data).then(function (response) {
      if (response.status) {

        this.setState({ url: response.file_url, ReceivedStatus: response.status });
      } else {
        this.setState({ ReceivedStatus: response.status })
      }
    }.bind(this))
  }

  downloadPaymnetGiven(creatorId, tempFilter) {


    var url = devconfig.admin + "/course/download-payment-given";

    var data = JSON.stringify({
      "creator_id": creatorId,
      "start_date": tempFilter && tempFilter.startDate ? tempFilter.startDate.format("DD-MM-YYYY") : null,
      "end_date": tempFilter && tempFilter.endDate ? tempFilter.endDate.format("DD-MM-YYYY") : null
    });

    APIPost(url, data).then(function (response) {
      if (response.status) {
        this.setState({ givenUrl: response.file_url, givenStatus: response.status });
        console.log(this.state.givenUrl)
      }
      else {
        this.setState({ givenStatus: response.status })
      }
    }.bind(this))
  }


  clearFilter() {
    this.setState({
      filterDefault: { value: null, label: 'All Creators' },
      creatorFilter: { value: 1, label: 'Creator name' },
      filter: null,
      sort: 1,
    });
  }

  moreClick(tempId) {
    this.setState({ moreOpen: tempId });
  }

  moreClose() {
    this.setState({ moreOpen: 0 });
  }

  onDatesChange = async ({ startDate, endDate }) => {
    await this.setState({
      startDate,
      endDate
    });

    if (startDate && endDate) {
      if (this.state.tabActive === 1) {
        await this.getData(1, { startDate: startDate, endDate: endDate }, this.state.creatorFilter.value);
        await this.downloadPaymnetGiven(this.state.creatorFilter.value, { startDate: startDate, endDate: endDate });
      } else {
        await this.getOtherData(1, this.state.creatorFilter.value, this.state.stateDataFilter.value, { startDate: startDate, endDate: endDate });
        await this.downloadPaymnetReceived(this.state.creatorFilter.value, this.state.stateDataFilter.value, { startDate: startDate, endDate: endDate });
      }

    }
  }


  loadMoreClick() {
    this.getData(this.state.pageNum + 1, this.state.filter, this.state.sort);
    this.setState({ pageNum: this.state.pageNum + 1, lazyLoadStatus: true });
  }

  loadMoreClick2() {
    // this.getOtherData(this.state.pageNum2 + 1, this.state.filter, this.state.sort);
    this.getOtherData(this.state.pageNum2 + 1, this.state.stateDataFilter.value, this.state.creatorFilter.value, { startDate: this.state.startDate, endDate: this.state.endDate });
    this.setState({ pageNum2: this.state.pageNum2 + 1, lazyLoadStatus2: true });
  }

  tabItemClick(tempActive) {
    this.setState({ tabActive: tempActive });
    if (tempActive == 1) {
      this.getData(1, null, null)
    } else {
      this.getOtherData(1, null, null)
      this.downloadPaymnetReceived(this.state.stateDataFilter.value, this.state.creatorFilter.value, { startDate: this.state.startDate, endDate: this.state.endDate })
    }
  }

  searchChange(e) {
    this.setState({
      search: e.target.value
    });

    if (e.target.value.length >= 3) {
      // this.creatorsSearchApi(e.target.value);
      this.setState({
        searchEnable: true
      });

      this.clearFilter();
    }

    if (!e.target.value.length) {
      // this.creatorsListApi(1, null, 1);
      this.setState({
        searchEnable: false,
      });
    }
  }


  render() {

    const sortBy = this.state.sortBy

    const type = [
      { value: null, label: 'All' },
      { value: 1, label: 'Course' },
      { value: 2, label: 'Webinar' },
    ]

    return (
      <div className="e-main-content e-creators-wrap e-invoice-wrap">
        <h2>Payment Reports</h2>

        <div className="row mb-4">
          <div className="col-lg-12">
            {
              this.state.tabActive === 1 ?
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 1)}>Given</span>
                  <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 2)}>Received</span>
                </div>
                :
                null
            }

            {
              this.state.tabActive === 2 ?
                <div className="e-tab-title-wrap">
                  <span className="e-tab-item-title" onClick={this.tabItemClick.bind(this, 1)}>Given</span>
                  <span className="e-tab-item-title e-active" onClick={this.tabItemClick.bind(this, 2)}>Received</span>

                </div>
                :
                null
            }

          </div>
        </div>
        {
          this.state.tabActive === 1 ?
            <div>
              <div className="row">
                <div className="col-lg-2">
                  {
                    this.state.searchEnable ?
                      null
                      :
                      <div className="e-filter-wrap">
                        <label>Filter by:</label>
                        <SelectBox defaultValue={this.state.creatorFilter} options={sortBy} selectchange={this.sortChange} placeholder="Filter" />
                      </div>
                  }
                </div>

                <div className="col-lg-3">
                  <div className="e-calendar-wrap">
                    <DateRangePicker
                      startDate={this.state.startDate}
                      endDateId="e-endDate"
                      startDateId="e-startDate"
                      endDate={this.state.endDate}
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      startDatePlaceholderText={"Start date"}
                      endDatePlaceholderText={"End date"}
                      disableScroll={false}
                      hideKeyboardShortcutsPanel={true}
                      regular={false}
                      small
                      numberOfMonths={1}
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    />
                  </div>
                </div>
                <div className="col-lg-7 text-right">
                  {
                    this.state.givenStatus ?
                      <div className="mt-4">
                        <a className="e-download-link" href={this.state.givenUrl} target="_blank">Download CSV</a>
                      </div>
                      :
                      null
                  }

                </div>
              </div>

              {
                !this.state.dataLoading ?
                  <div className="row mt-4">
                    {
                      this.state.data.length ?
                        <div className="col-lg-12">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Tutor</th>
                                <th>Paid date</th>
                                <th>Amount</th>
                                <th>Transaction ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.data.map((data, index) => {
                                  var tempClass = "position-relative";
                                  return (
                                    <tr className={tempClass} key={index}>
                                      <td className="e-creator-dull-txt">{data.creator}</td>
                                      <td className="e-creator-dull-txt">{data.paid_on}</td>
                                      <td className="e-creator-dull-txt">₹ {data.amount}</td>
                                      <td className="e-creator-dull-txt">
                                        {data.transaction_id}
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                          {
                            this.state.moreOpen ?
                              <span className="e-more-overlay" onClick={this.moreClose.bind(this)}></span>
                              :
                              null
                          }

                          {
                            !this.state.lazyDataEnd ?
                              <div className="text-center py-3">
                                <span className="e-load-more" onClick={this.loadMoreClick.bind(this)}>Load More</span>
                              </div>
                              :
                              null
                          }

                          {
                            this.state.lazyLoadStatus ?
                              <div className="text-center py-3">
                                <LazyLoaderGif />
                              </div>
                              :
                              null
                          }
                        </div>
                        :
                        <div className="col-lg-12 text-center">
                          <NothingFound data="No data found !" />
                        </div>
                    }

                  </div>
                  :
                  <div className="row">
                    <div className="col-lg-12 text-center pt-5">
                      <LazyLoaderGif />
                    </div>
                  </div>

              }

            </div>
            :
            null
        }

        {/* {
          this.state.tabActive === 2 ?
            <div>
              <div className="row">
                <div className="col-lg-2">
                  {
                    this.state.searchEnable ?
                      null
                    :
                      <div className="e-filter-wrap">
                        <label>Filter by tutor name:</label>
                        <SelectBox defaultValue={this.state.creatorFilter} options={sortBy} selectchange={this.sortChange2} placeholder="Filter"/>
                      </div>
                  }
                </div>

                <div className="col-lg-2">
                  {
                    this.state.searchEnable ?
                      null
                    :
                      <div className="e-filter-wrap">
                        <label>Filter by states:</label>
                        <SelectBox defaultValue={this.state.stateDataFilter} options={this.state.stateData} selectchange={this.stateChange} placeholder="Filter"/>
                      </div>
                  }
                </div>

                <div className="col-lg-3">
                  <div className="e-calendar-wrap">
                    <DateRangePicker
                      startDate={this.state.startDate}
                      endDateId="e-endDate" 
                      startDateId="e-startDate"
                      endDate={this.state.endDate}
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      startDatePlaceholderText={"Start date"}
                      endDatePlaceholderText={"End date"}
                      disableScroll={false}
                      hideKeyboardShortcutsPanel={true}
                      regular={false}
                      small
                      numberOfMonths={1}
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    />
                  </div>
                </div>
                <div className="col-lg-5 text-right">
                {
                  this.state.ReceivedStatus ?
                  <div className="mt-4">
                    <a className="e-download-link" href={this.state.url} target="_blank" >Download CSV</a>
                  </div>
                :
                null
                }
                
                </div>
              </div>


              <div className="row">
                <div className="col-lg-12">
                  <hr/>
                </div>

                <div className="col-lg-12 pt-2 pb-3">
                  <h5>Summary based on filters</h5>
                </div>

                <div className="col-lg-2">
                  <div className="e-summary-item">
                    <span className="e-dull-text">Total Taxable Amount:</span>
                    <p>₹ {this.state.total_amount}</p>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-summary-item">
                    <span className="e-dull-text">CGST:</span>
                    <p>₹ {this.state.total_cgst}</p>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-summary-item">
                    <span className="e-dull-text">SGST:</span>
                    <p>₹ {this.state.total_sgst}</p>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="e-summary-item">
                    <span className="e-dull-text">IGST:</span>
                    <p>₹ {this.state.total_igst}</p>
                  </div>
                </div>
              </div>

              {
                !this.state.dataLoading2 ?
                  <div className="row mt-4">
                    {
                      this.state.courseData.length ?
                        <div className="col-lg-12">
                          <table className="table">
                            <thead>
                              <tr>
                                
                                <th>Student</th>
                                <th>Course name</th>
                                <th>Tutor</th>
                                <th>Enrolment date</th>
                                <th>State</th>
                                <th>Enrolment Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                this.state.courseData.map( (data, index) => {
                                  var tempClass = "position-relative";
                                  if(data.muted){
                                    tempClass = tempClass + " e-muted";
                                  }

                                  var tempURL = "/detail?id="+data.id;
                                  
                                  var tempLink = "https://www.youtube.com/channel/"+data.channel_id;
                                  return(
                                    <tr className={tempClass} key={index}>
                                      <td className="e-creator-dull-txt">{data.user_name}</td>
                                      <td className="e-creator-dull-txt">{data.course_name}</td>
                                      <td className="e-creator-dull-txt">{data.creator}</td>
                                      <td className="e-creator-dull-txt">{data.purchased_on}</td>
                                      <td className="e-creator-dull-txt">{data.state}</td>
                                      <td className="e-creator-dull-txt">₹ {data.offer_price}</td>
                                      <td className="e-creator-dull-txt">
                                        <span className="e-link-text" data-toggle="modal" data-target="#invoiceDetailModal" 
                                          onClick={()=>this.setState({invoiceData: data}, ()=>this.setState({showInvoice: true})) }
                                        >View Details</span>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                          {
                            this.state.moreOpen ?
                              <span className="e-more-overlay" onClick={this.moreClose.bind(this)}></span>
                            :
                              null
                          }

                          {
                            !this.state.lazyDataEnd2 && !this.state.lazyLoadStatus2 ?
                              <div className="text-center py-3">
                                <span className="e-load-more" onClick={this.loadMoreClick2.bind(this)}>Load More</span>
                              </div>
                            :
                              null
                          }

                          {
                            this.state.lazyLoadStatus2 ?
                              <div className="text-center py-3">
                                <LazyLoaderGif/>
                              </div>
                            :
                              null
                          }
                        </div>
                      :
                        <div className="col-lg-12 text-center">
                          <NothingFound data="No creator found !"/>
                        </div>
                    }
                    
                  </div>
                :
                  <div className="row">
                    <div className="col-lg-12 text-center pt-5">
                      <LazyLoaderGif/>
                    </div>
                  </div>

              }
              
            </div>
          :
            null
        } */}
        {
          this.state.tabActive === 2 ?
            <div>
              <div className="row">
                <div className="col-lg-2">

                  <div className="e-filter-wrap">
                    <label>Type:</label>
                    <SelectBox defaultValue={this.state.creatorFilter} options={type} selectchange={this.sortChange2} placeholder="Type" />
                  </div>

                </div>


                <div className="col-lg-3">
                  <div className="e-filter-wrap">
                    <label>Filter by date:</label>
                    <div className="e-calendar-wrap e-received">
                      <DateRangePicker
                        startDate={this.state.startDate}
                        endDateId="e-endDate"
                        startDateId="e-startDate"
                        endDate={this.state.endDate}
                        onDatesChange={this.onDatesChange}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                        startDatePlaceholderText={"Start date"}
                        endDatePlaceholderText={"End date"}
                        disableScroll={false}
                        hideKeyboardShortcutsPanel={true}
                        regular={false}
                        small
                        numberOfMonths={1}
                        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className='col-lg-5'>
                  <div className="e-search-wrap text-right mt-4">
                    <input className="e-search-input e-webinar-search" placeholder="Search for hosts" value={this.state.value} type="text" onChange={this.searchChange.bind(this)} required />
                  </div>
                </div> */}
                <div className="col-lg-5 position-relative text-right e-search-wraper" style={{top: "30px"}}>

                  <SearchInput
                    placeholder="Search for hosts"
                    value={this.state.searchKeyword}
                    className="e-dash-search"
                    valueChange={(value) => {
                      this.setState({ searchKeyword: value })
                    }}
                  />
                  {
                    this.state.searchKeyword && this.state.searchKeyword.length ?
                      <>
                        <span className="e-search-submit" onClick={() => ({ searchKeyword: "" })} ></span>
                        <button className="e-search-close" onClick={() => this.setState({ searchKeyword: "" })}></button>
                      </>
                      :
                      null
                  }

                  
                </div>
                <div className="col-lg-2 text-right pt-3">
                  {
                    this.state.ReceivedStatus ?
                      <div className="mt-4">
                        <a className="e-download-link" href={this.state.url} target="_blank" >Download CSV</a>
                      </div>
                      :
                      null
                  }

                </div>
              </div>



              <div className="row mt-4">
                <div className="col-lg-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <div className='e-checkbox-wrap-table'>
                            <input type="checkbox" checked={this.state.all} onChange={(e) => this.setState({ all: e.target.checked })} />
                            <span className="checkmark"></span>
                          </div>

                        </th>
                        <th>ID</th>
                        <th>Host by</th>
                        <th>Name of the webinar</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td>
                          <div className='e-checkbox-wrap-table'>
                            <input type="checkbox" checked={this.state.all} onChange={(e) => this.setState({ all: e.target.checked })} />
                            <span className="checkmark"></span>
                          </div>
                        </td>
                        <td className="e-creator-dull-txt">TSTUR001</td>

                        <td className="e-creator-dull-txt">John lary</td>
                        <td className="e-creator-dull-txt">Option Trading Strategy</td>
                        <td className="e-creator-dull-txt">Jul 02 , 2020</td>
                        <td className="e-creator-dull-txt">343</td>
                        <td className="e-creator-dull-txt">₹ 4000</td>
                        <td className="e-creator-dull-txt e-webinar-published"><span>Published</span></td>

                      </tr>
                      <tr className="">
                        <td>
                          <div className='e-checkbox-wrap-table'>
                            <input type="checkbox" checked={this.state.all} onChange={(e) => this.setState({ all: e.target.checked })} />
                            <span className="checkmark"></span>
                          </div>
                        </td>
                        <td className="e-creator-dull-txt">TSTUR002</td>
                        <td className="e-creator-dull-txt">Victor james</td>
                        <td className="e-creator-dull-txt">Option Trading Strategy</td>
                        <td className="e-creator-dull-txt">Jul 02 , 2020</td>
                        <td className="e-creator-dull-txt">343</td>
                        <td className="e-creator-dull-txt">₹ 4000</td>
                        <td className="e-creator-dull-txt e-webinar-unpublished"><span>Unpublished</span></td>
                      </tr>
                      <tr className="">
                        <td>
                          <div className='e-checkbox-wrap-table'>
                            <input type="checkbox" checked={this.state.all} onChange={(e) => this.setState({ all: e.target.checked })} />
                            <span className="checkmark"></span>
                          </div>
                        </td>
                        <td className="e-creator-dull-txt">TSTUR003</td>
                        <td className="e-creator-dull-txt">John james</td>
                        <td className="e-creator-dull-txt">Option Trading Strategy</td>
                        <td className="e-creator-dull-txt">Jul 02 , 2020</td>
                        <td className="e-creator-dull-txt e-green">Free</td>
                        <td className="e-creator-dull-txt">₹ 4000</td>
                        <td className="e-creator-dull-txt e-webinar-published"><span>Published</span></td>
                      </tr>


                    </tbody>
                  </table>

                </div>
              </div>

            </div>
            :
            null
        }

        {/* Modals */}
        {
          this.state.showInvoice ?
            <DetailInvoice data={this.state.invoiceData} />
            :
            null
        }


      </div>

    );
  }
}

export default InvoiceListing;
